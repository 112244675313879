import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';
import { NotificationService } from '../notification/notification.service';

@Injectable({
  providedIn: 'root'
})
export class ProgressiveWebAppService {
  private promptEvent; // Obs: Não sei qual é o tipo
  private updateAvailable:Boolean;

  constructor(
    private _swUpdate: SwUpdate,
    private _notificationService: NotificationService
  ) { 
    this.promptEvent = null
    this.updateAvailable = false;

    // Inscreve um tratamento para o evento que é disparado quando a instalação PWA é suportada
    window.addEventListener('beforeinstallprompt', (event) => {
      // Captura o evento para uso posterior
      this.promptEvent = event;
    });

    // Inscreve um tratamento para o evento de atualização disponível
    this._swUpdate.available.subscribe((event) => {
      this.updateAvailable = true;
      this._notificationService.notifySwUpdate();
    });
    // Habilita a verificação de updates automaticamente a cada 1 minuto
    interval(1000 * 60 * 1).subscribe(() => this._swUpdate.checkForUpdate());          
  }

  /**
   * Retorna se a instalação é suportada no dispositivo
   * @returns True se é suyportada
   */
  public installationSupported() : boolean {
    return (this.promptEvent != null) && (this.promptEvent != undefined);
  }

  /**
   * Executa o prompt de instalação se ela for suportada
   */
  public promptInstallation() : void {
    if(this.installationSupported()){
      this.promptEvent.prompt();
    }    
  }
}
