import {Component, OnInit, AfterViewInit, NgZone, ElementRef, ViewChildren, QueryList} from '@angular/core';
import { ProgressiveWebAppService } from 'src/app/services/progressive-web-app/progressive-web-app.service';
import { AuthenticationService, EAuthState } from 'src/app/services/authentication/authentication.service';
import { SnackBarService } from 'src/app/services/snack-bar/snack-bar.service';
import { ErrorHandlingService } from 'src/app/services/error-handling/error-handling.service';
import { IError } from "src/app/services/error-handling/error-definitions";
import { User } from 'src/app/models/user.model';
import { Router } from '@angular/router';
import { version } from 'src/app/app.version';


@Component({
  selector: 'app-loader-screen',
  templateUrl: './loader-screen.component.html',
  styleUrls: ['./loader-screen.component.scss']
})
export class LoaderScreenComponent implements OnInit, AfterViewInit {
  @ViewChildren("testimonial, about") testimonials:QueryList<ElementRef>;

  public progressBarEnabled:Boolean;
  public checkingPersistentLogin:Boolean;

  private loggerUserLocalRef:User;

  private testimonial_index:number;
  private num_testimonials:number;

  public version = version;  

  public constructor(
    public  _pwa: ProgressiveWebAppService,
    private _authService:AuthenticationService,
    private _errorHnd:ErrorHandlingService,
    private _snackBar:SnackBarService,
    private _router:Router,
    private _ngZone: NgZone
  ) {
    this.progressBarEnabled = true;
    this.checkingPersistentLogin = true;
  }

  /**
   * Chamado ao carregar o componente
   */
  public ngOnInit(): void {
    // Registra um observable para atualizar a referencia local do 
    // usuário logado
    this._authService.loggedUser.subscribe((user:User) => {
      this.loggerUserLocalRef = user;     
    });

    // Registra um observable para verificar o estado da autenticação
    this._authService.authState.subscribe((state:EAuthState) => {
      switch(state){
        case EAuthState.AccessAllowed:{
          this._ngZone.run(()=>{
            this._snackBar.show("Olá " + this.loggerUserLocalRef.personalInformation.name);
            this._router.navigate(['accessAllowed']);
          });          
          break;
        }
        case EAuthState.RegistrationRequired:{
          this._ngZone.run(()=>{
            this._router.navigate(['registration']);
          }); 
          break;
        }
        case EAuthState.AccessDenied:{          
          break;
        }
      }
    });

    // O timeout para interromper a verificação é puramente estético. 
    // Estou assumindo que após 1 segundo o estado da autenticação é
    // definitivo e já foi propagado.
    setTimeout(()=>{
      this.checkingPersistentLogin = false;
      this.progressBarEnabled = false;
    }, 1000);

  }

  public ngAfterViewInit() : void {
    
    // Faz a interação sob a troca dos depoimentos a cada 2 segundos
    this.num_testimonials = this.testimonials.length;
    this.testimonial_index = 0;
    this.rollTestimonial();
    setInterval(this.rollTestimonial.bind(this), 10000);
  } 
    
  public rollTestimonial() : void {
    // Esconde todos
    this.testimonials.forEach((item)=>{
      let element = (item.nativeElement as HTMLDivElement);
      element.style.display = 'none';
      element.classList.remove('w3-animate-left');
    });

    // Exibe apenas o atual
    let current = this.testimonials.get(this.testimonial_index).nativeElement as HTMLDivElement;
    current.style.display = 'block';
    current.classList.add('w3-animate-left');
    
    // Incrementa para a próxima chamada
    this.testimonial_index = (this.testimonial_index+1) % this.num_testimonials;
  }

  /**
   * 
   */
  public async onLoginButtonClick(): Promise<void> {
    this.progressBarEnabled = true;
    this._authService.loginWithGoogle()
    .catch((error:IError) => {      
      this._errorHnd.show(error);
    })
    .finally(()=>{
      this.progressBarEnabled = false;
    });
  }

}
