import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IStreamDialogResult } from 'src/app/models/live-stream.model';


@Component({
  selector: 'app-open-stream-dialog',
  templateUrl: './open-stream-dialog.component.html',
  styleUrls: ['./open-stream-dialog.component.scss']
})
export class OpenStreamDialogComponent {
  @ViewChild('urlInput') urlInput:ElementRef;
  public streamUrl:string;

  public result:IStreamDialogResult = {
    url:'',
    source:'youtube',
    title: ''
  }

  constructor(
    public dialogRef: MatDialogRef<OpenStreamDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any    
  ) { }
  
  public updateUrl() : void {
    switch(this.result.source){
      case 'youtube':{
        this.urlInput.nativeElement.disabled = false;
        this.result.url = '';
        break;
      }
      case 'instagram':{
        this.urlInput.nativeElement.disabled = true;
        this.result.url = 'https://www.instagram.com/cycleconnect_exclusivo/live';
        break;
      }
      case 'private':{

        break;
      }
    }
  }

}
