<div class="card-padding">
    <mat-card class="class-card">

        <mat-card-header class="class-card-header">                            
            <mat-card-title>{{getClassKeyForSafeDisplay(class, 'name')}}</mat-card-title>
            <mat-card-subtitle>Duração: {{getClassKeyForSafeDisplay(class, 'totalTimeMinuts')}} minutos</mat-card-subtitle>
            <mat-card-subtitle>Última atualização: {{getClassKeyForSafeDisplay(class, 'updatedAt') | date:  'HH:mm , dd/MM/yyyy'}}</mat-card-subtitle>                
        </mat-card-header>
        
        <img mat-card-image [src]="class.thumbnail_base64" [routerLink]="['../class-map',{id: class.id}]">

        <mat-card-actions>
            <button mat-button (click)="delete()">Excluir</button>
            <button mat-stroked-button color="primary" [routerLink]="['../class-map',{id: class.id}]">Carregar</button>
        </mat-card-actions>

    </mat-card>
</div>
