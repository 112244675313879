import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Feedback } from 'src/app/models/feedback.model';
import { IUser } from 'src/app/models/user.model';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { IError } from 'src/app/services/error-handling/error-definitions';
import { ErrorHandlingService } from 'src/app/services/error-handling/error-handling.service';
import { OnlineDataControllerService } from 'src/app/services/online-data-controller/online-data-controller.service';
import { SnackBarService } from 'src/app/services/snack-bar/snack-bar.service';

@Component({
  selector: 'app-feedback-form',
  templateUrl: './feedback-form.component.html',
  styleUrls: ['./feedback-form.component.scss']
})
export class FeedbackFormComponent implements OnInit {
  public isExecAsyncRequest:boolean = false;
  public feedbackFormGroup: FormGroup;

  constructor(
    private _formBuilder: FormBuilder,
    private _dc:OnlineDataControllerService,
    private _auth:AuthenticationService,
    private _snack:SnackBarService,
    private _errHnd:ErrorHandlingService,
    private _router:Router
  ) { }

  /**
   * 
   */
  public ngOnInit(): void {
    this.isExecAsyncRequest = false;

    this.feedbackFormGroup = this._formBuilder.group({
      subjectControl:              ['', Validators.required],
      otherSubjectControl:         ['', Validators.required],
      commentControl:              ['', Validators.required]
    });    
  }

  public onSubjectSelect() : void {
    if(this.feedbackFormGroup.get('subjectControl').value == 'Outro'){
      this.feedbackFormGroup.get('otherSubjectControl').setValue('');
    }else{
      // Escreve um valor qualquer no campo oculto para não afetar a validação
      this.feedbackFormGroup.get('otherSubjectControl').setValue('--');
    }
  }


  /**
   * 
   */
  public send() : void {
    if(this.feedbackFormGroup.valid){
      this.isExecAsyncRequest = true;

      let f = new Feedback('');
      f.subject = this.feedbackFormGroup.get('subjectControl').value;
      if(f.subject === 'Outro'){
        f.subject = this.feedbackFormGroup.get('otherSubjectControl').value;
      }
      f.comment = this.feedbackFormGroup.get('commentControl').value;
      f.from = this._auth.getLoggedUser();

      this._dc.createFeedback(f)
      .then(()=>{
        this._snack.show("Obrigado pelo seu feedback!");
        // Navegar para o início
        this._router.navigate(['accessAllowed/dashboard']);
      })
      .catch((error:IError)=>{
        this._errHnd.show(error);
      })
      .finally(()=>this.isExecAsyncRequest = false);
    }
  }
}
