import { Injectable } from '@angular/core';
import { FirebaseService } from '../firebase/firebase.service';
import { FacebookService } from '../facebook/facebook.service';
import firebase from "firebase/app";
import 'firebase/analytics';


@Injectable({
  providedIn: 'root'
})

export class AnalyticsService {
  private _analytics:firebase.analytics.Analytics;

  constructor(
    private _firebase:FirebaseService,
    private _facebook:FacebookService
  ) { 
    this._analytics = this._firebase.getAnalytics();
  }

  public logEvent(e:AnalyticsEvents, params?: {[key: string]: any}, extra?:any ) : void {
    try{
      this._analytics.logEvent(e, params);
      this._facebook.logEvent(e, params, extra);  
    }catch(e){};
  }
}

export type AnalyticsEvents = 
    "Login" 
  | "Logout" 
  | "Registration start"
  | "Registration personal info"
  | "Registration contact info"
  | "Registration complete"
  | "Enter payment information" 
  | "Subscribe" 
  | "Cancel Subsciption" 
  | "Query live class"
  | "Join live class"
  | "Watch offline class"
;
