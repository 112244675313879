<div class="container-fluid pt-3">
    <h4>1. DEFINIÇÕES DE TERMOS</h4>
    <hr />
    <h5>SISTEMA</h5>
    <p>Conjunto de softwares desenvolvidos abrangendo o FRONTEND e o BACKEND e as infraestruturas necessárias para o funcionamento.</p>
    <h5>DESENVOLVEDORES</h5>
    <p>Profissionais responsáveis pelo desenvolvimento e manutenção do SISTEMA, aqui representados por Cycle Connect Brasil - CNPJ 36.901.307/0001-00.</p>
    <h5>PROFESSOR</h5>
    <p>Usuário do SISTEMA, cadastrado como PROFESSOR, ciente de que este ainda está em fase de desenvolvimento.</p>
    <h5>CLIENTE</h5>
    <p>Usuário do SISTEMA, cadastrado como ALUNO.</p>
    <h5>USUÁRIO</h5>
    <p>Usuário do sistema, cadastrado como PROFESSOR ou CLIENTE.</p>

    <h4>2. INFORMAÇÕES GERAIS</h4>
    <hr />
    <p>A presente Política de Privacidade contém informações a respeito das permissões necessárias para execução no dispositivo e do modo como tratamos, total ou parcialmente, de forma automatizada ou não, os dados pessoais dos usuários que acessam o SISTEMA. Seu objetivo é esclarecer os interessados acerca dos tipos de dados que são coletados, dos motivos da coleta e da forma como o usuário poderá atualizar, gerenciar ou excluir estas informações.</p>
    <p>Esta Política de Privacidade foi elaborada em conformidade com a Lei Federal n. 12.965 de 23 de abril de 2014 (Marco Civil da Internet), com a Lei Federal n. 13.709, de 14 de agosto de 2018 (Lei de Proteção de Dados Pessoais) e com o Regulamento UE n. 2016/679 de 27 de abril de 2016 (Regulamento Geral Europeu de Proteção de Dados Pessoais - RGDP).</p>
    <p>Esta Política de Privacidade poderá ser atualizada em decorrência de eventual atualização normativa, razão pela qual se convida o usuário a consultar periodicamente esta seção.</p>

    <h4>3. PERMISSÕES NECESSÁRIAS</h4>
    <hr />
    <h5>NOTIFICAÇÕES</h5>
    <p>A permissão para notificações é utilizada apenas para informar ao USUÁRIO da disponibilidade de novas versões e alterações nos Termos de Uso.</p>
    <h5>ARMAZENAMENTO LOCAL</h5>
    <p>A permissão para armazenamento local é utilizada para gravar os mapas de aula criados pelo PROFESSOR no espaço de armazenamento do navegador, assim como outras preferências de uso do USUÁRIO.</p>

    <h4>COLETA DE DADOS</h4>
    <hr />
    <h5>NÃO SÃO COLETADOS</h5>
    <p>Não serão coletados dados referentes à informações pessoais dos usuários tais como nome, endereço, idade, etc.</p>
    <p>Não serão coletados dados sensíveis dos usuários, assim entendidos aqueles definidos nos arts. 9º e 10 do RGPD e nos arts. 11 e seguintes da Lei de Proteção de Dados Pessoais. Assim, dentre outros, não haverá coleta dos seguintes dados:</p>
    <ul>
      <li>Dados que revelem a origem racial ou étnica, as opiniões políticas, as convicções religiosas ou filosóficas, ou a filiação sindical do usuário;</li>
      <li>Dados genéticos;</li>
      <li>Dados biométricos para identificar uma pessoa de forma inequívoca;</li>
      <li>Dados relativos à saúde do usuário;</li>
      <li>Dados relativos à vida sexual ou à orientação sexual do usuário;</li>
      <li>Dados relacionados a condenações penais ou a infrações ou com medidas de segurança conexas.</li>
    </ul>
    <h5>SÃO COLETADOS</h5>
    <p>Serão coletados de maneira obrigatória os seguintes dados pessoais, necessários para o cadastro no SISTEMA:</p>
    <ul>
      <li>Nome completo</li>
      <li>CPF</li>
      <li>Aniversário</li>
      <li>E-mail e informações provenientes do login com o Google</li>
    </ul>
    <p>Serão coletados de maneira opcional os seguintes dados pessoais, para o cadastro no SISTEMA:</p>
    <ul>
      <li>Telefone Celular</li>
      <li>Telefone Residencial</li>
      <li>Endereço residencial (CEP, Logradouro, Bairro, UF, Cidade e complemento)</li>
    </ul>
    <p>Serão coletados dados anônimos referentes à sessão do usuário com fins estatísticos através da plataforma Google Analytics.</p>
    <p>Todos os dados pessoais coletados podem ser vistos e editados pelo USUÁRIO através do menu ‘Minha Conta’. A exclusão permanente dos dados e da conta pode ser solicitada através do e-mail cycleconnectbrasil@gmail.com, que se dará em um prazo de até 15 dias úteis.</p>

    <p>Para adesão ao plano de assinatura, serão coletadas as seguintes informações de cobrança:</p>
    <ul>
      <li>Dados do Cartão de Crédito</li>
      <li>Endereço de cobrança</li>
    </ul>    
    <p>Os dados de cobrança coletados <b>não são armazenados pelo SISTEMA</b>. Após a coleta, os mesmos são criptografados e transferidos para o Gateway de Pagamento, Gerencianet (<a href="www.gerencianet.com.br">www.gerencianet.com.br</a>), responsável pela execução das cobranças da assinatura.</p>
  </div>