<div>
  <mat-sidenav-container #container autosize class="sidenav-container">

      <mat-sidenav 
          #sidenav
          opened
          [mode]="mobileQuery.matches ? 'over' : 'side'"
          [fixedInViewport]="mobileQuery.matches"
          [ngClass]="{
            'expanded'  : (sidenavState == 'expanded'),
            'contracted': (sidenavState == 'contracted')
          }"
          [@sidenavWidth]="{value: sidenavState}" >
          
          <div class="sidenav-content" fxLayout="column">

            <div class="sidenav-header">
              <div class="logo-container" [routerLink]="['dashboard']">
                <div class="logo"></div>
              </div>  
            </div>
                  
            <div class="sidenav-body" fxFlex="1 1 auto">
              <mat-nav-list *ngIf="loggedUserLocalRef?.alowed">
    
                <!--ÁREA DO PROFESSOR-->
                <div *ngIf="loggedUserLocalRef?.role === Role.Professor">
                  <a mat-list-item
                    matTooltip="Inicie uma nova aula ao vivo"
                    matTooltipPosition="right" 
                    [routerLink]="['live-class']"
                    (click)="(sidenav.mode == 'over') && sidenav.close()">
                      <mat-icon color="primary">live_tv</mat-icon>
                      <div class="link-name">Aula ao vivo</div>
                  </a>
                  <a mat-list-item
                    matTooltip="Acesse seus mapas de aula"
                    matTooltipPosition="right"
                    [routerLink]="['my-class-maps']"
                    (click)="(sidenav.mode == 'over') && sidenav.close()">
                      <mat-icon color="primary">show_chart</mat-icon>
                      <div class="link-name">Mapas de aula</div>
                  </a>
                </div>
      
                <!--ÁREA DO CLIENTE-->
                <div *ngIf="loggedUserLocalRef?.role === Role.Client">

                  <ng-container *ngIf="((((activeSubsPlan | async)?.status === 'Ativo') || ((activeSubsPlan | async)?.status === 'Degustação')) && ((activeSubsPlan | async)?.payment_status === 'Pago')); else noActivePlan">
                    <a mat-list-item
                      matTooltip="Aulas ao vivo"
                      matTooltipPosition="right"
                      [routerLink]="['live-classes-list']"
                      (click)="(sidenav.mode == 'over') && sidenav.close()">
                      <mat-icon color="primary">live_tv</mat-icon>
                      <div class="link-name">Aulas ao vivo</div>
                    </a>

                    <a mat-list-item
                      matTooltip="Acesse as aulas transmitidas neste mês"
                      matTooltipPosition="right"
                      [routerLink]="['recorded-classes-list']"
                      (click)="(sidenav.mode == 'over') && sidenav.close()">
                      <mat-icon color="primary">dvr</mat-icon>
                      <div class="link-name">Aulas gravadas</div>
                    </a>
                  </ng-container>
                  <ng-template #noActivePlan>
                    <a mat-list-item
                      matTooltip="Gerencie seu plano de assinatura"
                      matTooltipPosition="right"
                      [routerLink]="['subscription-management']"
                      (click)="(sidenav.mode == 'over') && sidenav.close()">
                      <mat-icon color="primary">loyalty</mat-icon>
                      <div class="link-name">Gerenciar Assinatura</div>
                    </a>
                  </ng-template>

                  <a mat-list-item
                    matTooltip="Aulas de demonstração"
                    matTooltipPosition="right"
                    [routerLink]="['public-recorded-classes-list']"
                    (click)="(sidenav.mode == 'over') && sidenav.close()">
                    <mat-icon color="primary">dvr</mat-icon>
                    <div class="link-name">Aulas de demonstração</div>
                  </a>

                </div>
      
              </mat-nav-list>            
            </div>                    
    
            <div class="sidenav-footer">
              <button class="sidenav-toogle-btn" (click)="toggleSidenavWidthMode()" matTooltip="Modo compacto/expandido" matTooltipPosition="right">
                <mat-icon [@sidenavWidthTogglerIcon]="{value: sidenavState}" class="mat-18">chevron_left</mat-icon>
              </button>                          
            </div>

          </div>

      </mat-sidenav>
    
      <mat-sidenav-content #content fxLayout="column">
          <app-header (onMenuToggle)="sidenav.toggle()"></app-header>
          <app-body fxFlex="1 1 auto" style="overflow: auto;"></app-body>
          <app-footer></app-footer>
      </mat-sidenav-content>
          
  </mat-sidenav-container>
</div>

