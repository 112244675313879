<div id="fab-dismiss"
     *ngIf="fabTogglerState==='active'"
     (click)="onToggleFab()">
</div>
<div class="fab-container">
  <button mat-fab class="fab-toggler" color="primary" (click)="onToggleFab()">
    <i class="material-icons" [@fabToggler]="{value: fabTogglerState}">{{icon}}</i>
  </button>
  <div [@speedDialStagger]="buttons.length">
    <button *ngFor="let btn of buttons"
            mat-mini-fab
            class="fab-secondary"
            color="secondary"
            matTooltip="{{btn.text}}"
            [matTooltipPosition]="'before'"
            (click)="onClickMiniFab(btn.action)">
      <i class="material-icons" >{{btn.icon}}</i>
    </button>
  </div>
</div>
