import { Injectable } from '@angular/core';
import { ClassMap } from '../../models/class-map.model';
import { ILocalDataControllerService } from './local-data-controller.interface';
import { IndexedDBDcServiceService } from './indexed-db-dc-service/indexed-db-dc-service.service';

@Injectable({
  providedIn: 'root'
})
export class LocalDataControllerService implements ILocalDataControllerService {
  
  // Instância do DataController a ser utilizado
  constructor(private _dc:IndexedDBDcServiceService) { 
    // Utilizando o IndexedDB como local storage
  }

  /**
   * Salva/Atualiza uma aula no Indexed DB
   * @param c Aula a ser armazenada ou atualizada (se houver um id correspondente)
   */
  public async saveClassMap(c:ClassMap) : Promise<void> {
    c.updatedAt = Date.now();
    return this._dc.saveClassMap(c);
  }

  /**
   * Exclui uma aula no Indexed DB
   * @param c ID da aula a ser excluida
   */
  public async deleteClassMap(id:string) : Promise<void> {
    return this._dc.deleteClassMap(id);
  }  

  /**
   * Carrega uma aula do indexed db dado seu id
   * @param id Id da aula a ser carregada
   * @returns Aula carregada
   */  
  public async loadClassMap(id:string) : Promise<ClassMap> {
    return this._dc.loadClassMap(id);
  }

  /**
   * Obtem uma lista de todas as aulas armazenadas no indexed db
   * @returns Array de aulas
   */  
  public async listAllClassMaps() : Promise<Array<ClassMap>> {
    return this._dc.listAllClassMaps();
  }

}
