<div mat-dialog-title>
  <mat-toolbar appSticky>
    <button *ngIf="data.header_back_button"
        mat-icon-button
        matTooltip="Voltar"
        [mat-dialog-close]="false">
        <mat-icon>arrow_back_ios</mat-icon>
    </button>    
    <span>{{data.title}}</span>
    <span class="fill-remaining-space"></span>
  </mat-toolbar>
</div>

<div mat-dialog-content>
    {{data.message}}
</div>

<div mat-dialog-actions *ngIf="data.buttons == EDialogButtons.OK">
  <button 
    mat-button
    [mat-dialog-close]="true"
    cdkFocusInitial>OK</button>
</div>

<div mat-dialog-actions *ngIf="data.buttons == EDialogButtons.SIM_NAO">
  <button
    mat-button
    [mat-dialog-close]="false">Não</button>
  <button 
    mat-button
    [mat-dialog-close]="true"
    cdkFocusInitial>Sim</button>
</div>
