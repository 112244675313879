import { Platform } from '@angular/cdk/platform';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, Unsubscribable } from 'rxjs';
import { LiveStream } from 'src/app/models/live-stream.model';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { OnlineDataControllerService } from 'src/app/services/online-data-controller/online-data-controller.service';
import { SnackBarService } from 'src/app/services/snack-bar/snack-bar.service';

@Component({
  selector: 'app-live-classroom',
  templateUrl: './live-classroom.component.html',
  styleUrls: ['./live-classroom.component.scss']
})
export class LiveClassroomComponent implements OnInit, OnDestroy, Unsubscribable {

  public liveStream:LiveStream;
  private liveStreamSubscription: Subscription;
  private routeSubscription: Subscription;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _snackBar: SnackBarService,
    private _dc: OnlineDataControllerService,
    private _dialog: DialogService,
    private platform: Platform
  ) { }

  /**
   * 
   */  
  public ngOnInit() : void {
    this.routeSubscription = this._route.paramMap.subscribe((params)=>{
      var professor_uid = params.get('professor_uid');
      if(!professor_uid){
        this._snackBar.show("Parâmetros de aula inválidos");
        this._router.navigate(['/accessAllowed/live-classes-list']);
        return;
      }

      // Observa a aula
      this.liveStreamSubscription = this._dc.observeLiveStream(professor_uid).subscribe((liveStreamUpdate)=>{        
        // Verifica se o stream foi encerrado
        if(!liveStreamUpdate.streaming){
          this._dialog.createInformation("A transmissão foi encerrada pelo professor. Obrigado por assistir!");
          this._router.navigate(['/accessAllowed/live-classes-list']);
          return;
        }
        this.liveStream = liveStreamUpdate;
        // Caso seja instagram, redireciona usando um deep link
        if(this.liveStream.source === 'instagram'){
            if(this.platform.ANDROID){
              // TODO: Calcular esses deeplinks ?
              window.location.href = "intent://www.instagram.com/cycleconnect_exclusivo/live#Intent;package=com.instagram.android;scheme=https;end";
            }else if(this.platform.IOS){
              window.location.href = "instagram://user?username=cycleconnect_exclusivo";
            }else{
              window.location.href = this.liveStream.url;
            }
        }
      });

    });
  }

  /**
   * 
   */
  public ngOnDestroy(): void {
    this.unsubscribe();
  }
  
  /**
   * 
   */
  public unsubscribe(): void {
    if(this.routeSubscription) this.routeSubscription.unsubscribe();
    if(this.liveStreamSubscription) this.liveStreamSubscription.unsubscribe();
  }
}