import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import videojs from 'video.js';
import 'videojs-youtube';

@Component({
  selector: 'app-videojs-player',
  templateUrl: './videojs-player.component.html',
  styleUrls: ['./videojs-player.component.scss']
})
export class VideojsPlayerComponent implements OnInit, OnDestroy {
  @ViewChild('target', {static: true}) target: ElementRef;
  @ViewChild('container', {static: true}) container: ElementRef;
  @Input() options: {
    fluid: boolean,
    aspectRatio: string,
    autoplay: boolean,
    sources: {
        src: string,
        type: string,
    }[],
    techOrder: string[]
  };
  
  private player: videojs.Player;

  constructor(
    
  ) { }

  /**
   * 
   */
  public ngOnInit() : void {
    // Tratamento de eventos de toque no player
    //["click", "touchstart"].forEach((e)=>this.container.nativeElement.addEventListener(e,this.onPlayerTap.bind(this)));
    //this.container.nativeElement.addEventListener("dblclick",this.onPlayerDoubleTap.bind(this));
    //this.container.nativeElement.addEventListener("mouseenter",this.onMouseEnterPlayer.bind(this));

    // instantiate Video.js
    this.player = videojs(this.target.nativeElement, this.options, this.onPlayerReady.bind(this));    
  }


  /**
   * 
   */
  public ngOnDestroy() : void {
    // destroy player
    if (this.player) {
      this.player.dispose();
    }
  }

  /**
   * 
   */
  private onPlayerReady() : void {
    // Remove interações do iframe
    var iframeRef = document.getElementsByTagName("iframe");
    iframeRef[0].style.pointerEvents = "none";
    // Volume em 100% (se possível)
    this.player.volume(1.0);
    // Em fullscreen (se possível)
    this.player.requestFullscreen();
    // Inicia o video automaticamente (se possível)
    this.player.play();
  }

  /**
   * Um toque -> Pause/Play
   * @param event 
   */
  private doubleTap = false;
  private onPlayerTap(event:MouseEvent | TouchEvent) : void {
    // Tratamento exclusivo para mobile
    if(event instanceof TouchEvent){
      // Evita que em mobiles o evento de click seja disparado logo em seguida
      event.preventDefault();      
      if(!this.doubleTap) {
        this.doubleTap = true;
        setTimeout(()=>{ this.doubleTap = false}, 300);
      }else{
        this.onPlayerDoubleTap(event);
        return;
      }
    }

    if(this.player.paused()){
      this.player.play();
    }else{
      this.player.pause();
    }

    
  }

  /**
   * Dois toques -> Fullscreen on/off
   * @param event 
   */
  private onPlayerDoubleTap(event:MouseEvent | TouchEvent) : void {
    if(this.player.isFullscreen()){
      this.player.exitFullscreen();
    }else{
      this.player.requestFullscreen();
    }    
  }

  /**
   * 
   * @param event 
   */
  private onMouseEnterPlayer(event:MouseEvent) : void {
    var eventClone = new Event(event.type, event);
    this.target.nativeElement.dispatchEvent(eventClone);    
  }
}
