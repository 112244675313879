<div class="full-content-container" [loader]='isExecAsyncRequest'>
    <div class="content-padding" fxLayout="column">

        <!--Form para alteração das informações pessoais-->
        <div fxFlex="1 1 auto">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>Informações pessoais</mat-card-title>
                </mat-card-header>
                <mat-card-content>                    
                    <form [formGroup]="personalInfoFormGroup">
                        <div fxLayout="row warp">
                            <!--Nome-->                
                            <mat-form-field>
                                <input 
                                    #nameInput
                                    matInput 
                                    required
                                    placeholder="Nome completo" 
                                    maxlength="100"                                
                                    formControlName="personalInfoNameControl">
                                <mat-error *ngIf="personalInfoFormGroup.get('personalInfoNameControl').hasError('required')">O nome não pode ser vazio</mat-error>
                                <mat-hint align="end">{{nameInput.value.length}} / 100</mat-hint>
                            </mat-form-field>
                            
                            <!--CPF-->                
                            <mat-form-field>
                                <input 
                                    matInput 
                                    required
                                    placeholder="000.000.000-00"
                                    maxlength="14"
                                    mask="000.000.000-00" 
                                    formControlName="personalInfoCPFControl">
                                <mat-label>CPF</mat-label>
                                <mat-hint *ngIf="personalInfoFormGroup.get('personalInfoCPFControl').status === 'PENDING'">
                                    Verificando CPF...
                                </mat-hint>
                                <mat-error *ngIf="personalInfoFormGroup.get('personalInfoCPFControl').hasError('required')">O CPF não pode ser vazio</mat-error>
                                <mat-error *ngIf="personalInfoFormGroup.get('personalInfoCPFControl').errors?.invalidCPF">Por favor insra um CPF válido</mat-error>
                                <mat-error *ngIf="personalInfoFormGroup.get('personalInfoCPFControl').errors?.unavaliableCPF">O CPF inserido já é utilizado por outro usuário</mat-error>
                            </mat-form-field> 
                            
                            <!--Aniversário-->                
                            <mat-form-field>
                                <input 
                                    matInput 
                                    required
                                    formControlName="personalInfoBirthdayControl"
                                    [matDatepicker]="birthdayDatePicker"
                                    placeholder="Escolha a data"
                                    readonly
                                    >
                                <mat-datepicker-toggle 
                                    matSuffix [for]="birthdayDatePicker">
                                </mat-datepicker-toggle>
                                <mat-datepicker 
                                    #birthdayDatePicker
                                    touchUi
                                    startView="multi-year"
                                    disabled="false">
                                </mat-datepicker>
                                <mat-label>Aniversário</mat-label>
                                <mat-error *ngIf="personalInfoFormGroup.get('personalInfoBirthdayControl').hasError('required')">A data de aniversário não pode ser vazia</mat-error>                            
                            </mat-form-field>                
                        </div>
                    </form>                    
                </mat-card-content>
            </mat-card>
        </div> 

        <div style="padding-top: 16px; padding-bottom: 16px;">
            <mat-divider></mat-divider>
        </div>

        <!--Form para alteração das informações de contato-->
        <div fxFlex="1 1 auto">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>Informações de contato</mat-card-title>
                </mat-card-header>
                <mat-card-content>                      
                    <form [formGroup]="contactInfoFormGroup">
                        <div fxLayout="row warp">
                            <!--E-mail-->
                            <mat-form-field>
                                <input 
                                    #emailInput
                                    matInput 
                                    required                                
                                    type="email"
                                    placeholder="E-mail" 
                                    maxlength="100"
                                    formControlName="contactInfoEmailControl">
                                    <mat-error *ngIf="contactInfoFormGroup.get('contactInfoEmailControl').hasError('email') && !contactInfoFormGroup.get('contactInfoEmailControl').hasError('required')">É necessário um e-mail válido</mat-error>
                                <mat-error *ngIf="contactInfoFormGroup.get('contactInfoEmailControl').hasError('required')">Por favor nos diga seu E-mail</mat-error>
                                <mat-hint align="end">{{emailInput.value.length}} / 100</mat-hint>
                            </mat-form-field>  
                            
                            <!--Celular-->
                            <mat-form-field>
                                <input 
                                    matInput                                 
                                    type="text"
                                    placeholder="(00) 0 0000-0000" 
                                    mask="(90) 9 9000-0000"
                                    [patterns]="phoneCustomPatterns"
                                    formControlName="contactInfoMobilePhoneControl">
                                <mat-label>Telefone Celular</mat-label>
                                <mat-icon matSuffix color="primary">smartphone</mat-icon>
                            </mat-form-field> 

                            <!--Residencial-->
                            <mat-form-field>
                                <input 
                                    matInput                                 
                                    type="text"
                                    placeholder="(00) 0 0000-0000" 
                                    mask="(90) 9000-0000||(90) 9 9000-0000"
                                    [patterns]="phoneCustomPatterns"
                                    formControlName="contactInfoResidentialPhoneControl">
                                <mat-label>Telefone Residencial</mat-label>
                                <mat-icon matSuffix color="primary">phone</mat-icon>
                            </mat-form-field> 

                            <!--Endereço completo-->
                            <!--CEP-->
                            <mat-form-field>                            
                                <input 
                                    #cepInput
                                    matInput                                 
                                    type="text"
                                    placeholder="00000-000" 
                                    mask="00000-000"
                                    formControlName="contactInfoAddressCEPControl"
                                    (input)="fillAddress(cepInput.value)">
                                <mat-label>CEP</mat-label>                            
                            </mat-form-field> 

                            <!--Rua-->                    
                            <mat-form-field>
                                <input 
                                    #streetInput
                                    matInput                                 
                                    type="text"
                                    placeholder="Rua" 
                                    maxlength="100"
                                    formControlName="contactInfoAddressStreetControl">
                                <mat-label>Logradouro</mat-label>
                                <mat-hint align="end">{{streetInput.value.length}} / 100</mat-hint>
                            </mat-form-field>

                            <!--Bairro-->
                            <mat-form-field>
                                <input 
                                    #neighborhoodInput
                                    matInput                                 
                                    type="text"
                                    placeholder="Bairro" 
                                    maxlength="50"
                                    formControlName="contactInfoNeighborhoodStreetControl">
                                <mat-label>Bairro</mat-label>
                                <mat-hint align="end">{{neighborhoodInput.value.length}} / 50</mat-hint>
                            </mat-form-field> 
                            
                            <!--Numero-->
                            <mat-form-field>
                                <input 
                                    matInput                                 
                                    type="number"
                                    placeholder="Número" 
                                    maxlength="5"
                                    formControlName="contactInfoAddressNumberControl">
                                <mat-label>Número</mat-label>
                            </mat-form-field>               

                            <!--Complemento-->
                            <mat-form-field>
                                <input 
                                    #complementInput
                                    matInput                                 
                                    type="text"
                                    placeholder="Complemento" 
                                    maxlength="100"
                                    formControlName="contactInfoAddressComplementControl">
                                <mat-label>Complemento</mat-label>
                                <mat-hint align="end">{{complementInput.value.length}} / 100</mat-hint>
                            </mat-form-field> 
                            
                            <!--Estado-->
                            <mat-form-field>                                
                                <mat-select formControlName="contactInfoAddressStateControl">
                                    <mat-option *ngFor="let state of states" [value]="state.sigla">{{state.nome}}</mat-option>
                                </mat-select>
                                <mat-label>UF</mat-label>
                            </mat-form-field>

                            <!--Cidade-->
                            <mat-form-field>
                                <input 
                                    #cityInput
                                    matInput                                 
                                    type="text"
                                    placeholder="Cidade" 
                                    maxlength="50"
                                    formControlName="contactInfoAddressCityControl">
                                <mat-label>Cidade</mat-label>
                                <mat-hint align="end">{{cityInput.value.length}} / 50</mat-hint>
                            </mat-form-field>                   
                            
                        </div>
                    </form> 
                </mat-card-content>
            </mat-card>
        </div> 


    </div>
</div>


<button 
    mat-fab 
    matTooltip="Atualizar informações"
    (click)="update()"
    color="primary"
    class="fab-button">
    <mat-icon>save</mat-icon>        
</button>
