import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Unsubscribable } from 'rxjs';
import { Client } from 'src/app/models/client.model';
import { LiveStream } from 'src/app/models/live-stream.model';
import { Professor } from 'src/app/models/professor.model';
import { ERole, User } from 'src/app/models/user.model';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { ErrorHandlingService } from 'src/app/services/error-handling/error-handling.service';
import { OnlineDataControllerService } from 'src/app/services/online-data-controller/online-data-controller.service';
import { SnackBarService } from 'src/app/services/snack-bar/snack-bar.service';

export interface ILiveClasse {
  ownerRef:Professor,
  streamRef:LiveStream
}

@Component({
  selector: 'app-live-classes-listing',
  templateUrl: './live-classes-listing.component.html',
  styleUrls: ['./live-classes-listing.component.scss']
})
export class LiveClassesListingComponent implements  OnInit, OnDestroy, Unsubscribable {
  
  // Aluno logado
  private loggedUser:Client = this._auth.getLoggedUser() as Client;

  // Professores cadastrados
  public professors:Array<Professor> = new Array<Professor>();

  // Aulas ao vivo
  public classes:Array<ILiveClasse> = new Array<ILiveClasse>();

  // Auxiliares
  public isExecAsyncRequest:boolean = false;

  constructor(
    private _dc:OnlineDataControllerService,
    private _auth:AuthenticationService,
    private _router:Router,    
    private _snackBar:SnackBarService,
    private _analytics:AnalyticsService
  ) { }

  /**
   * 
   */
  public ngOnInit(): void {
    this._auth.secureApplication().then((isSecure)=>{
      if(isSecure){
        // TODO: Verifica o status do plano de assinatura do cliente
        this.refreshLiveClassesList();
      }
    }); 
  }

  /**
   * 
   */
  public ngOnDestroy(): void {
    this.unsubscribe();
  }

  /**
   * 
   */
  public unsubscribe(): void {
    
  }

  /**
   * 
   */
  public refreshLiveClassesList() : void {
    this._analytics.logEvent('Query live class',{uid:this.loggedUser.uid});
    // Obtem todos os professores cadastrados.
    this.isExecAsyncRequest = true;
    this.classes = new Array<ILiveClasse>();
    this._dc.getAllProfessors().then(async (professors)=>{
      // Filtra para exibir apenas os professors com acesso liberado
      this.professors = professors.filter((p)=>p.alowed);
      // Para cada professor, verifica qual está com o stream habilitado
      for(let professor of this.professors){
        try{
          const liveStream = await this._dc.getLiveStream(professor.uid);
          if(liveStream.streaming){
            this.classes.push({
              ownerRef: professor,
              streamRef: liveStream
            });
          }
        }catch(err){
          this._snackBar.show("Falha ao atualizar a lista de aulas. Por favor tente novamente mais tarde");
          console.error(err);          
        }
        this.isExecAsyncRequest = false;
      }
    });
  }

  /**
   * 
   */
  public join(professor_uid:string) : void {
    this.isExecAsyncRequest = true;
    this._dc.joinLiveStream(professor_uid,this.loggedUser)
    .then(()=>{
      this._analytics.logEvent('Join live class',{uid:this.loggedUser.uid});
      // Redireciona para o componente de exibição do vídeo
      this._router.navigate(['/accessAllowed/live-classroom', {professor_uid: professor_uid}]);
    })
    .catch((err)=>{
      console.error(err);
      this._snackBar.show("Não foi possível se conectar à aula. Por favor tente novamente.")
    })
    .finally(()=>{
      this.isExecAsyncRequest = false;
    });
  }
}
