import { Vector2d } from 'konva/types/types';

export class AnchorPointData {
    public _id: number;
    public position: Vector2d;
    public minimized: boolean;
    public constructor(_id: number, position: Vector2d, minimized: boolean) {
        this._id = _id;
        this.position = position;
        this.minimized = minimized;
    }
}

export class StageData {
    public anchorPoints: Array<AnchorPointData>;
}

export class ClassMap {
    public id: string;
    public createdAt: number;
    public updatedAt: number;
    public name: string;
    public description: string;
    public totalTimeMinuts: number;
    public thumbnail_base64:string;
    public audioTrack:File;
    public get totalTimeSeconds(): number {
        return this.totalTimeMinuts * 60;
    }
    public get totalTimeMilliseconds(): number {
        return this.totalTimeSeconds * 1000;
    }
    public get totalTimeString(): String {
        let time = new Date(null);
        time.setMinutes(this.totalTimeMinuts);
        return time.toISOString().substr(11, 8);
    }
    public stageData: StageData;
    public rpmMap: Object;

    public constructor(totalTimeMinuts: number) {
        this.totalTimeMinuts = totalTimeMinuts;
        // Gera um ID baseado no timestamp
        this.id = (+new Date).toString(36);
        // Cria um timestamp de criacao
        this.createdAt = Date.now();
        // Inicializa as demais propriedades
        this.updatedAt = Date.now();
        this.name = '';
        this.description = '';
        this.rpmMap = new Object();
        this.stageData = new StageData();
        this.thumbnail_base64 = '';
    }
}
