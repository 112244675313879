<div class="full-content-container" [loader]="isExecAsyncRequest">

    <div fxLayout="row wrap" class="content-padding" *ngIf="classes.length > 0 else noLiveClasses">        
        <app-live-class-card *ngFor="let liveClass of classes" [liveClass]="liveClass" (click)="join(liveClass.ownerRef.uid)"></app-live-class-card>
    </div>
    <ng-template #noLiveClasses>
        <div class="full-content-container empty-classes" fxLayout="column" fxLayoutAlign="center center">
            <mat-icon color="primary">cancel</mat-icon>
            <span>Nenhuma aula ao vivo no momento</span>
        </div>          
    </ng-template>

</div>

<button 
    mat-fab 
    matTooltip="Atualizar"
    (click)="refreshLiveClassesList()"
    color="primary"
    class="fab-button">
    <mat-icon>refresh</mat-icon>        
</button>