import { Injectable, NgZone } from '@angular/core';
import { IOnlineDataControllerService } from '../online-data-controller.interface';
import { Duration, Moment } from 'moment';
import moment from 'moment';

import { FirebaseService } from '../../firebase/firebase.service';
import firebase from "firebase/app";
import "firebase/firestore";

// Models
import { User, ERole } from 'src/app/models/user.model';
import { Errors, ErrorBuilder } from "../../error-handling/error-definitions";
import { Professor } from 'src/app/models/professor.model';
import { Observable } from 'rxjs';
import { 
  _CLIENTS_COLLECTION,
  _STREAMS_COLLECTION,
  _LIVE_STREAM_DOC,
  _PARTICIPANTS_COLLECTION,
  _PROFESSORS_COLLECTION,
  _USERS_COLLECTION,
  _SUBSCRIPTIONS_COLLECTION,
  _ACTIVE_SUBSCRIPTION_DOC,
  _FEEDBACKS_COLLECTION
} from './firebase-consts';
import { Utils } from 'src/app/utils/utils.functions';
import { Client } from 'src/app/models/client.model';
import { LiveStream } from 'src/app/models/live-stream.model';
import { SubscriptionPlan } from 'src/app/models/subscription.model';
import { Feedback } from 'src/app/models/feedback.model';


@Injectable({
  providedIn: 'root'
})
export class FirebaseDcServiceService implements IOnlineDataControllerService {
  private _db: firebase.firestore.Firestore;

  // -----------------------------------------------------------------
  // Timestamps de escritas nas entidades
  // -----------------------------------------------------------------
  private static readonly _MINIMUM_WRITE_INTERVAL:Duration = moment.duration(3, 'seconds');
  private static _lastWriteTimestamp:Moment = moment();

  public constructor(
    private _firebaseService: FirebaseService,
    private _zone: NgZone,
  ) { 
    this._db = this._firebaseService.getFirestore();
  }


  /**
   * 
   */
  private async awaitWriteInterval() : Promise<void> {
    let now:Moment = moment();
    let lastWriteInterval:Duration = moment.duration(now.diff(FirebaseDcServiceService._lastWriteTimestamp));

    if(lastWriteInterval.asMilliseconds() < FirebaseDcServiceService._MINIMUM_WRITE_INTERVAL.asMilliseconds()){
      let remainingTime = FirebaseDcServiceService._MINIMUM_WRITE_INTERVAL.clone().subtract(lastWriteInterval);
      // É necessário aguardar a diferença
      return await Utils.delay(remainingTime.asMilliseconds());
    }else{
      return Promise.resolve();
    }
  }

  //#region USUÁRIOS
  /**
   * Busca um usuário dado seu uid
   * @param uid Id do usuário a ser buscado
   */  
  public async getUser(uid: string): Promise<User> {
    return new Promise(async (resolve,reject) => {
      // Tenta obter o usuário na coleção de Professores      
      var user:User;
      try {
        user = await this.getProfessor(uid);
        resolve(user);
      }catch(error){
        // Não é um professor, não existe ou houve uma falha inexperada
        if(error != Errors.UserNotFound){
          reject(error);
        }
      }

      // Tenta obter o usuário na coleção de Clientes
      try {
        user = await this.getClient(uid);
        resolve(user);
      }catch(error){
        // Não é um professor nem um cliente => Não existe ou houve uma falha inexperada
        reject(error);
      }
    });
  }

  /**
   * Busca um usuário dado seu uid
   * @param uid Id do usuário a ser buscado
   */  
  public observeUser(uid: string, role:ERole): Observable<User> {
    switch(role){
      case ERole.Client:    return this.observeClient(uid);
      case ERole.Professor: return this.observeProfessor(uid);
    }
  }  

  /**
   * Busca um usuário dado seu cpf
   * @param cpf CPF do usuário a ser buscado
   */  
  public async getUserByCPF(cpf: string): Promise<User> {
    return new Promise(async (resolve,reject) => {
      // Tenta obter o usuário na coleção de Professores      
      var user:User;
      try {
        user = await this.getProfessorByCPF(cpf);
        resolve(user);
      }catch(error){
        // Não é um professor, não existe ou houve uma falha inexperada
        if(error != Errors.UserNotFound){
          reject(error);
        }
      }

      // Tenta obter o usuário na coleção de Clientes
      try {
        user = await this.getClientByCPF(cpf);
        resolve(user);
      }catch(error){
        // Não é um professor nem um cliente => Não existe ou houve uma falha inexperada
        reject(error);
      }
    });
  }  

  /**
   * Salva um usuário no banco de dados (criando ou atualizando)
   * @param user Usuário a ser salvo
   */
  public async updateUser(user: User): Promise<void> {
    switch(user.role){
      case ERole.Client:    return this.updateClient(user);
      case ERole.Professor: return this.updateProfessor(user);
    }
  }
  //#endregion

  //#region PROFESSORES
  /**
   * 
   * @param uid 
   */
  public async getProfessor(uid: string) : Promise<Professor> {
    return this._db.collection(_PROFESSORS_COLLECTION).doc(uid).get()
    .then((docSnapshot:firebase.firestore.DocumentSnapshot) => {
      if(docSnapshot.exists) {          
        return Professor.fromDataControllerBus(docSnapshot);
      } else {
        // Encapsula o erro
        // OBS: Aqui deve lançar uma exceção e não a promessa rejeitada mesmo
        throw(Errors.UserNotFound);
      }
    });
  }

  /**
   * 
   * @param uid 
   */
  public async getAllProfessors() : Promise<Array<Professor>> {
    return this._db.collection(_PROFESSORS_COLLECTION).get()
    .then((querySnapshot:firebase.firestore.QuerySnapshot) => {
      var professors = new Array<Professor>();
      for(let doc of querySnapshot.docs){
        professors.push(Professor.fromDataControllerBus(doc));
      }
      return professors;
    });
  }  

  /**
   * 
   * @param uid 
   */
  public async getProfessorByCPF(cpf: string) : Promise<Professor> {
    return this._db.collection(_PROFESSORS_COLLECTION).where("personalInformation.cpf","==",cpf).limit(1).get()
    .then((querySnapshot:firebase.firestore.QuerySnapshot) => {
      if(querySnapshot.size == 0){
        // OBS: Aqui deve lançar uma exceção e não a promessa rejeitada mesmo
        throw(Errors.UserNotFound);
      }
      return Professor.fromDataControllerBus(querySnapshot.docs[0]);
    });
  }  

  /**
   * 
   * @param uid 
   */
  public observeProfessor(uid:string) : Observable<Professor> {    
    return new Observable<Professor>(((subscriber) => {      
      return this._db.collection(_PROFESSORS_COLLECTION).doc(uid).onSnapshot((querySnapshot: firebase.firestore.DocumentSnapshot) => {
        this._zone.run(() => {          
          try{
            subscriber.next(Professor.fromDataControllerBus(querySnapshot));
          }catch(err){
            subscriber.next(undefined);
          }          
        });
      });
    }));
  }

  /**
   * 
   * @param c 
   */
  public async updateProfessor(p:Professor) : Promise<void> {
    // Aguarda o intervalo mínimo de acesso de escrita
    await this.awaitWriteInterval();

    return this._db.collection(_PROFESSORS_COLLECTION).doc(p.uid).set(p.toDataControllerBus())
    .catch((error)=>{
      // Encapsula o erro
      Promise.reject(ErrorBuilder(Errors.FailedToSaveData,error));
    })
    .finally(()=>{
      // Atualiza o tempo da última escrita
      FirebaseDcServiceService._lastWriteTimestamp = moment();
    });      
  }   

  /**
   * 
   * @param c 
   */
  public async createProfessor(p:Professor) : Promise<Professor> {
    // Aguarda o intervalo mínimo de acesso de escrita
    await this.awaitWriteInterval();

    var newDocRef = this._db.collection(_PROFESSORS_COLLECTION).doc();
    p.uid = newDocRef.id;
    return newDocRef.set(p.toDataControllerBus())
    .then(()=>{
      return p;
    })
    .catch((error)=>{
      // Encapsula o erro
      throw(ErrorBuilder(Errors.FailedToSaveData,error));
      // TODO: Verificar aqui porque nao posso Promise.reject
    })
    .finally(()=>{
      // Atualiza o tempo da última escrita
      FirebaseDcServiceService._lastWriteTimestamp = moment();
    });    
  }  

  /**
   * 
   * @param p 
   * @param from 
   * @param to 
   */
  public async getProfessorRecordedStreams(professor_uid:string, from:Date, to:Date) : Promise<Array<LiveStream>> {
    // Doc path: professors/{professor_uid}/live_classes/streaming_now
    return this._db
    .collection(_PROFESSORS_COLLECTION)
    .doc(professor_uid)
    .collection(_STREAMS_COLLECTION)
    .get()
    .then((querySnapshot:firebase.firestore.QuerySnapshot) => {
      var streams = new Array<LiveStream>();
      for(let doc of querySnapshot.docs){
        if(doc.id != _LIVE_STREAM_DOC){
          streams.push(LiveStream.fromDataControllerBus(doc));
        }        
      }
      return streams;      
    });
  }
  //#endregion
  
  //#region CLIENTES
  /**
   * 
   * @param uid 
   */
  public async getClient(uid: string) : Promise<Client> {
    return this._db.collection(_CLIENTS_COLLECTION).doc(uid).get()
    .then((docSnapshot:firebase.firestore.DocumentSnapshot) => {
      if(docSnapshot.exists) {                              
        return Client.fromDataControllerBus(docSnapshot);
      } else {
        // Encapsula o erro
        // OBS: Aqui deve lançar uma exceção e não a promessa rejeitada mesmo
        throw(Errors.UserNotFound);
      }
    });
  }

  /**
   * 
   * @param cpf 
   */
  public async getClientByCPF(cpf: string) : Promise<Client> {
    return this._db.collection(_CLIENTS_COLLECTION).where("personalInformation.cpf","==",cpf).limit(1).get()
    .then((querySnapshot:firebase.firestore.QuerySnapshot) => {
      if(querySnapshot.size == 0){
        // OBS: Aqui deve lançar uma exceção e não a promessa rejeitada mesmo
        throw(Errors.UserNotFound);
      }
      return Client.fromDataControllerBus(querySnapshot.docs[0]);
    });
  }   

  /**
   * 
   * @param uid 
   */
  public observeClient(uid:string) : Observable<Client> {    
    return new Observable<Client>(((subscriber) => {      
      return this._db.collection(_CLIENTS_COLLECTION).doc(uid).onSnapshot((querySnapshot: firebase.firestore.DocumentSnapshot) => {
        this._zone.run(() => {
          try{
            subscriber.next(Client.fromDataControllerBus(querySnapshot));
          }catch(err){
            subscriber.next(undefined);
          }          
        });
      });
    }));
  }

  /**
   * 
   * @param c 
   */
  public async updateClient(p:Client) : Promise<void> {
    // Aguarda o intervalo mínimo de acesso de escrita
    await this.awaitWriteInterval();

    return this._db.collection(_CLIENTS_COLLECTION).doc(p.uid).set(p.toDataControllerBus())
    .catch((error)=>{
      // Encapsula o erro
      Promise.reject(ErrorBuilder(Errors.FailedToSaveData,error));
    })
    .finally(()=>{
      // Atualiza o tempo da última escrita
      FirebaseDcServiceService._lastWriteTimestamp = moment();
    });      
  }   

  /**
   * 
   * @param c 
   */
  public async createClient(p:Client) : Promise<Client> {
    // Aguarda o intervalo mínimo de acesso de escrita
    await this.awaitWriteInterval();

    var newDocRef = this._db.collection(_CLIENTS_COLLECTION).doc();
    p.uid = newDocRef.id;
    return newDocRef.set(p.toDataControllerBus())
    .then(()=>{
      return p;
    })
    .catch((error)=>{
      // Encapsula o erro
      throw(ErrorBuilder(Errors.FailedToSaveData,error));
      // TODO: verificar aqui porque nao posso Promise.reject
    })
    .finally(()=>{
      // Atualiza o tempo da última escrita
      FirebaseDcServiceService._lastWriteTimestamp = moment();
    });    
  }  

  /**
   * 
   * @param c 
   */
  public observeClientActiveSubscriptionPlan(c: Client): Observable<SubscriptionPlan> {
    return new Observable<SubscriptionPlan>(((subscriber) => {      
      // Doc path: professors/{professor_uid}/live_classes/streaming_now
      return this._db
      .collection(_CLIENTS_COLLECTION)
      .doc(c.uid)
      .collection(_SUBSCRIPTIONS_COLLECTION)
      .doc(_ACTIVE_SUBSCRIPTION_DOC)
      .onSnapshot((docSnapshot:firebase.firestore.DocumentSnapshot) => {
        this._zone.run(() => {
          try{
            subscriber.next(SubscriptionPlan.fromDataControllerBus(docSnapshot));
          }catch(err){
            subscriber.next(new SubscriptionPlan(_ACTIVE_SUBSCRIPTION_DOC));
          }
        });
      });
    }));  
  }

  //#endregion  

  //#region AULA AO VIVO
  /**
   * 
   * @param professor_uid 
   */
  public observeLiveStream(professor_uid: string): Observable<LiveStream>{
    return new Observable<LiveStream>(((subscriber) => {      
      // Doc path: professors/{professor_uid}/live_classes/streaming_now
      return this._db
      .collection(_PROFESSORS_COLLECTION)
      .doc(professor_uid)
      .collection(_STREAMS_COLLECTION)
      .doc(_LIVE_STREAM_DOC)
      .onSnapshot(async (querySnapshot: firebase.firestore.DocumentSnapshot) => {
        if(!querySnapshot.exists){
          // Se o doc da aula ao vivo não existir (primeiro acesso do professor),
          // cria automaticamente
          try{
            await querySnapshot.ref.set(new LiveStream(_LIVE_STREAM_DOC).toDataControllerBus());
          }catch(err){
            
          }          
        }else{
          this._zone.run(() => {          
            try{
              subscriber.next(LiveStream.fromDataControllerBus(querySnapshot));
            }catch(err){
              
            }          
          });            
        }
      });
    }));    
  }

  /**
   * 
   * @param uid 
   */
  public async getLiveStream(professor_uid: string): Promise<LiveStream> {
    // Doc path: professors/{professor_uid}/live_classes/streaming_now
    return this._db
    .collection(_PROFESSORS_COLLECTION)
    .doc(professor_uid)
    .collection(_STREAMS_COLLECTION)
    .doc(_LIVE_STREAM_DOC)
    .get()
    .then((docSnapshot:firebase.firestore.DocumentSnapshot) => {
      if(docSnapshot.exists) {          
        return LiveStream.fromDataControllerBus(docSnapshot);
      } else {
        // Encapsula o erro
        Promise.reject(Errors.ObjectNotFound);
      }
    });
  }

  /**
   * 
   * @param professor_uid 
   */
  public async updateLiveStream(professor_uid: string, liveStream:LiveStream) : Promise<void> {
    // Aguarda o intervalo mínimo de acesso de escrita
    await this.awaitWriteInterval();
    // Doc path: professors/{professor_uid}/live_classes/{uid}
    return this._db
      .collection(_PROFESSORS_COLLECTION)
      .doc(professor_uid)
      .collection(_STREAMS_COLLECTION)
      .doc(liveStream.uid)
      .set(liveStream.toDataControllerBus())
      .catch((error)=>{
        // Encapsula o erro
        Promise.reject(ErrorBuilder(Errors.FailedToSaveData,error));
      })
      .finally(()=>{
        // Atualiza o tempo da última escrita
        FirebaseDcServiceService._lastWriteTimestamp = moment();
      });  
  }

  /**
   * 
   * @param professor_uid 
   * @param liveStream 
   */
  public async createLiveStream(professor_uid: string, liveStream:LiveStream) : Promise<LiveStream> {
    // Aguarda o intervalo mínimo de acesso de escrita
    await this.awaitWriteInterval();
    // Doc path: professors/{professor_uid}/live_classes/{uid}
    var newDocRef = this._db
      .collection(_PROFESSORS_COLLECTION)
      .doc(professor_uid)
      .collection(_STREAMS_COLLECTION)
      .doc();
      liveStream.uid = newDocRef.id;
      return newDocRef.set(liveStream.toDataControllerBus())
      .then(()=>{
        return liveStream;
      })
      .catch((error)=>{
        // Encapsula o erro
        throw(ErrorBuilder(Errors.FailedToSaveData,error));
        // TODO: verificar aqui porque nao posso Promise.reject
      })
      .finally(()=>{
        // Atualiza o tempo da última escrita
        FirebaseDcServiceService._lastWriteTimestamp = moment();
      });  
  }  


  /**
   * 
   * @param professor_uid 
   */
  public observeLiveStreamParticipants(professor_uid: string) : Observable<Array<Client>> {
    return new Observable<Array<Client>>(((subscriber) => {
      // Doc path: professors/{professor_uid}/live_classes/streaming_now/participants
      return this._db
      .collection(_PROFESSORS_COLLECTION)
      .doc(professor_uid)
      .collection(_STREAMS_COLLECTION)
      .doc(_LIVE_STREAM_DOC)
      .collection(_PARTICIPANTS_COLLECTION)
      .onSnapshot(async (querySnapshot: firebase.firestore.QuerySnapshot) => {
        var result = new Array<Client>();
        if(!querySnapshot.empty){
          for(let doc of querySnapshot.docs){
            result.push(Client.fromDataControllerBus(doc));
          }
        }
        this._zone.run(() => {                    
          subscriber.next(result);                    
        });
      });
    }));  
  }

  /**
   * 
   * @param professor_uid 
   */
  public clearLiveStreamParticipants(professor_uid: string) : Promise<void> {
      // Doc path: professors/{professor_uid}/live_classes/streaming_now/participants
      return this._db
      .collection(_PROFESSORS_COLLECTION)
      .doc(professor_uid)
      .collection(_STREAMS_COLLECTION)
      .doc(_LIVE_STREAM_DOC)
      .collection(_PARTICIPANTS_COLLECTION)      
      .get()
      .then(async (querySnapshot: firebase.firestore.QuerySnapshot) => {
        if(!querySnapshot.empty){
          querySnapshot.docs.forEach(async doc => await doc.ref.delete()); 
        }
      });
  }  

  /**
   * 
   * @param professor_uid 
   * @param client 
   */
  public async joinLiveStream(professor_uid: string, client:Client) : Promise<void> {
    // Aguarda o intervalo mínimo de acesso de escrita
    await this.awaitWriteInterval();
    // Doc path: professors/{professor_uid}/live_classes/streaming_now/participants/{client_uid}
    return this._db
      .collection(_PROFESSORS_COLLECTION)
      .doc(professor_uid)
      .collection(_STREAMS_COLLECTION)
      .doc(_LIVE_STREAM_DOC)
      .collection(_PARTICIPANTS_COLLECTION)
      .doc(client.uid)
      .set(client.toDataControllerBus())
      .catch((error)=>{
        // Encapsula o erro
        Promise.reject(ErrorBuilder(Errors.FailedToSaveData,error));
      })
      .finally(()=>{
        // Atualiza o tempo da última escrita
        FirebaseDcServiceService._lastWriteTimestamp = moment();
      });  
  }
  //#endregion

  //#region FEEDBACKS
  /**
   * 
   * @param c 
   */
   public async createFeedback(f: Feedback) : Promise<Feedback> {
    // Aguarda o intervalo mínimo de acesso de escrita
    await this.awaitWriteInterval();

    var newDocRef = this._db.collection(_FEEDBACKS_COLLECTION).doc();
    f.uid = newDocRef.id;
    return newDocRef.set(f.toDataControllerBus())
    .then(()=>{
      return f;
    })
    .catch((error)=>{
      // Encapsula o erro
      throw(ErrorBuilder(Errors.FailedToSaveData,error));
      // TODO: verificar aqui porque nao posso Promise.reject
    })
    .finally(()=>{
      // Atualiza o tempo da última escrita
      FirebaseDcServiceService._lastWriteTimestamp = moment();
    });    
  }  
  //#endregion
}
