<div class="card-padding">
    <mat-card>

        <mat-card-header>
            <div mat-card-avatar style="background-size: cover;" [ngStyle]="{ 'background-image': 'url(' + liveClass.ownerRef.displayInformation.avatarUrl + ')'}"></div>                        
            <mat-card-title>{{liveClass.streamRef.title || '--'}}</mat-card-title>
            <mat-card-subtitle>Professor {{liveClass.ownerRef.personalInformation.name}}</mat-card-subtitle>
            <mat-card-subtitle>Início: {{liveClass.streamRef.started_at | date:  'HH:mm , dd/MM/yyyy'}}</mat-card-subtitle>
        </mat-card-header>
        
        <img mat-card-image [src]="thumbnail_url">
    </mat-card>
</div>
