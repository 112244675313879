import { Utils } from '../utils/utils.functions';
import { IContactInformation, IPersonalInformation, IDisplayInformation, IDataControllable } from './common.model';


export enum ERole {
    Professor,
    Client,
    Manager
}

export interface TermsOfUseAcceptance {
    version: number;
    at: Date;
}

export interface IUser {

    uid: string;
    
    // Informações comuns a todos os usuários
    personalInformation?: IPersonalInformation;
    contactInformation?: IContactInformation;
    displayInformation?: IDisplayInformation;
    role?: ERole;
    accepted_terms_of_use: TermsOfUseAcceptance;
}

export class User implements IUser, IDataControllable {

    public uid: string;
    public alowed:boolean;
    
    // Informações comuns a todos os usuários
    public personalInformation?: IPersonalInformation;
    public contactInformation?: IContactInformation;
    public displayInformation?: IDisplayInformation;
    public role: ERole;
    public accepted_terms_of_use: TermsOfUseAcceptance;
    
    constructor(uid:string) {
        this.uid = uid;
        this.role = ERole.Client;
        this.alowed = false;
        this.accepted_terms_of_use = {version: 0, at: new Date()};
        this.personalInformation = {};
        this.contactInformation = {
            email:'',
            address: {},
            mobilePhone: {},
            residentialPhone : {}
        };
        this.displayInformation = {};
    }
    
    //#region Barramento de conversão para o Data Controller
    /**
     * Barramento de conversão entre o modelo de dados e o tipo nativo do DataController
     * OBS: nesse caso é do Firebase. Mas essa função deveria ser genérica
     * OBS: As datas devem ser convertidas do tipo Timestamp para Date
     * @param firebaseData 
     */    
    public static fromDataControllerBus(firebaseDocSnap:any) : User {
        var user = new User(firebaseDocSnap.id || firebaseDocSnap.uid);
        var firebaseData = (firebaseDocSnap.data) ? firebaseDocSnap.data() :  firebaseDocSnap;
        
        if(firebaseData.role !== undefined) user.role = firebaseData.role;
        if(firebaseData.alowed !== undefined) user.alowed = firebaseData.alowed;

        if(firebaseData.personalInformation !== undefined) user.personalInformation = firebaseData.personalInformation;
        if(firebaseData.personalInformation.birthday !== undefined) user.personalInformation!.birthday = (firebaseData.personalInformation.birthday as any).toDate();

        if(firebaseData.contactInformation !== undefined) user.contactInformation = firebaseData.contactInformation;

        if(firebaseData.displayInformation !== undefined) user.displayInformation = firebaseData.displayInformation;

        if(firebaseData.accepted_terms_of_use !== undefined) user.accepted_terms_of_use = firebaseData.accepted_terms_of_use;
        if(firebaseData.accepted_terms_of_use.at !== undefined) user.accepted_terms_of_use.at = (firebaseData.accepted_terms_of_use.at as any).toDate();
        return user;
    }

    /**
     * Barramento de conversão entre o modelo de dados e o tipo nativo do DataController
     */
    public toDataControllerBus() : object {
        return Utils.removeUndefinedKeys({
            uid: this.uid,
            alowed: this.alowed,
            accepted_terms_of_use: this.accepted_terms_of_use,
            personalInformation: this.personalInformation,
            contactInformation: this.contactInformation,
            displayInformation: this.displayInformation,
            role: this.role 
        });
    } 
    //#endregion   

    //#region Interface IPrimitiveObject
    /**
     * Converte o modelo para o tipo primitivo do javascript
     * As datas são serializadas como ISO String, e por conta
     * disso, convertidas para UTC.
     */
     public toPrimitiveObject(): object {
        return Utils.toJSON(this);
    } 

    /**
     * Converte um objeto do tipo primitivo do javascript serializado
     * para o modelo. 
     * É necessário converter todas as datas manualmente pois elas estão
     * serializadas como ISO Strings
     * @param primitive 
     */
    public static fromPrimitiveObject(p:object) : User {
        var primitive = p as any;
        var user = new User(primitive["uid"]);    

        if(primitive['role'] !== undefined) user.role = primitive['role'];
        if(primitive['alowed'] !== undefined) user.alowed = primitive['alowed'];

        if(primitive['personalInformation'] !== undefined) user.personalInformation = primitive['personalInformation'];
        if(primitive['personalInformation'].birthday !== undefined) user.personalInformation!.birthday = new Date(primitive['personalInformation'].birthday as any);

        if(primitive['contactInformation'] !== undefined) user.contactInformation = primitive['contactInformation'];

        if(primitive['displayInformation'] !== undefined) user.displayInformation = primitive['displayInformation'];

        if(primitive['accepted_terms_of_use'] !== undefined) user.accepted_terms_of_use = primitive['accepted_terms_of_use'];
        if(primitive['accepted_terms_of_use'].at !== undefined) user.accepted_terms_of_use.at = new Date(primitive['accepted_terms_of_use'].at);

        return user;
    }
    //#endregion  

}