import { Injectable } from '@angular/core';
import { FirebaseService } from '../firebase/firebase.service';
import { GetIpService } from '../get-ip/get-ip.service';

@Injectable({
  providedIn: 'root'
})
export class SessionWatchDogService {
  private loginWatchDogUnsubscribe: ()=>void;

  constructor(
    private _firebaseService:FirebaseService,
    private _ip:GetIpService,
  ) { }

  /**
   * 
   */
  public async start(uid:string, onOtherLogin:(sessionMetadata:any) => void) : Promise<void> {
    // Salva o ip utilizado e o timestamp do login
    let ip = await this._ip.fetch();
    let sessionWatchDog = this._firebaseService.getFirestore().collection('metadata').doc(uid);
    let sessionStart = new Date().getTime();    
    await sessionWatchDog.set({sessionStart: sessionStart, ip:ip});

    this.loginWatchDogUnsubscribe = sessionWatchDog.onSnapshot((snapshot)=>{
      // Observa todas as mudanças no objeto.
      // Usa por enquanto o ip logado como critério para determinar se deve fazer logout das demais sessões
      if(snapshot.data().ip !== ip){
        onOtherLogin(snapshot.data());
      }      
    });    
  }

  /**
   * 
   */
  public stop() : void {
    if(this.loginWatchDogUnsubscribe) this.loginWatchDogUnsubscribe();
  }
}
