import { Injectable } from '@angular/core';
import { AnalyticsService, AnalyticsEvents } from '../analytics/analytics.service';
import { Router } from '@angular/router';
import { GetIpService } from '../get-ip/get-ip.service';
import { ErrorBuilder, Errors, IError } from "../error-handling/error-definitions";
import { User } from '../../models/user.model';
import { OnlineDataControllerService } from '../online-data-controller/online-data-controller.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { SubscriptionPlan } from 'src/app/models/subscription.model';
import { Client } from 'src/app/models/client.model';

import { FirebaseService } from '../firebase/firebase.service';
import firebase from "firebase/app";
import "firebase/auth";
import { SnackBarService } from '../snack-bar/snack-bar.service';
import { SessionWatchDogService } from '../session-watch-dog/session-watch-dog.service';


export interface IAuthProviderUserBasicInfo {
  uid:string;
  name?:string;
  email?:string;
  profileUrl?:string;
}

export enum EAuthState {
  AccessDenied,
  AccessAllowed,  
  RegistrationRequired
}

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private auth:firebase.auth.Auth;
  private credential:firebase.auth.AuthCredential;  
  public firebaseUser: firebase.User;
  private additionalUserInfo: firebase.auth.AdditionalUserInfo;  
  
  // Observable para verificação do estado da autenticação (Negado, Permitido, Cadastro Necessário)
  // Qualquer component pode se subscrever para o 'authState' e receber suas modificações em tempo 
  // real;
  private authStateSubject = new BehaviorSubject<EAuthState>(EAuthState.AccessDenied);
  public  authState:Observable<EAuthState> = this.authStateSubject.asObservable();
  private setAuthState(state:EAuthState): void {
    this.authStateSubject.next(state);
  }

  // Observable para verificação do usuário logado. Qualquer component pode se subscrever para
  // o 'loggerUser' e receber suas modificações
  private loggedUserSubscription:Subscription;
  private loggerUserSubject = new BehaviorSubject<User>(null);
  public  loggedUser:Observable<User> = this.loggerUserSubject.asObservable();
  public setLoggedUser(user:User): void {
    this.loggerUserSubject.next(user);
  }

  // Observable para verificação da assinatura do usuário logado. Qualquer component pode se subscrever para
  // o 'loggerUserSubscription' e receber suas modificações
  private loggedUserSubsPlanSubscription:Subscription;
  private loggedUserSubsPlanSubject = new BehaviorSubject<SubscriptionPlan>(null);
  public  loggedUserSubsPlan:Observable<SubscriptionPlan> = this.loggedUserSubsPlanSubject.asObservable();
  public setloggedUserSubsPlan(plan:SubscriptionPlan): void {
    this.loggedUserSubsPlanSubject.next(plan);
  }

  constructor(
    private _firebaseService:FirebaseService,   // Apenas para garantir a inicialização do firebase
    private _analytics:AnalyticsService,
    private _dc:OnlineDataControllerService,
    private _router:Router,
    private _sessionWatchDog:SessionWatchDogService,
    private _snack:SnackBarService
  ) {

    this.auth = this._firebaseService.getAuth();
    this.firebaseUser = this.auth.currentUser;
    
    // Tratamento do evento de mudança no estado da autenticação referente ao
    // auth provider. Esse evento ocorre independente da aba ou navegador em
    // que o usuário fizer login/logout. Sendo possível propagar um logout
    // instantâneo 
    this.auth.onAuthStateChanged((user:firebase.User)=>{ 
      this.firebaseUser = user;
      if(this.firebaseUser){
        // Loga nos analytcs
        this._analytics.logEvent('Login', {uid:this.firebaseUser.uid});
        // Verifica se o usuário já existe na base de dados
        this._dc.getUser(this.firebaseUser.uid)
        .then((user:User) => {
          // Se encontrou o usuário então ele já existe e pode acessar o sistema
          this.startAuthAndRegisteredProcedures(user);
        })
        .catch((error:IError) => {
          // Se não encontrou, pode ser uma falha inexperada ou o usuário não existe no BD
          this.setLoggedUser(null);          
          switch(error){
            // Se ele não existe no BD então é necessário cadastrá-lo.
            case Errors.UserNotFound: {
              this.setAuthState(EAuthState.RegistrationRequired);
              break;
            }
            default:{
              this.setAuthState(EAuthState.AccessDenied);
            }
          }
        });      
      }else{
        // Ocorre quando o usuário se deslogou.
        if(this.loggedUserSubscription) this.loggedUserSubscription.unsubscribe();
        if(this.loggedUserSubsPlanSubscription) this.loggedUserSubsPlanSubscription.unsubscribe();
        this.setLoggedUser(null);
        this.setAuthState(EAuthState.AccessDenied);
        // Força um redirecionamento aqui pois ativa o logout em todas as abas simulataneamente
        this.secureApplication();
      }
    });
    
    // Desabilitando a persistencia local apenas para facilitar os testes
    this.auth.setPersistence(firebase.auth.Auth.Persistence.SESSION);
    //this.auth.setPersistence(environment.production ? firebase.auth.Auth.Persistence.SESSION : firebase.auth.Auth.Persistence.LOCAL); 
  }

  /**
   * 
   */
  public getLoggedUser(): User {
    return this.loggerUserSubject.value;
  }

  /**
   * 
   */
  public async startAuthAndRegisteredProcedures(user:User) : Promise<void> {    
    // Habilita um observable para o usuário logado          
    this.loggedUserSubscription = this._dc.observeUser(user.uid, user.role).subscribe((user)=>{
      this.setLoggedUser(user);
    });
    this.setLoggedUser(user);
    this.setAuthState(EAuthState.AccessAllowed);
    // Habilita um observable para o plano do usuário logado
    // TODO: Mover esse estado global para fora do serviço de autenticação
    this.loggedUserSubsPlanSubscription = this._dc.observeClientActiveSubscriptionPlan(user as Client).subscribe((subsPlan)=>{
      this.setloggedUserSubsPlan(subsPlan);
    });
    // Habilita um observable para verificar se outro login foi feito pelo mesmo usuário em outra tela/dispositivo
    this._sessionWatchDog.start(user.uid,(metadata)=>{      
        this._snack.show("Não são permitidos logins simultâneos com a mesma conta");
        this.logout();      
    }); 
  }

  /**
   * Verificação simples se existe um usuário autenticado. A verificação é feita baseado no
   * estado atual da autenticação, retorando true se o estado for 'AccessAllowed', significando
   * que o usuário está autenticado e devidamente registrado
   */
  public get isAuthenticated(): boolean {
    return (this.authStateSubject.value == EAuthState.AccessAllowed);    
  } 

  /**
   * Verifica se existe o login e se não existir, redireciona para o inicio.
   */
  public secureApplication() : Promise<boolean> {
    return new Promise<boolean>((resolve,reject)=>{
      if(this.authStateSubject.value == EAuthState.AccessDenied){
        this._router.navigate(['accessDenied']);
        resolve(false);
      }else{
        resolve(true);
      }
    });
  }

  /**
   * Realiza o login com o google através de um novo popup.
   * Por enquanto a promessa de retorno retorna exatamente o mesmo que o firebase. Mas pretendo encapsular isso futuramente
   */
  public loginWithGoogle() : Promise<void> {
    var provider = new firebase.auth.GoogleAuthProvider();
    /*
    provider.addScope("profile");
    provider.addScope("email");
    provider.addScope("https://www.googleapis.com/auth/contacts.readonly");
    provider.addScope("https://www.googleapis.com/auth/user.birthday.read");
    provider.addScope("https://www.googleapis.com/auth/userinfo.profile");
    provider.addScope("https://www.googleapis.com/auth/fitness.activity.read");
    provider.addScope("https://www.googleapis.com/auth/fitness.activity.write");
    provider.addScope("https://www.googleapis.com/auth/fitness.body.read");
    provider.addScope("https://www.googleapis.com/auth/fitness.body.write");
    */

    this.auth.useDeviceLanguage();

    return new Promise((resolve,reject)=>{
      this.auth.signInWithPopup(provider)
      .then((result) => {
        this.credential = result.credential;
        this.firebaseUser = result.user;
        this.additionalUserInfo = result.additionalUserInfo;        
      })
      .catch((error) => {   
        // Houve uma falha inexperada no login. Por enquanto não há tratamento
        // Apenas loga o erro e o propaga
        var errorCode = error.code;
        var errorMessage = error.message;
        var email = error.email;
        var credential = error.credential;
        
        // this._analytics.logEvent('Login', {
        //   status: "Error",
        //   name: email,
        //   errorCode: errorCode,
        //   errorMsg: errorMessage
        // });        
        this.setAuthState(EAuthState.AccessDenied);
        this.setLoggedUser(null);
        this._router.navigate(['accessDenied']);
        reject(ErrorBuilder(Errors.LoginFailed,error));
      });
    });

  }

  /**
   * Realiza o logout
   */
  public logout() : Promise<void> {    
    // Em caso de algum tratamento especial aqui, como remover do cache o usuário, etc
    return new Promise((resolve,reject)=>{
      this.auth.signOut()
      .then(()=>{        
        this._analytics.logEvent('Logout', {uid:this.firebaseUser.uid});

        if(this.loggedUserSubscription) this.loggedUserSubscription.unsubscribe();
        if(this.loggedUserSubsPlanSubscription) this.loggedUserSubsPlanSubscription.unsubscribe();
        this._sessionWatchDog.stop();
        resolve();
      })
      .catch((error)=>{
        reject(ErrorBuilder(Errors.UnexpectedFailure,error));
      })
      .finally(()=> {
        // Independente do resultado anula os parâmetros de segurança
        if(this.loggedUserSubscription) this.loggedUserSubscription.unsubscribe();
        this.setLoggedUser(null);
        this.setAuthState(EAuthState.AccessDenied);
        this._router.navigate(['accessDenied']);
      })
    });
  }
}
