import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { DefaultDialogComponent } from './components/default-dialog/default-dialog.component';
import { EDialogButtons, EDialogIcon } from './dialog.model';
import { TermsOfServiceDialogComponent } from './components/terms-of-service-dialog/terms-of-service-dialog.component';
import { PrivacyPolicyDialogComponent } from './components/privacy-policy-dialog/privacy-policy-dialog.component';
import { Observable } from 'rxjs';
import { OpenStreamDialogComponent } from 'src/app/components/live-class/open-stream-dialog/open-stream-dialog.component';
import { ComponentType } from '@angular/cdk/portal';
import { SubscriptionDialogComponent } from 'src/app/components/subscription-management/subscription-dialog/subscription-dialog.component';
import { BreakpointObserver } from '@angular/cdk/layout';
import { IPlan } from 'src/app/models/subscription.model';
import { CloseStreamDialogComponent } from 'src/app/components/live-class/close-stream-dialog/close-stream-dialog.component';
import { LiveStream } from 'src/app/models/live-stream.model';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  
  constructor(
    public _dialog: MatDialog,
    private _breakPointObserver:BreakpointObserver
  ) { }

  public getOpenDialogs() : MatDialogRef<any,any>[] {
    return this._dialog.openDialogs;
  }

  public createCustom(componentOrTemplateRef: ComponentType<unknown> | TemplateRef<unknown>, config?:MatDialogConfig<any>) : Observable<any> {
    return this._dialog.open(componentOrTemplateRef,config).afterClosed();
  }

  public createInformation(info:string) : Observable<any> {
    return this.createCustom(DefaultDialogComponent, {
      width:  this._breakPointObserver.isMatched('(max-width: 599px)') ? '90%' : '450px',
      data: {
        title: "Informação",
        message: info,
        buttons: EDialogButtons.OK,
        header_back_button: true,
        icon: EDialogIcon.INFORMATION
      },
      panelClass: 'custom-dialog-with-toolbar'
    });
  } 

  public createWarning(warning:string) : Observable<any> {
    return this.createCustom(DefaultDialogComponent, {
      width:  this._breakPointObserver.isMatched('(max-width: 599px)') ? '90%' : '450px',
      data: {
        title: "Atenção!",
        message: warning,
        buttons: EDialogButtons.OK,
        header_back_button: true,
        icon: EDialogIcon.WARNING
      },
      panelClass: 'custom-dialog-with-toolbar'
    });
  }   

  public createQuestion(question:string) : Observable<boolean> {
    return this.createCustom(DefaultDialogComponent, {
      width:  this._breakPointObserver.isMatched('(max-width: 599px)') ? '90%' : '450px',
      data: {
        title: "Pergunta",
        message: question,
        buttons: EDialogButtons.SIM_NAO,
        header_back_button: true,
        icon: EDialogIcon.QUESTION
      },
      panelClass: 'custom-dialog-with-toolbar'
    });
  } 

  public createError(errorMsg:string) : Observable<any> {
    return this.createCustom(DefaultDialogComponent, {
      width:  this._breakPointObserver.isMatched('(max-width: 599px)') ? '90%' : '450px',
      data: {
        title: "Erro",
        message: errorMsg,
        buttons: EDialogButtons.OK,
        header_back_button: true,
        icon: EDialogIcon.ERROR      
      },
      panelClass: 'custom-dialog-with-toolbar'
    });
  } 

  public createTermsOfService() : Observable<boolean> {
    return this.createCustom(TermsOfServiceDialogComponent, {
      width:  this._breakPointObserver.isMatched('(max-width: 599px)') ? '100vw' : '80%',
      //height: this._breakPointObserver.isMatched('(max-width: 599px)') ?'100vh' : '80%',
      maxWidth: '100vw',
      maxHeight: '100vh',
      disableClose: true,
      closeOnNavigation: false,
      panelClass: 'custom-dialog-with-toolbar'
    });
  } 

  public createPrivacyPolicy() : Observable<boolean> {
    return this.createCustom(PrivacyPolicyDialogComponent, {
      width:  this._breakPointObserver.isMatched('(max-width: 599px)') ? '100vw' : '80%',
      //height: this._breakPointObserver.isMatched('(max-width: 599px)') ?'100vh' : '80%',
      maxWidth: '100vw',
      maxHeight: '100vh',
      disableClose: true,
      closeOnNavigation: false,
      panelClass: 'custom-dialog-with-toolbar',
    });
  }

  public openStreamDialog() : Observable<any> {
    return this.createCustom(OpenStreamDialogComponent, {
      width:  this._breakPointObserver.isMatched('(max-width: 599px)') ? '90%' : '450px',
      panelClass: 'custom-dialog-with-toolbar'
    });
  }

  public closeStreamDialog(stream:LiveStream) : Observable<any> {
    return this.createCustom(CloseStreamDialogComponent, {
      width:  this._breakPointObserver.isMatched('(max-width: 599px)') ? '90%' : '450px',
      data: {
        stream: stream,
      },      
      panelClass: 'custom-dialog-with-toolbar'
    });
  }   

  public showSubscriptionPayment(plan:IPlan) : Observable<any> {
    return this.createCustom(SubscriptionDialogComponent, {
      width:  this._breakPointObserver.isMatched('(max-width: 599px)') ? '100vw' : '80%',
      height: this._breakPointObserver.isMatched('(max-width: 599px)') ?'100vh' : '80%',
      maxWidth: '100vw',
      maxHeight: '100vh',
      disableClose: true,
      closeOnNavigation: true,
      data:{
        plan:plan
      },
      panelClass: 'custom-dialog-with-toolbar',
    });
  }   

  public showWaitingForLiberationDialog() : Observable<any> {    
    return this.createCustom(DefaultDialogComponent, {
      width:  this._breakPointObserver.isMatched('(max-width: 599px)') ? '90%' : '450px',
      disableClose: true,
      closeOnNavigation: false,      
      data: {
        title: "Aguardando liberação",
        message: "Seu acesso ao sistema está pendente de liberação. Entre em contato com o professor Fred Reis.",
        icon: EDialogIcon.INFORMATION,
        buttons: EDialogButtons.OK
      },
      panelClass: 'custom-dialog-with-toolbar'
    });
  }   
}
