import { Injectable } from '@angular/core';
import { SnackBarService } from '../snack-bar/snack-bar.service';
import { IError } from './error-definitions';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlingService {

  constructor(private _snackBarService: SnackBarService) { }

  public show(error:IError) : void {
    // TODO: Criar uma snack customizada com ícone de erro, cor diferente, etc.
    this._snackBarService.show(
      error.code + 
      ': ' +
      error.message
    );
    console.error("Erro " + error.code +": " + error.message);
    if(error.innerException){
      console.error("Inner: " + error.innerException.message);      
    }     
  } 

}

