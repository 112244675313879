import { Component, OnInit, ElementRef, HostListener, ɵConsole, AfterViewInit } from '@angular/core';
import { ViewChild } from '@angular/core'

import { ClassMap, AnchorPointData, StageData } from '../../models/class-map.model';
import {TimelineLite, Linear, Elastic} from 'gsap';
import { Vector2d } from 'konva/types/types';
import { LocalDataControllerService } from 'src/app/services/local-data-controller/local-data-controller.service';
import { ActivatedRoute } from '@angular/router';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { SnackBarService } from 'src/app/services/snack-bar/snack-bar.service';
import { Image, Stage, Layer, Group, Text, Rect, Tween, Circle, Path, Line, Easings, Collection, RegularPolygon, KonvaEventObject } from 'konva/konva.min';

@Component({
  selector: 'app-class-map-editor',
  templateUrl: './class-map-editor.component.html',
  styleUrls: ['./class-map-editor.component.scss']
})
export class ClassMapEditorComponent implements OnInit, AfterViewInit {

  @ViewChild("konvaStageContainer",{static:true}) divContainerRef:ElementRef;

  /// Variáveis para desenho do editor de gráficos
  private stage:Stage;
  private backgroundLayer:Layer;
  private headerInfoLayer:Layer;
  private edgesLayer:Layer;
  private anchorPointsLayer:Layer;
  private animationControlBarGroup:Group;

  /// Textos dinâmicos
  private textPSE:Text;
  private textTime:Text;
  private textRPM:Text;
  private textCoords:Text;

  // Indicador de zona
  private trainingZoneIndicator:Group;
  private trainingZoneIndicatorRect:Rect;
  private trainingZoneIndicatorText:Text;
  private trainingZoneHiddingTween:Tween;

  /// Controle dos Anchor Points
  private lastAnchorPointRef:Circle;
  public selectedAnchorPointRef:Circle;

  /// Variáveis para desenho da animação
  private animationLayer:Layer;
  private animationShape:Circle;
  private animationCountdownModal:Rect;
  private animationCountdownText:Text;
  private animationSliderControl:Path;
  private animation:TimelineLite;
  private animationCountdown:TimelineLite;

  private animationSliderControlxPositions;

  // Os valores são sempre iniciados como constantes pois após a inicialização do Stage,
  // as transformações necessárias serão calculadas e todos os pontos inicias são referentes
  // ao tamanho pré-definido.
  private stageScale: Vector2d;
  private readonly stageWidth = 1366;
  private readonly stageHeight = 768;


  /// Definições para desenho do anchor point
  // Obs: talvez o raio e borda tenham que ser calculados em referencia ao tamanho do stage para responsividade
  private anchorPointDefaultDrawingDef = {
    fill :        "#000000",
    stroke:       "#FFFFFF",
    radius:       10,
    strokeWidth:  3,
    dash:         []
  };
  private anchorPointOnMouseEnterDrawingDef = {
    strokeWidth:  4,
    dash:         [3,3]
  };
  private anchorPointOnMouseLeaveDrawingDef = {
    strokeWidth:  this.anchorPointDefaultDrawingDef.strokeWidth,
    dash:         this.anchorPointDefaultDrawingDef.dash
  };
  private anchorPointSelectedDrawingDef = {
    fill :        "#FF0000",
    dash:         [3,3]
  };
  private anchorPointNotSelectedDrawingDef = {
    fill :        this.anchorPointDefaultDrawingDef.fill,
    dash:         this.anchorPointDefaultDrawingDef.dash
  };
  private anchorPointTransparentDrawingDef = {
    fill :        "#FFFFFF",
    radius:       this.anchorPointDefaultDrawingDef.radius / 5,
  };
  private anchorPointAnimationDrawingDef = {
    fill :        "#DE00FF",
    dash:         [3,3]
  };

  // Definições para desenho das linhas que unem os anchor points
  private edgeDefaultDrawingDef = {
    fill :        "#FFFFFF",
    stroke:       this.anchorPointDefaultDrawingDef.stroke,
    strokeWidth:  this.anchorPointDefaultDrawingDef.strokeWidth+3,
  };

  // Definições das zonas de treino
  private ZonesAndPSE: Array<any> = [
    { 
      color:'#1C1E23',
      training_zone: null,
      pse: null
    },{ 
      color:'#e6301e',
      training_zone: "6",
      pse: '8-10'
    },{ 
      color:'#6d4e9b',
      training_zone: "5",
      pse: '6-7'
    },{ 
      color:'#ef7b13',
      training_zone: "4",
      pse: '4-5'
    },{ 
      color:'#f2e71a',
      training_zone: "3",
      pse: '3-4'
    },{ 
      color:'#49ad32',
      training_zone: "2",
      pse: '2-3'
    },{ 
      color:'#4e75b9',
      training_zone: "1",
      pse: '1'
  }];  

  /// -------------------------------------------------------------
  /// Propriedades de controle dos macros
  /// -------------------------------------------------------------
  private shiftKeyHold: Boolean;
  private ctrlKeyHold: Boolean;
  public isEdittingPoint: Boolean;

  /// -------------------------------------------------------------
  /// Bindings com elementos de Form da tela e armazenamento
  /// -------------------------------------------------------------
  public classInfo:ClassMap;
  


  /**
   * Construtor. Recebe injetado o snackbar e o Service do Local Data Controller
   * @param _snackBar 
   * @param _dc 
   */
  constructor(
    private _snackBar: SnackBarService, 
    private _route: ActivatedRoute,
    private _dc:LocalDataControllerService,
    private _dialogService: DialogService) {
      // Sempre instancia uma nova aula para que os bindings de componentes na tela não falhem
      // enquanto o restante dos componentes é carregado. Essa referência é substituída automaticamente
      // posteriormente no observable dos parâmetros recebidos
      this.classInfo = new ClassMap(60);      
  }

  /**
   * Implementação da interface OnInit. Inicializa todos os elementos do gráfico.
   */
  public ngOnInit() : void {
    this.isEdittingPoint = false;
  }

  /**
   * Implementação da interface AfterViewInit. Inicializa todos os elementos do gráfico
   */
  public ngAfterViewInit(): void {

    // Trata o recebimento de parâmetros opcionais na url
    // Quando um 'id' é recebido, assume que deve carregar a aula
    // Quando 'id' é nulo assume que é uma nova aula
    this._route.paramMap.subscribe(params => {
      // Cria o stage para edição da aula
      this.createStage();
      // Aplica as transformações necessárias para redimensionar o stage na janela
      this.fitStageToContainer();      
      // Quando as fontes carregarem é necessário redesenhar tudo pois a primeira renderização ocorre com as fontes
      // default do browser pelas outras não estarem carregadas
      (document as any).fonts.ready.then(()=>{this.stage.draw()}); 

      let classId = params.get("id");
      if(classId == null){
        // Uma nova aula
        // Sempre inicia novas aulas com 60 minutos
        this.classInfo = new ClassMap(60);
        // É necessário limpar o stage pois o Angular só reinstancia o componente caso ele não esteja mais no DOM,
        // Desta forma, caso o usuário navegue de uma aula carregada para o link de nova aula, o componente já estará criado na tela.
        this.clearStage();
        // Começa o gráfico com um ponto na origem.
        this.addAnchorPoint({x:0,y:this.stage.height()});       
      }else{
        // Carrega uma aula (descartando modificações que não foram salvas)
        this.loadClass(classId);
      }
    });  


    // Tratamento para redimensionamento da janela
    window.addEventListener("resize", (event)=>{
      this.fitStageToContainer();
    });

    // Tratador para redimensionamento do stage ao maximizar a visualização
    document.addEventListener("fullscreenchange", (event)=>{
      if(document.fullscreenElement){
        this.fitStageToScreen();
      }else{
        // Restaura o stage para o tamanho do seu container
        this.fitStageToContainer();
      }
    });
  }

  /**
   * Tratador de click no stage. Adiciona um anchor path para o gráfico conetado ao último da lista
   */
  private onClickBackgroundHandler = (e:KonvaEventObject<MouseEvent>) => {
    if(this.isInteractionAllowed){
      var pos = this.stage.getPointerPosition();
      this.addAnchorPoint(pos);
    }else{
      this.showSnackBar("Não é possível adicionar um ponto enquanto há uma animação sendo executada.");
    }
  }

  /**
   * Instancia o Stage e todos os seus layers
   */
  private createStage(): void {
    this.stageScale = {x:1.0, y:1.0};
    // -------------------------------------------------------
    // Instancia e inicia os elementos
    // Stage
    this.stage = new Stage({
      container: "konvaStageContainer",
      width: this.stageWidth,
      height: this.stageHeight
    });

    // Layers
    this.backgroundLayer = new Layer();
    this.headerInfoLayer = new Layer();
    this.edgesLayer = new Layer();
    this.anchorPointsLayer = new Layer();
    this.animationLayer = new Layer();

    // Adiciona os tratadores de eventos
    this.backgroundLayer.on("click tap", this.onClickBackgroundHandler);
    this.divContainerRef.nativeElement.addEventListener("mouseenter", (e)=>{
      // Por enquanto a barra estará fixada
      // this.showAnimationControlBar();
    });
    this.divContainerRef.nativeElement.addEventListener("mouseleave", (e)=>{
      // Por enquanto a barra estará fixada
      // this.hideAnimationControlBar();
    });

    this.trainingZoneIndicator = new Group();

    // Compoe os elementos na ordem correta das camadas
    this.stage.add(this.backgroundLayer);
    this.stage.add(this.headerInfoLayer);
    this.stage.add(this.edgesLayer);
    this.stage.add(this.anchorPointsLayer);
    this.stage.add(this.animationLayer);

    // Desenha o background
    this.fillBackgroundLayer();
    this.fillHeaderInfoLayer();

    // Cria uma exibição para as coordenadas do ponto selecionado
    this.textCoords = new Text({
      x: 0,
      y: 0,
      text: "(0,0)",
      fontSize: 14,           // TODO: Tamanho ser proporcional ao tamanho do stage
      fontFamily: "Verdana",  // TODO: Fonte melhor
      fill: "black",
      fontStyle: "bold",
      visible: false
    });
    this.anchorPointsLayer.add(this.textCoords);

    // Cria os objetos da animação

    this.animationShape = new Circle({
      ...this.anchorPointDefaultDrawingDef,
      ...this.anchorPointAnimationDrawingDef,
      ...{
        x: 0,
        y: 0,
        visible: false
    }});
    this.animationLayer.add(this.animationShape);    
    this.animationCountdownModal = new Rect({
      x:0,
      y:0,
      width: this.stage.width(),
      height: this.stage.height(),
      fill: "#000000",
      opacity: 0.5,
      visible: false
    });
    this.animationLayer.add(this.animationCountdownModal);

    this.animationCountdownText = new Text({
      x: this.animationCountdownModal.width()/2,
      y: this.animationCountdownModal.height()/2,
      text: "-",
      fontSize: 300,
      fontFamily: "Myriad Pro",
      fill: "#FFFFFF",
      fontStyle: "bold",
      visible: false     
    });
    this.animationLayer.add(this.animationCountdownText);
    this.animationCountdownText.offset({
      x:this.animationCountdownText.width()/2,
      y:this.animationCountdownText.height()/2
    });

    this.animation = new TimelineLite();
    this.stopAnimation();

    // Cria o slider de controle da timeline da animação
    this.animationSliderControlxPositions = new Object();
    this.animationSliderControlxPositions["x_min"] = (this.stage.width()/10)*2;
    this.animationSliderControlxPositions["x_max"] = (this.stage.width()/10)*8;
    this.animationSliderControl = new Path({
      x: this.animationSliderControlxPositions.x_min,
      y: this.stage.height()/7,
      data:"M15.5 5.5c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zM5 12c-2.8 0-5 2.2-5 5s2.2 5 5 5 5-2.2 5-5-2.2-5-5-5zm0 8.5c-1.9 0-3.5-1.6-3.5-3.5s1.6-3.5 3.5-3.5 3.5 1.6 3.5 3.5-1.6 3.5-3.5 3.5zm5.8-10l2.4-2.4.8.8c1.3 1.3 3 2.1 5.1 2.1V9c-1.5 0-2.7-.6-3.6-1.5l-1.9-1.9c-.5-.4-1-.6-1.6-.6s-1.1.2-1.4.6L7.8 8.4c-.4.4-.6.9-.6 1.4 0 .6.2 1.1.6 1.4L11 14v5h2v-6.2l-2.2-2.3zM19 12c-2.8 0-5 2.2-5 5s2.2 5 5 5 5-2.2 5-5-2.2-5-5-5zm0 8.5c-1.9 0-3.5-1.6-3.5-3.5s1.6-3.5 3.5-3.5 3.5 1.6 3.5 3.5-1.6 3.5-3.5 3.5z",
      fill: "#931B4A",
      draggable: true,
      // Permite apenas arrastar dentro dos limites do x
      dragBoundFunc : (pos)=>{
        // Converte a posição recebida para relativa às transformações sofridas pelo stage
        var transform = this.stage.getAbsoluteTransform().copy();
        transform.invert();
        var relativePos = transform.point(pos);

        var x = relativePos.x;
        if(relativePos.x < this.animationSliderControlxPositions.x_min){
          x = this.animationSliderControlxPositions.x_min;
        }
        if(relativePos.x > this.animationSliderControlxPositions.x_max){
          x = this.animationSliderControlxPositions.x_max;
        }

        // Converte novamente a posição calculada para aplicar as transformações sofridas pelo stage
        var transform = this.stage.getAbsoluteTransform().copy();
        var absolutePos = transform.point({x:x, y:0});
        return {
          x: absolutePos.x,
          y: this.animationSliderControl.getAbsolutePosition().y
        }}
    });
    this.animationSliderControl.y((this.animationSliderControl.y()-this.animationSliderControl.getSelfRect().height)-1);
    this.animationSliderControl.on("mouseenter", (e)=>{
      var slider = e.target as Path;
      document.body.style.cursor = 'pointer';
    });
    this.animationSliderControl.on("mouseleave", (e)=>{
      var slider = e.target as Path;
      document.body.style.cursor = 'default';
    });
    this.animationSliderControl.on("dragmove", (e)=>{
      var slider = e.target as Path;
      this.animation.progress(
        (slider.x() - this.animationSliderControlxPositions.x_min) /
        (this.animationSliderControlxPositions.x_max - this.animationSliderControlxPositions.x_min)
      );
    });

    this.animationLayer.add(this.animationSliderControl);
  }

  /**
   * Aplica as transformações necessárias de escala para adaptar o stage ao container
   */
  private fitStageToContainer() : void {
    var width = this.divContainerRef.nativeElement.clientWidth;
    var height = this.divContainerRef.nativeElement.clientHeight;
    this.stageScale.x = width / this.stageWidth;
    this.stageScale.y = height / this.stageHeight;
    this.stage.width(width);
    this.stage.height(height);
    this.stage.scale(this.stageScale);
    this.stage.batchDraw();        
  }

  /**
   * Aplica as transformações necessárias de escala para adaptar o stage a tela inteira (modo fullscreen)
   */
  private fitStageToScreen() : void {
    this.stageScale.x = screen.width / this.stageWidth;
    this.stageScale.y = screen.height / this.stageHeight;
    this.stage.width(screen.width);
    this.stage.height(screen.height);
    this.stage.scale(this.stageScale);
    this.stage.draw();    
  }

  /**
   * Remove todos os anchor points e edges
   */
  private clearStage() : void {
    // Removendo os pontos atuais e as arestas que os conectam
    this.anchorPointsLayer.find("Circle").each((point)=>{
      this.deleteAnchorPoint(point as Circle);
    });

    this.lastAnchorPointRef = undefined;    
  }

  /**
   * Preenche o background da área do gráfico com os retângulos nas cores pré-determinadas, incluindo
   * o retângulo reservado para o header, estipulando no mesmo tamanho dos demais.
   */
  private async fillBackgroundLayer() : Promise<void> {
    const rectHeight = this.stage.height() / this.ZonesAndPSE.length;
    const rectWidht = this.stage.width();

    for(var i=0; i<this.ZonesAndPSE.length; i++){
      // Cor de fundo
      var rect = new Rect({
        x: 0,
        y: 0+(rectHeight * i),
        width: rectWidht,
        height: rectHeight,
        fill: this.ZonesAndPSE[i].color
      });
      this.backgroundLayer.add(rect);
      // PSE
      if(this.ZonesAndPSE[i].pse){
        var textPSE = new Text({
          x: (this.stage.width()/20),
          y: (rectHeight/2) + (rectHeight * i),
          text: this.ZonesAndPSE[i].pse,
          fontSize: 48,
          fontFamily: "Myriad Pro",
          fill: "#FFFFFF",
          fontStyle: "bold",
          opacity: 0.35     
        });
        this.backgroundLayer.add(textPSE);
        textPSE.offset({
          x:textPSE.width() / 2,
          y:textPSE.height() / 2
        });
      }
      // Zonas de treino
      if(this.ZonesAndPSE[i].training_zone){
        var textTrainingZone = new Text({
          x: (this.stage.width()/20)*19,
          y: (rectHeight/2) + (rectHeight * i),
          text: 'Z'+this.ZonesAndPSE[i].training_zone,
          fontSize: 48,
          fontFamily: "Myriad Pro",
          fill: "#FFFFFF",
          fontStyle: "bold",
          opacity: 0.35     
        });
        this.backgroundLayer.add(textTrainingZone);
        textTrainingZone.offset({
          x:textTrainingZone.width() / 2,
          y:textTrainingZone.height() / 2
        });
      }
    }

    // Controles da animação
    this.fillAnimationControlBar();

    // Desenha a marca d'agua no fundo    
    Image.fromURL('/assets/images/watermark.png', (imageNode) => {
      this.backgroundLayer.add(imageNode);
      imageNode.scale({x:1.25,y:1.25});
      imageNode.offset({
        x:imageNode.width() / 2
      });      
      imageNode.setAttrs({
        x: (rectWidht/2),
        y: rectHeight,
        opacity: 0.15
      });

      this.backgroundLayer.draw();      
    });
  }

  /**
   * Preenche as informações do cabeçalho. O posicionamento é relativo ao tamanho do stage quando iniciado
   */
  private fillHeaderInfoLayer() : void {
    // Label estático do PSE
    var pseLabel = new Text({
      x: this.stage.width()/20,
      y: this.stage.height()/14,
      text: "PSE",
      fontSize: 48,
      fontFamily: "Myriad Pro",
      fontStyle: "bold",
      fill: "#931B4A"
    });
    // Alinhando o texto no seu centro
    pseLabel.offset({
        x:pseLabel.width() / 2,
        y:pseLabel.height() / 2
    });
    this.headerInfoLayer.add(pseLabel);

    // Label estático do RPM
    var rpmLabel = new Text({
      x: (this.stage.width()/20)*17,
      y: this.stage.height()/14,
      text: "RPM",
      fontSize: 48,
      fontFamily: "Myriad Pro",
      fontStyle: "bold",
      fill: "#931b4a"
    });
    // Alinhando o texto no seu centro
    rpmLabel.offset({
        x:rpmLabel.width() / 2,
        y:rpmLabel.height() / 2
    });
    this.headerInfoLayer.add(rpmLabel);

    // Linhas de divisória verticais
    var vLine = new Line({
      points: [(this.stage.width()/10)*2, 10, (this.stage.width()/10)*2, (this.stage.height()/7)-10],
      stroke: "#FFFFFF",
      strokeWidth: 1,
      opacity: 0.25,
      dash: [4,2]
    });
    this.headerInfoLayer.add(vLine);
    var vLine = new Line({
      points: [(this.stage.width()/10)*8, 10, (this.stage.width()/10)*8, (this.stage.height()/7)-10],
      stroke: "#FFFFFF",
      strokeWidth: 1,
      opacity: 0.25,
      dash: [4,2]
    });
    this.headerInfoLayer.add(vLine);

    // Inicia os demais textos que são dinâmicos
    // valor do PSE
    this.textPSE = new Text({
      x: (this.stage.width()/20)*3,
      y: this.stage.height()/14,
      text: "",
      fontSize: 48,
      fontFamily: "ds-digi",
      fill: "#FFFFFF"
    });
    this.headerInfoLayer.add(this.textPSE);
    this.updatePSEText("---");

    // Tempo
    this.textTime = new Text({
      x: (this.stage.width()/2),
      y: this.stage.height()/14,
      text: "",
      fontSize: 48,
      fontFamily: "ds-digi", // TODO: Fix font
      fill: "#FFFFFF"
    });
    this.headerInfoLayer.add(this.textTime);
    this.updateTimeText("00:00:00");

    // valor do RPM
    this.textRPM = new Text({
      x: (this.stage.width()/20)*19,
      y: this.stage.height()/14,
      text: "",
      fontSize: 48,
      fontFamily: "ds-digi", // TODO: Fix font
      fill: "#FFFFFF"
    });
    this.headerInfoLayer.add(this.textRPM);
    this.updateRPMText("---");

    // Indicador de zona de treino
    this.createTrainingZoneIndicator();

    // Desenha
    this.headerInfoLayer.draw();
  }

  /**
   * Atualiza o label contendo o PSE e mantendo-o centralizado
   * @param value Valor a ser escrito no label
   */
  private updatePSEText(value:string) : void {
    this.textPSE.text(value);
    this.textPSE.offset({
      x:this.textPSE.width() / 2,
      y:this.textPSE.height() / 2
    });
    this.headerInfoLayer.draw();
  }

  /**
   * * Atualiza o label contendo o tempo decorrido e mantendo-o centralizado
   * @param value Valor a ser escrito no label
   */
  private updateTimeText(value:string) : void {
    this.textTime.text(value);
    this.textTime.offset({
      x:this.textTime.width() / 2,
      y:this.textTime.height() / 2
    });
    this.headerInfoLayer.draw();
  }

  /**
   * Atualiza o label contendo o RPM e mantendo-o centralizado
   * @param value Valor a ser escrito no label
   */
  private updateRPMText(value:string) : void {
    this.textRPM.text(value);
    this.textRPM.offset({
      x:this.textRPM.width() / 2,
      y:this.textRPM.height() / 2
    });
    this.headerInfoLayer.draw();
  }

  /**
   * Obtem a quantidade de segundos por pixel definida de acordo com a duração da aula e o tamanho do stage
   * @returns Segundos / Pixel do stage
   */
  public get stageSecondsPerPixel() : number {
    // Notei que ao aplicar a transformação de escala no stage, as coordenadas dos pontos se mantém as mesmas após a transformada
    // Dessa forma ...
    return (this.classInfo.totalTimeSeconds / this.stage.width());
  }

  /**
   * Retorna true se a interação do usuário for permitida. Ela não é permitida caso há uma animação sendo tocada
   * @returns Verdadeiro se o usuário pode interagir com o gráfico no momento
   */
  private get isInteractionAllowed() : boolean {
    return !(this.animation.isActive() || this.animationLayer.visible());
  }

  /**
   * Realiza a conversão de uma coordenada no eixo x para tempo de acordo com o tamanho do stage e da duração da aula
   * @param x Coordenada
   * @returns Tempo em formato de data
   */
  private xCoordToTime(x:number) : Date {
    // Notei que ao aplicar a transformação de escala no stage, as coordenadas dos pontos se mantém as mesmas após a transformada
    // Mas o width do stage muda. Dessa forma preciso sempre obter o x em relação ao stage atual
    var transform = this.stage.getAbsoluteTransform().copy();
    var relativePos = transform.point({x:x, y:0});

    var time = new Date(null);
    time.setSeconds(Math.ceil(relativePos.x * this.stageSecondsPerPixel)); // Arredondamento sempre para o próximo inteiro
    return time;
  }

  /**
   * Realiza a conversão de uma coordenada no eixo x para tempo de acordo com o tamanho do stage e da duração da aula
   * e retorna o tempo formatado em hh:mm:ss
   * @param x Coordenada
   * @returns Tempo formatado hh:mm:ss
   */
  public xCoordToTimeStr(x:number) : String {
    return this.xCoordToTime(x).toISOString().substr(11, 8);
  }

  /**
   * Formata a coordenada x recebida em uma string de relativo ao gráfico da aula com unidade
   * para exibição.
   * @param y Coordenada
   */  
  public xCoordToTimeStrForDisplay(x:number) : String {
    return "X: " + this.xCoordToTimeStr(x) + "hs";
  }

  /**
   * Converte uma string de tempo no formato 00:00:00 para a coordenada x equivalente
   * @param timeStr String de tempo no formato 00:00:00
   * @returns coordenada x equivalente ou NaN no caso de uma string mal formada
   */
  public timeStrToXcoord(timeStr:string) : number {       
    if(timeStr.length != 8) return NaN; 
    var tokens = timeStr.split(':');    
    var milisseconds = ((Number(tokens[0] || 0)*3600) + (Number(tokens[1] || 0)*60) + (Number(tokens[2] || 0))) * 1000;    
    var xCoord = (milisseconds / this.classInfo.totalTimeMilliseconds) * this.stageWidth;
    return xCoord;
  }

  /**
   * Converte um percentual inteiro para a coordenada no eixo y
   * OBS: Apenas na área da Zona de treino e com origem relativa ao canto inferior esquerdo
   * @param percentage Inteiro 0 a 100
   * @return y coordenada entre 0 e stageHeight em pixels
   */
  public percentageToYcoord(percentage:number) : number {
    var trainingZoneHeight = (this.stageHeight / 7); // Dividido igualmente em 1 cabeçalho + 6 zonas de treino
    var headerHeight = trainingZoneHeight;
    var trainingZoneTotalHeight = (trainingZoneHeight) * 6; // Dividido igualmente em 1 cabeçalho + 6 zonas de treino
    return (trainingZoneTotalHeight * ((100-percentage)/100)) + headerHeight;
  }

  /**
   * Converte uma coordenada em y para um percentual
   * OBS: Apenas na área da Zona de treino e com origem relativa ao canto inferior esquerdo
   * @param y coordenada entre 0 e stageHeight em pixels
   * @returns Inteiro 0 a 100
   */
  public yCoordToPercentage(y:number) : number {
    var trainingZoneHeight = (this.stageHeight / 7); // Dividido igualmente em 1 cabeçalho + 6 zonas de treino
    var headerHeight = trainingZoneHeight;
    var trainingZoneTotalHeight = (trainingZoneHeight) * 6; // Dividido igualmente em 1 cabeçalho + 6 zonas de treino
    var rel_y = trainingZoneTotalHeight + headerHeight - y;
    
    return (rel_y/trainingZoneTotalHeight) * 100;
  }

  /**
   * Formata a coordenada y recebida em um percentual relativo ao gráfico da aula com unidade
   * para exibição.
   * @param y Coordenada
   */
  public yCoordToPercentageStrForDisplay(y:number) : string {
    return "Y: " + this.yCoordToPercentage(y).toFixed(1) + "%";
  }  

  /// ------------------------------------------------------------------------------------------------
  /// TRATAMENTOS PARA AÇÕES SOBRE OS ANCHOR POINTS
  /// ------------------------------------------------------------------------------------------------  
  //#region 
  /**
   * Adiciona um Anchor Point ao gráfico
   * @param x Coordenada x relativa ao stage
   * @param y Coordenada y relativa ao stage
   */
  private addAnchorPoint(pos:Vector2d, id?:number, minimized?:boolean) : void {
    // Ignora as transformações aplicadas no stage para calculo da posicao do ponto
    var transform = this.stage.getTransform().copy();
    transform.invert();
    pos = transform.point(pos);

    var anchorPoint = new Circle({
      ...this.anchorPointDefaultDrawingDef,
      ...{
        x: pos.x,
        y: pos.y,
        radius: this.anchorPointDefaultDrawingDef.radius/2, // Começa com metade do raio para que a animação atue
        draggable: true,
    }});

    // Caso receba um id pré-definido determina-o
    if(id) anchorPoint._id = id;

    // Evento quanto o mouse entra no anchor point
    anchorPoint.on("mouseenter", (e)=>{
      var aPoint = e.target as Circle;

      // Obtem a posicao relativa do ponto, caso haja alguma transformacao aplicada ao stage
      // para se poder comparar ao height do stage atual
      var transform = this.stage.getAbsoluteTransform().copy();
      var relativePos = transform.point(aPoint.position());

      // Isso aqui é uma marretada por conta de um erro de arredondamento que está ocorrendo e eu não sei explicar o motivo
      relativePos.y = Math.round(relativePos.y);

      // Se houver animação tocando não deixa arrastar os pontos
      // Se for o ponto na origem (0,height) também não deixa arrastar.
      if((!this.isInteractionAllowed) || (relativePos.x==0 && relativePos.y==this.stage.height())){
        document.body.style.cursor = "not-allowed";
        aPoint.setAttrs({...this.anchorPointOnMouseEnterDrawingDef,...{draggable:false}});
      }else{
        document.body.style.cursor = 'move';
        aPoint.setAttrs({...this.anchorPointOnMouseEnterDrawingDef,...{draggable:true}});
      }

      // Cria uma exibição para as coordenadas do ponto
      this.textCoords.setAttrs({
        x: aPoint.x() - (this.textCoords.width()/2),
        y: aPoint.y() - aPoint.radius() - 35, // Acima do ponto 25px
        text: this.xCoordToTimeStrForDisplay(aPoint.x())+'\n'+this.yCoordToPercentageStrForDisplay(aPoint.y()),
        visible:true
      });
      this.anchorPointsLayer.draw();
    });
    // Evento quanto o mouse sai do anchor point
    anchorPoint.on("mouseleave", (e)=>{
      document.body.style.cursor = 'default';
      var aPoint = e.target as Circle;
      if(aPoint == this.selectedAnchorPointRef){
        aPoint.setAttrs(this.anchorPointSelectedDrawingDef);
      }else{
        aPoint.setAttrs(this.anchorPointOnMouseLeaveDrawingDef);
      }

      // Esconde a exibição para as coordenadas do ponto
      this.textCoords.visible(false);
      this.anchorPointsLayer.draw();
    });
    // Eventos de clique, toque e inicio de arrastar do anchor point
    anchorPoint.on("click tap dragstart", (e)=>{
      var aPoint = e.target as Circle;
      this.selectAnchorPoint(aPoint);

      // Verifica os macros de controle para arrastar na vertical(ctrl)/horizontal(shift)
      if(this.ctrlKeyHold){
        // Habilita apenas drag na vertical
        aPoint.dragBoundFunc((pos)=>{
          return {
            x: aPoint.getAbsolutePosition().x,
            y: pos.y
          };
        });
      }else if(this.shiftKeyHold){
        // Habilita apenas drag na horizontal
        aPoint.dragBoundFunc((pos)=>{
          return {
            x: pos.x,
            y: aPoint.getAbsolutePosition().y
          };
        });
      }else{
        // Habilita drag em qualquer direção
        aPoint.dragBoundFunc((pos)=>{
          return {
            x: pos.x,
            y: pos.y
          };
        });
      }
    });
    // Move a visualização das coordenadas junto
    anchorPoint.on("dragmove", (e)=>{
      var aPoint = e.target as Circle;

      // TODO: Não deixar arrastastar para antes de um anchor point que já esteja à esquerda

      // Cria uma exibição para as coordenadas do ponto
      this.textCoords.setAttrs({
        x: aPoint.x() - (this.textCoords.width()/2),
        y: aPoint.y() - aPoint.radius() - 35, // Acima do ponto 25px
        text: this.xCoordToTimeStrForDisplay(aPoint.x())+'\n'+this.yCoordToPercentageStrForDisplay(aPoint.y()),
      });
      this.textCoords.draw();
    });

    // Cria uma linha do ùltimo anchor point adicionado (caso haja) para este novo
    if(this.lastAnchorPointRef) {
      this.addEdge(this.lastAnchorPointRef, anchorPoint);
    }

    // Adiciona o ponto criado no layer
    this.anchorPointsLayer.add(anchorPoint);

    // Cria uma pequena animação que faz um tween do radius do ponto para o tamanho final
    // para o ponto criado
    anchorPoint.to({
      duration: 0.5,
      easing: Easings.BackEaseInOut,
      radius: this.anchorPointDefaultDrawingDef.radius,
      onFinish: () => {
        if(minimized != undefined){
          if(minimized) this.minimizeAnchorPoint(anchorPoint);
        }
      }
    });

    // Atualiza a referência do último ponto adicionado
    this.lastAnchorPointRef = anchorPoint;
  }

  /**
   * Marca o anchor point recebido como 'selecionado'
   * @param aPoint Referencia para o anchor point a ser selecionado
   */
  private selectAnchorPoint(aPoint:Circle) : void {
    // Deseleciona o atual casa haja
    if(this.selectedAnchorPointRef){
      this.selectedAnchorPointRef.setAttrs(this.anchorPointNotSelectedDrawingDef);
    }
    // Seleciona o requisitado
    aPoint.setAttrs(this.anchorPointSelectedDrawingDef);

    // Atualiza a referencia global
    this.selectedAnchorPointRef = aPoint;
    // Desenha o layer dos anchor points
    this.anchorPointsLayer.draw();
  }

  /**
   * Adiciona o efeito que 'minimiza' um ponto
   * @param aPoint Referencia para o anchor point a ser aplicado o efeito
   */
  private minimizeAnchorPoint(aPoint:Circle) : void {
    aPoint.setAttrs(this.anchorPointTransparentDrawingDef);
    this.anchorPointsLayer.draw();
  }

  /**
   * Retorna os anchor points adjascentes ao ponto recebido
   * @param aPoint Ponto para busca
   * @returns Coleção de pontos adjascentes ao ponto recebido
   */
  private getAdjacentAnchorPointsToAnchorPoint(aPoint:Circle) : Collection<Circle> {
    var adjacentsAnchorPoints:Collection<Circle> = new Collection<Circle>();

    // Primeiro obtem as arestas adjascentes ao ponto
    var adjascentsEdges = this.getAdjacentEdgesToAnchorPoint(aPoint);

    // Para cada aresta, obtem os pontos adjascentes a ela
    adjascentsEdges.each( edge => {
      // Para cada ponto obtido, adiciona à lista, desde que seja diferente do ponto em questao
      this.getAdjacentAnchorPointsToEdge(edge).each( p => {
        if(p != aPoint){
          adjacentsAnchorPoints.push(p);
        }
      });
    });
    return adjacentsAnchorPoints;
  }

  /**
   * Retorna as arestas adjascentes ao ponto recebido
   * @param aPoint Ponto para busca
   * @returns Coleção de arestas adjascentes ao ponto recebido
   */
  private getAdjacentEdgesToAnchorPoint(aPoint:Circle) : Collection<Line> {
    // Obtem todas as arestas que começam ou terminam no anchor point
    return this.edgesLayer.find((edge:Line) => {
      var edgePoints = edge.points();
      if((edgePoints[0] == aPoint.x() && edgePoints[1] == aPoint.y()) || // Começam no ponto OU
         (edgePoints[2] == aPoint.x() && edgePoints[3] == aPoint.y())){  // Terminam no ponto
        return true;
      }
      return false;
    });
  }

  /**
   * Exclui o anchor point recebido e a(s) linhas que o conectam e se necessário, cria uma nova linha
   * @param aPoint Referencia do ponto a ser excluído
   */
  private deleteAnchorPoint(aPoint:Circle) : void{
    var adjacentAPoints:Collection<Circle> = this.getAdjacentAnchorPointsToAnchorPoint(aPoint);
    var adjacentEdges:Collection<Line> = this.getAdjacentEdgesToAnchorPoint(aPoint);

    // Se houverem dois pontos adjascentes significa que é necessário criar uma nova aresta
    // para conecta-los
    if(adjacentAPoints.length == 2){
      this.addEdge(adjacentAPoints[0],adjacentAPoints[1]);
    }else if(adjacentAPoints.length == 1){
    // Se houver apenas um ponto adjascente, então o ponto que está sendo excluído é também o último
    // inserido. Neste caso deve-se atualizar a referência do último ponto para o adjascente
      this.lastAnchorPointRef = adjacentAPoints[0];
    }

    // Correção de bug que ocorre quando o ponto é excluído mas o mouse estava em cima do objeto,
    // causando uma perda de referência para o evento 'mouseleave'. A correção é remover o tratador
    // antes da exclusão. Ah, e forçar o ponteiro para default
    document.body.style.cursor = 'default';
    aPoint.off("mouseleave");

    // Apaga o ponto e remove a referência de ponto selecionado
    aPoint.destroy();
    this.selectedAnchorPointRef = null;

    // Apaga as arestas adjascentes
    adjacentEdges.each(edge => {
      edge.destroy();
    });

    // Esconde a posicao
    this.textCoords.visible(false);

    // Redesenha as camadas
    this.anchorPointsLayer.draw();
    this.edgesLayer.draw();
  }

  /**
   * Move um ponto (e suas arestas adjascentes) para a coordenada 
   * @param pos Posição absoluta a ser movido
   */
  public moveAnchorPointTo(pos:Vector2d): void {
    if(Number.isNaN(pos.x) || Number.isNaN(pos.y)) return;
    
    // Move as arestas adjascentes
    var edges = this.getAdjacentEdgesToAnchorPoint(this.selectedAnchorPointRef);
    edges.each((edge,index)=>{
      var edgePoints = edge.points();
      if(edgePoints[0] == this.selectedAnchorPointRef.x() && edgePoints[1] == this.selectedAnchorPointRef.y()){
        edge.points([pos.x, pos.y, edgePoints[2], edgePoints[3]]);
      }else{
        edge.points([edgePoints[0], edgePoints[1], pos.x, pos.y]);
      }
    });
    // Move o ponto
    this.selectedAnchorPointRef.position(pos);

    // Redesenha
    this.anchorPointsLayer.batchDraw();
    this.edgesLayer.batchDraw();     
  }
  //#endregion

  /// ------------------------------------------------------------------------------------------------
  /// TRATAMENTOS PARA AÇÕES SOBRE AS ARESTAS
  /// ------------------------------------------------------------------------------------------------
  //#region 
  /**
   * Cria uma linha entre os anchor points a e b, cuja posição é atualizada dinamicamente ao evento
   * de arrastar dos pontos
   * @param aPoint_a Ponto a
   * @param aPoint_b Ponto b
   */
  private addEdge(aPoint_a:Circle, aPoint_b:Circle) : void {
    var line = new Line({
      ...this.edgeDefaultDrawingDef,
      ...{
        points: [aPoint_a.x(), aPoint_a.y(), aPoint_b.x(), aPoint_b.y()],
    }});

    // Cria o evento de arrastar do último ponto adicionado, afinal agora é
    // necessário atualizar a linha também
    aPoint_a.on("dragmove", (e)=>{
      var aPoint = e.target as Circle;
      line.points([aPoint.x(), aPoint.y(), line.points()[2], line.points()[3]]);
      this.edgesLayer.batchDraw();
    });

    // Cria o evento de arrastar do ponto adicionado agora, afinal agora é
    // necessário atualizar a linha também
    aPoint_b.on("dragmove", (e)=>{
      var aPoint = e.target as Circle;
      line.points([line.points()[0], line.points()[1], aPoint.x(), aPoint.y()]);
      this.edgesLayer.batchDraw();
    });

    // Adiciona a linha criada ao layer e o desenha
    this.edgesLayer.add(line);
    this.edgesLayer.draw();
  }

  /**
   * Retorna os pontos adjascentes à aresta recebida
   * @param edge Aresta para busca
   * @returns Coleção de pontos adjascentes à aresta recebida
   */
  private getAdjacentAnchorPointsToEdge(edge:Line) : Collection<Circle> {
    // Obtem todas os pontos que são um começo ou fim da aresta
    return this.anchorPointsLayer.find((aPoint:Circle) => {
      var edgePoints = edge.points();
      if((edgePoints[0] == aPoint.x() && edgePoints[1] == aPoint.y()) || // Começam no ponto OU
         (edgePoints[2] == aPoint.x() && edgePoints[3] == aPoint.y())){  // Terminam no ponto
        return true;
      }
      return false;
    });
  }
  //#endregion
  /// ------------------------------------------------------------------------------------------------
  /// BARRA DE CONTROLE DA ANIMAÇÃO
  /// ------------------------------------------------------------------------------------------------
  //#region 
  /**
   * Desenha a barra de controle da animação
   * OBS: Pode ser transferida para fora do stage no futuro
   */
  private fillAnimationControlBar() : void {
    var width = (this.stage.width()/10)*2;
    var height = (this.stage.height()/21)+5;
    this.animationControlBarGroup = new Group({
      x: (this.stage.width()/10)*4,
      y: (this.stage.height() - height),
    });
    this.backgroundLayer.add(this.animationControlBarGroup);
    // Fundo
    var bkg = new Rect({
      x: 0,
      y: 0,
      width: width,
      height: height,
      fill: "#1C1E23",
      shadowColor: "black",
      shadowBlur: 5,
      shadowOffset: { x: 5, y: 5 },
      shadowOpacity: 0.5
    });
    this.animationControlBarGroup.add(bkg);

    // Botão de play
    var playBtn = new Path({
      data: "M8 6.82v10.36c0 .79.87 1.27 1.54.84l8.14-5.18c.62-.39.62-1.29 0-1.69L9.54 5.98C8.87 5.55 8 6.03 8 6.82z", // Play
      x: 0,
      y: 0,
      scale: {x:1.6,y:1.6},
      fill: "#ACACAC"
    });
    playBtn.on("mouseenter", (e)=>{
      var btn = e.target as RegularPolygon;
      document.body.style.cursor = 'pointer';
      btn.setAttrs({
        fill: "#931B4A",
      });
      btn.draw();
    });
    playBtn.on("mouseleave", (e)=>{
      var btn = e.target as RegularPolygon;
      document.body.style.cursor = 'default';
      btn.setAttrs({
        fill: "#ACACAC",
      });
      btn.draw();
    });
    playBtn.on("click tap", (e)=>{
      var btn = e.target as RegularPolygon;
      // Cancela a propagação do evento prevnindo que o click seja invocado por outros layers
      e.cancelBubble = true;
      this.playAnimation();
    });
    this.animationControlBarGroup.add(playBtn);

    // Botão de pause
    var pauseBtn= new Path({
      data: "M8 19c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2s-2 .9-2 2v10c0 1.1.9 2 2 2zm6-12v10c0 1.1.9 2 2 2s2-.9 2-2V7c0-1.1-.9-2-2-2s-2 .9-2 2z", // Pause
      x: 36,
      y: 0,
      scale: {x:1.6,y:1.6},
      fill: "#ACACAC"
    });
    pauseBtn.on("mouseenter", (e)=>{
      var btn = e.target as RegularPolygon;
      document.body.style.cursor = 'pointer';
      btn.setAttrs({
        fill: "#931B4A",
      });
      btn.draw();
    });
    pauseBtn.on("mouseleave", (e)=>{
      var btn = e.target as RegularPolygon;
      document.body.style.cursor = 'default';
      btn.setAttrs({
        fill: "#ACACAC",
      });
      btn.draw();
    });
    pauseBtn.on("click tap", (e)=>{
      var btn = e.target as RegularPolygon;
      // Cancela a propagação do evento prevnindo que o click seja invocado por outros layers
      e.cancelBubble = true;
      this.pauseAnimation();
    });
    this.animationControlBarGroup.add(pauseBtn);

    // Botão de stop
    var stopBtn= new Path({
      data: "M8 6h8c1.1 0 2 .9 2 2v8c0 1.1-.9 2-2 2H8c-1.1 0-2-.9-2-2V8c0-1.1.9-2 2-2z", // Stop
      x: 72,
      y: 0,
      scale: {x:1.6,y:1.6},
      fill: "#ACACAC"
    });
    stopBtn.on("mouseenter", (e)=>{
      var btn = e.target as RegularPolygon;
      document.body.style.cursor = 'pointer';
      btn.setAttrs({
        fill: "#931B4A",
      });
      btn.draw();
    });
    stopBtn.on("mouseleave", (e)=>{
      var btn = e.target as RegularPolygon;
      document.body.style.cursor = 'default';
      btn.setAttrs({
        fill: "#ACACAC",
      });
      btn.draw();
    });
    stopBtn.on("click tap", (e)=>{
      var btn = e.target as RegularPolygon;
      // Cancela a propagação do evento prevnindo que o click seja invocado por outros layers
      e.cancelBubble = true;
      this.stopAnimation();
    });
    this.animationControlBarGroup.add(stopBtn);


    // Linha divisória

    // Botão de maximizar com fundo
    var maxBtn= new Path({
      data: "M6 14c-.55 0-1 .45-1 1v3c0 .55.45 1 1 1h3c.55 0 1-.45 1-1s-.45-1-1-1H7v-2c0-.55-.45-1-1-1zm0-4c.55 0 1-.45 1-1V7h2c.55 0 1-.45 1-1s-.45-1-1-1H6c-.55 0-1 .45-1 1v3c0 .55.45 1 1 1zm11 7h-2c-.55 0-1 .45-1 1s.45 1 1 1h3c.55 0 1-.45 1-1v-3c0-.55-.45-1-1-1s-1 .45-1 1v2zM14 6c0 .55.45 1 1 1h2v2c0 .55.45 1 1 1s1-.45 1-1V6c0-.55-.45-1-1-1h-3c-.55 0-1 .45-1 1z", // Stop
      x: width-36,
      y: 0,
      scale: {x:1.6,y:1.6},
      fill: "#ACACAC"
    });
    var maxBtnBkg = new Rect({
      x: width-36,
      y: 0,
      width : 36,
      height: 36,
      fill: "#1C1E23"
    });
    maxBtnBkg.on("mouseenter", (e)=>{
      document.body.style.cursor = 'pointer';
      maxBtn.setAttrs({
        fill: "#931B4A",
      });
      maxBtn.draw();
    });
    maxBtnBkg.on("mouseleave", (e)=>{
      document.body.style.cursor = 'default';
      maxBtn.setAttrs({
        fill: "#ACACAC",
      });
      maxBtn.draw();
    });
    maxBtnBkg.on("click tap", (e)=>{
      // Cancela a propagação do evento prevnindo que o click seja invocado por outros layers
      e.cancelBubble = true;
      this.toggleFullScreen();
    });
    this.animationControlBarGroup.add(maxBtnBkg);
    this.animationControlBarGroup.add(maxBtn);

    // Por enquanto a barra estará fixada
    // this.hideAnimationControlBar();
  }

  /**
   * Obtem o tempo decorrido da animação formatado em hh:mm:ss
   * @returns Tempo decorrido da animação formatado em hh:mm:ss
   */
  public get animationElapsedTimeStr() : string {
    var elapsedTime = new Date(null);
    elapsedTime.setSeconds(this.animation.time());
    return elapsedTime.toISOString().substr(11, 8);
  }

  /**
   * Toca a animação
   */
  public playAnimation() : void {
    if(this.animation.paused()){
      //this.audioPlayer.play();      
      this.animation.resume();
      return;
    }else{
      // Obtem todos os pontos e os ordena pelo id (OBS: pode ser que esse critério falhe pois nao controlo o id)
      var anchorPoints = this.anchorPointsLayer.find("Circle").toArray().sort((p1,p2)=>{
        if(p1.id() < p2.id()){
          return -1;
        }
        if(p1.id() > p2.id()){
          return 1;
        }
        return 0;
      });

      if(anchorPoints.length == 1) return;

      this.animationLayer.visible(true);
      // Posiciona o node no primeiro ponto e o torna visível
      this.animationShape.setAttrs({
        x:anchorPoints[0].x(),
        y:anchorPoints[0].y()
      });

      // Cria a contagem regressiva
      this.animationCountdown = new TimelineLite({
        onStart: ()=>{
          //console.log("this.animationCountdown / onStart");
          this.animationCountdownModal.visible(true);
          this.animationCountdownText.visible(true);
        },
        onComplete: ()=>{
          //console.log("this.animationCountdown / onComplete");
          this.animationCountdownModal.visible(false);
          this.animationCountdownText.visible(false);
          this.animationShape.visible(true);
          this.animation.play();
        }
      });
      var countdownSeconds = 10;
      const beginAt = 9;

      this.animationCountdownText.text(countdownSeconds.toString());
      this.centerText(this.animationCountdownText);
      this.animationCountdownText.scale({x:0.1, y:0.1});

      for(var i=beginAt; i>=0; i--){
        this.animationCountdown.to(
          this.animationCountdownText,
          1,
          {
            ease: Elastic.easeInOut,
            konva:{
              scaleX: 1,
              scaleY: 1
            },
            onComplete: ()=>{
              countdownSeconds = countdownSeconds-1;                         
              this.animationCountdownText.text(countdownSeconds.toString());
              this.centerText(this.animationCountdownText);
              this.animationCountdownText.scale({x:0.1, y:0.1});  
            }            
          });
      }
      this.animationCountdown.pause();

      // Cria a transição entre os anchor points
      this.animation.kill();
      this.animation = new TimelineLite({
        onUpdate: ()=>{
          //console.log("this.animation / onUpdate");
          this.updateAnimationTime();
          this.updateAnimationSliderPosition();
          this.updateAnimationPSE();
        },
        onComplete: ()=>{
          //console.log("this.animation / onComplete");
          this.animationLayer.visible(false);
        }
      });
      this.animation.pause();
      for(var j=1; j<anchorPoints.length; j++){
        var deltaTime = this.xCoordToTime(anchorPoints[j].x() - anchorPoints[j-1].x());
        var durationSeconds = (deltaTime.getMinutes() * 60) + (deltaTime.getSeconds());
        this.animation.to(this.animationShape, durationSeconds, {
          konva:{
            x: anchorPoints[j].x(),
            y: anchorPoints[j].y()
          },
          ease: Linear.easeNone,
          onStartParams: [anchorPoints[j-1]],
          onStart: (params)=>{
            //console.log("this.animation.to["+i+"] / onStart");
            // Seta o RPM do ponto de partida
            this.updateRPMText(this.classInfo.rpmMap[params._id]);
          }
        });
      }

      this.animationCountdown.play();
      //this.audioPlayer.play();
    }
  }

  /**
   * Pausa a animação
   */
  public pauseAnimation() : void {
    this.animation.pause();
    //this.audioPlayer.pause();
  }

  /**
   * Interrompe e reinicia a execução da animação
   */
  public stopAnimation() : void {
    if(this.animation.paused()){
      this.animation.resume();
    }
    this.animation.seek(0);
    this.animation.clear();
    this.animationLayer.visible(false);

    //this.audioPlayer.pause();
    //this.audioPlayer.currentTime = 0;    
  }

  /**
   * Atualiza o label com o tempo decorrido da animação
   */
  private updateAnimationTime() : void {
    this.updateTimeText(this.animationElapsedTimeStr);
  }

  private createTrainingZoneIndicator(): void {
    // Retângulo indicador
    this.trainingZoneIndicatorRect = new Rect({
      x: (this.stageWidth/10)*2,
      y: 10,
      width:  (this.stage.width()/10)*2,
      height: (this.stage.height()/7)-20,
      fill: "#000000",
      stroke: "#FFFFFF",
      strokeWidth: 1,
      dash: [4,2,0.25]      
    });
    this.trainingZoneIndicator.add(this.trainingZoneIndicatorRect);
    
    // Label da zona
    this.trainingZoneIndicatorText = new Text({
      x: (this.stage.width()/10)*3,
      y: ((this.stage.height()/7)/2),
      text: "Zona -",
      fontSize: 48,
      fontFamily: "Myriad Pro",
      fill: "#FFFFFF"
    });
    this.trainingZoneIndicator.add(this.trainingZoneIndicatorText);

    this.trainingZoneIndicator.opacity(0);
    this.headerInfoLayer.add(this.trainingZoneIndicator); 
  }

  /**
   * 
   */
  private showTrainingZoneIndicator(currentTrainingZone:number): void {
    var zone;    
    this.ZonesAndPSE.forEach((item)=>{
      if(item.training_zone == currentTrainingZone){
        zone = item;
      }
    });
    
    if(this.trainingZoneHiddingTween){
      this.trainingZoneHiddingTween.finish();
    }

    this.trainingZoneIndicatorRect.fill(zone.color);
    this.trainingZoneIndicatorText.text('Zona ' + zone.training_zone);
    this.trainingZoneIndicatorText.offset({
      x:this.trainingZoneIndicatorText.width() / 2,
      y:this.trainingZoneIndicatorText.height() / 2
    });
    this.trainingZoneIndicator.opacity(1);
    this.trainingZoneIndicator.draw();

    // Animação de esconder
    this.trainingZoneHiddingTween = new Tween({
      node: this.trainingZoneIndicator,
      duration: 10,
      opacity: 0
    });   
    this.trainingZoneHiddingTween.play();
  }

  /**
   * Atualiza o label indicando a zona de treino de acordo com a tabela de percepção de esforço
   * e de acordo com a posição do anchor point da animação
   */
  private currentTrainingZone:number;
  private updateAnimationPSE() : void {
    const ZoneAndPSE = [{pse: "8-10", zone:6},{pse:"6-7",zone:5},{pse:"4-5",zone:4},{pse:"3-4",zone:3},{pse:"2-3",zone:2},{pse:" 1 ",zone:1}];
    // Verifica em qual das zonas de treino está o ponto da animação
    var trainingZoneHeight = this.stageHeight / (7); // 6 zonas + o header (que tem o mesmo tamanho)
    var headerHeight = trainingZoneHeight;

    // Se esta na zona i
    for(var i=0; i<=ZoneAndPSE.length-1; i++){
      if((this.animationShape.y() > (headerHeight+(i*trainingZoneHeight))) &&
         (this.animationShape.y() < (headerHeight+((i+1)*trainingZoneHeight)))){
        // Verifica se houve transição entre zonas. 
        if(ZoneAndPSE[i].zone != this.currentTrainingZone){
          // Havendo, exibe um retângulo informativo por 10 segundos
          this.showTrainingZoneIndicator(ZoneAndPSE[i].zone);
        }
        this.currentTrainingZone = ZoneAndPSE[i].zone;
        this.updatePSEText(ZoneAndPSE[i].pse);
      }
    }
  }

  /**
   * Atualiza o indicador do slider (atualmente um ícone de bike) de acordo com o progresso da animação
   */
  private updateAnimationSliderPosition() : void {
    // Existe um bug que ocorre aqui na instanciação da animação e no stop da animação. Nesses momentos, animation.progress() == undefined, resultando
    // em x = NaN. Para corrigir, fiz o workaround de só atualizar se a animação estiver tocando.
    if(this.animation.isActive()){
      let x = this.animationSliderControlxPositions.x_min + this.animation.progress() * (this.animationSliderControlxPositions.x_max - this.animationSliderControlxPositions.x_min);    
      this.animationSliderControl.x(x);
    }
  }

  /**
   * Tween de exibição da barra de controle da animação.
   * Obs: Atualmente não sendo usado pois a barra está fixada
   */
  private showAnimationControlBar() : void {
    this.animationControlBarGroup.to({
      y:this.stage.height() - 35,
      duration : 1,
      easing: Easings.ElasticEaseOut
    });
    // Esconde a barra automaticamente após 5 segundos
    // Obs: desabilitado momentaneamente
    /*
    setTimeout(() => {
      this.hideAnimationControlBar();
    }, 5000);
    */
  }

  /**
   * Tween para esconder a barra de controle da animação
   * Obs: Atualmente não sendo usado pois a barra está fixada
   */
  private hideAnimationControlBar() : void {
    this.animationControlBarGroup.to({
      y:this.stage.height(),
      duration : 0.5,
      easing: Easings.EaseIn
    });
  }

  /**
   * Habilita/Desabilita full screen para o elemento container do stage
   */
  public toggleFullScreen() : void {
    if(!document.fullscreenElement){
      this.divContainerRef.nativeElement.requestFullscreen();
    }else{
      document.exitFullscreen();
    }
  }
  //#endregion

  /// ------------------------------------------------------------------------------------------------
  /// TRATAMENTOS PARA TECLAS DE ATALHO
  /// ------------------------------------------------------------------------------------------------
  //#region 
  /// ------------------------------------------------------------------------------------------------
  /// SHIFT - Macro para travar movimentação de anchor point na horizontal
  /// ------------------------------------------------------------------------------------------------
  @HostListener('window:keydown.Shift', ['$event'])
  public onShiftKeyDown(event: KeyboardEvent) : void {
    this.shiftKeyHold = true;
  }
  @HostListener('window:keyup.Shift', ['$event'])
  public onShiftKeyUp(event: KeyboardEvent) : void {
    this.shiftKeyHold = false;
  }
  /// ------------------------------------------------------------------------------------------------
  /// CTRL - Macro para travar movimentação de anchor point na vertical
  /// ------------------------------------------------------------------------------------------------
  @HostListener('window:keydown.Control', ['$event'])
  public onCtrlKeyDown(event: KeyboardEvent) : void {
    this.ctrlKeyHold = true;
  }
  @HostListener('window:keyup.Control', ['$event'])
  public onCtrlKeyUp(event: KeyboardEvent) : void {
    this.ctrlKeyHold = false;
  }
  /// ------------------------------------------------------------------------------------------------
  /// Insert - Macro para tornar um anchor point selecionado "invisível"
  /// ------------------------------------------------------------------------------------------------
  @HostListener('window:keyup.Insert', ['$event'])
  public onInsertKeyUp(event: KeyboardEvent) : void {
    // Tem alguma ação apenas se houver um selecionado
    if(this.selectedAnchorPointRef){
      this.minimizeAnchorPoint(this.selectedAnchorPointRef);
    }else{
      this.showSnackBar("Nenhum ponto selecionado para se tornar 'invisivel'");
    }
  }
  /// ------------------------------------------------------------------------------------------------
  /// Delete - Macro para excluir um anchor point
  /// ------------------------------------------------------------------------------------------------
  @HostListener('window:keyup.Delete', ['$event'])
  public onDeleteKeyUp(event: KeyboardEvent) : void {
    // Só exclui um ponto selecionado se ele não estiver sendo editado
    if(!this.isEdittingPoint){  
      // Obtem a posicao relativa do ponto, caso haja alguma transformacao aplicada ao stage
      // para se poder comparar ao height do stage atual
      var transform = this.stage.getAbsoluteTransform().copy();
      var relativePos = transform.point(this.selectedAnchorPointRef.position());
      // Isso aqui é uma marretada por conta de um erro de arredondamento que está ocorrendo e eu não sei explicar o motivo
      relativePos.y = Math.round(relativePos.y);
      if(this.selectedAnchorPointRef){
        if(!this.isInteractionAllowed){
          this.showSnackBar("Não é possível excluir um ponto enquanto há uma animação sendo executada");
        }else if(relativePos.x==0 && relativePos.y==this.stage.height()){
          this.showSnackBar("Não é possível excluir o ponto de origem");
        }else{
          this._dialogService.createQuestion("Confirma a exclusão do ponto?").subscribe((result)=>{
            if(result){
              this.deleteAnchorPoint(this.selectedAnchorPointRef);
            }
          });
        }
      }else{
        this.showSnackBar("Nenhum ponto selecionado para exclusão");
      }
    }
  }
  /// ------------------------------------------------------------------------------------------------
  /// Escape - Sai da tela cheia
  /// ------------------------------------------------------------------------------------------------
  @HostListener('window:keyup.Escape', ['$event'])
  public onEscapeKeyUp(event: KeyboardEvent) : void {    
    //this.toggleFullScreen();
  }
  //#endregion

  /// ------------------------------------------------------------------------------------------------
  /// SALVAR / CARREGAR AULA
  /// ------------------------------------------------------------------------------------------------
  //#region 
  /**
   * Salva a aula que está atualmente em exibição localmente no Indexed DB
   */
  public saveClass() : void {
    
    // Atualiza as informações do stage 
    this.classInfo.stageData = new StageData();
    this.classInfo.stageData.anchorPoints = new Array<AnchorPointData>();
    this.anchorPointsLayer.find("Circle").each( p => {
      this.classInfo.stageData.anchorPoints.push(new AnchorPointData(p._id,p.position(),(p.getAttr("radius") != this.anchorPointDefaultDrawingDef.radius)));
    });    

    this.stage.transformsEnabled("none");
    this.classInfo.thumbnail_base64 = this.stage.toDataURL({
      // Descontando o cabeçalho
      x:0,
      y:(this.stageHeight / 7), 
      width: this.stageWidth,
      height: (this.stageHeight / 7) * 6,
      // -----------------------
      pixelRatio: 0.30, // 30% do tamanho original
      callback: ()=>{
        this.stage.transformsEnabled("all");
      }
    });

    this._dc.saveClassMap(this.classInfo)
    .then(() => {
      this.showSnackBar("Aula salva com sucesso");
    })
    .catch((error) => {
      this.showSnackBar("Falha ao salvar a aula - Erro " + error);
    });
  }

  /**
   * Carrega uma aula no stage dado seu id.
   * A aula atual é perdida.
   * @param id ID da aula a ser carregada
   */
  public loadClass(id:string) : void {
    this._dc.loadClassMap(id)
    .then((loadedClassInfo:ClassMap) => {
      // Atualiza todos os elementos do stage atual
      this.classInfo = new ClassMap(loadedClassInfo.totalTimeMinuts);
      this.classInfo.createdAt = loadedClassInfo.createdAt;
      this.classInfo.updatedAt = loadedClassInfo.updatedAt;
      this.classInfo.id = loadedClassInfo.id;
      this.classInfo.name = loadedClassInfo.name;
      this.classInfo.description = loadedClassInfo.description;
      this.classInfo.rpmMap = loadedClassInfo.rpmMap;
      this.classInfo.stageData = loadedClassInfo.stageData;  

      this.clearStage();

      // Adicionando os carregados com os mesmos ids e posicoes
      this.classInfo.stageData.anchorPoints.forEach((point)=>{               
        this.addAnchorPoint(this.stage.getTransform().point(point.position), point._id, point.minimized);
      });    
      
      this.showSnackBar("Aula carregada com sucesso");
    })
    .catch((error) => {
      this.showSnackBar("Falha ao carregar a aula - Erro " + error);
    });
  }
  //#endregion
  
  /// ------------------------------------------------------------------------------------------------
  /// PROPRIEDADES DO PONTO
  /// ------------------------------------------------------------------------------------------------
  //#region 
  public validateAndUpdateXCoordOfSelectedAnchorPoint(event) : void {    
    var input = event.target as HTMLInputElement;
       
    // Valida a entrada
    // 1) Deve estar no formato 00:00:00    
    var valor = input.value as string;
    if(valor.length != 8){
      // TODO: mostrar tooltip dizendo "O Formato deve ser 00:00:00"
      return;
    }
    // 2) Se está maior que o tempo de aula (ou o tamanho do stage equivalentemente)
    var xCoord = this.timeStrToXcoord(valor);
    if(xCoord > this.stageWidth){
      // TODO: mostrar tooltip dizendo "Formato tempo não pode ser maior que o tempo da aula"
      return;
    }

    // Se passou na validação, move o anchor point
    this.moveAnchorPointTo({x:xCoord, y:this.selectedAnchorPointRef.y()});
  }

  public validateAndUpdateYCoordOfSelectedAnchorPoint(event) : void {
    var input = event.target as HTMLInputElement;

    // Valida a entrada
    // 1) Deve ser entre 0 e 100   
    var valor = Number(input.value);
    if(Number.isNaN(valor) || valor < 0 || valor > 100){
      // TODO: mostrar tooltip dizendo "Entre 0 e 100"
      return;
    }

    var yCoord = this.percentageToYcoord(valor);

    // Se passou na validação, move o anchor point
    this.moveAnchorPointTo({x:this.selectedAnchorPointRef.x(), y:yCoord});
  }  
  //#endregion

  /// ------------------------------------------------------------------------------------------------
  /// AUXILIARES
  /// ------------------------------------------------------------------------------------------------  
  //#region 

  /**
   * Troca o offset do texto para seu centro, centralizando-o horizontalmente e verticalmente
   * @param text 
   */
  private centerText(text:Text): void{
    text.offset({
      x:text.width()/2,
      y:text.height()/2
    });
  }

  /**
   * Exibe uma snackbar com a string recebida
   * @param msg String a ser exibida
   */
  private showSnackBar(msg:string){
    this._snackBar.show(msg);
  }

  private audioPlayer = new Audio();
  public testeAudioTrack() : void {
    console.log("teste");
    var file = (event.target as HTMLInputElement).files[0];
    var fileReader = new FileReader();
    fileReader.onload = (e) => {    
      this.audioPlayer.src = (e.target as FileReader).result as string;    
    };
    fileReader.readAsDataURL(file);
  }
  //#endregion
}
