import { Injectable } from '@angular/core';
import { IPlan } from 'src/app/models/subscription.model';
import { environment } from 'src/environments/environment';
import { AnalyticsEvents } from '../analytics/analytics.service';

export declare let fbq:Function;

@Injectable({providedIn: 'any'})
export class FacebookService {
  private static readonly appId:string = '534430810917098';
  private static readonly pixelId:string = '463902341424619';

  constructor() { }

  public async loadSDK() : Promise<void> {
    return new Promise<void>((resolve)=>{
      window['fbAsyncInit'] = function () {
        FB.init({
          appId      : FacebookService.appId,
          xfbml      : true,
          version    : 'v10.0'
        });
        FB.AppEvents.logPageView();
        resolve();
      };
  
      (function(d, s, id){
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {return;}
        js = d.createElement(s); js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));  
    });
  }

  public async loadPixel() : Promise<void> {
    return new Promise<void>((resolve)=>{
      (function (f: any, b, e, v, n, t, s) {
        if (f.fbq) return; n = f.fbq = function () {
            n.callMethod ?
                n.callMethod.apply(n, arguments) : n.queue.push(arguments)
        }; if (!f._fbq) f._fbq = n;
        n.push = n; n.loaded = !0; n.version = '2.0'; n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        t.onload = function(){console.log("t.onload Concluído")};
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s)
      })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', FacebookService.pixelId);
      resolve();
      console.log("loadPixel() Concluído");
    });
  }

  public logEvent(event:AnalyticsEvents, params?: {[key: string]: any}, extra?:any) : void {
    if(!environment.production) return;
    // Loga no pixel custom
    fbq('trackCustom', event, params);
    // Loga no app (por enquanto) e em alguns eventos padrão do pixel tmb
    switch(event){
      case 'Subscribe':{
        fbq('track', 'Subscribe', {currency: 'BRL', value: (extra as IPlan)?.value});
        FB.AppEvents.logPurchase(extra ? (extra as IPlan).value : 0.0, "BRL");        
        break;
      }
      case 'Registration complete':{
        fbq('track', 'CompleteRegistration');
        if(params) params[FB.AppEvents.ParameterNames.REGISTRATION_METHOD] = 'standard';
        FB.AppEvents.logEvent(FB.AppEvents.EventNames.COMPLETED_REGISTRATION, null, params);        
        break;
      }
      case 'Enter payment information':{
        fbq('track', 'AddPaymentInfo');
        if(params) params[FB.AppEvents.ParameterNames.SUCCESS] = 1;
        FB.AppEvents.logEvent(FB.AppEvents.EventNames.ADDED_PAYMENT_INFO, null, params);        
        break;  
      }
      case 'Join live class':{
        fbq('track', 'ViewContent', {content_name: "Live"});
        break;
      }
      case 'Watch offline class':{
        fbq('track', 'ViewContent', {content_name: "Recorded", content_ids:[params?.class_uid]});
        break;
      }      
    }
  }
}
