import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { SubscriptionPlan } from 'src/app/models/subscription.model';
import { ERole, User } from 'src/app/models/user.model';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { TermsOfServiceService } from 'src/app/services/terms-of-service/terms-of-service.service';
import { sidenavAnimations } from './app-content.animations';
import * as Hammer from 'hammerjs';
import { MatSidenav } from '@angular/material/sidenav';
import { Platform } from '@angular/cdk/platform';

@Component({
  selector: 'app-content',
  templateUrl: './app-content.component.html',
  styleUrls: ['./app-content.component.scss'],
  animations: sidenavAnimations
})
export class AppContentComponent implements OnInit, OnDestroy {
  @ViewChild(MatSidenav)  public sidenav: MatSidenav;
  public mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  public Role = ERole; // Necessário para o *ng-if do HTML ter acesso ao enum
  public loggedUserLocalRef:User;  
  // Observa o plano de assinatura ativo do cliente
  public activeSubsPlan:Observable<SubscriptionPlan> = this._authService.loggedUserSubsPlan;
  
  public sidenavState:string = "expanded";

  private dialogRef:MatDialogRef<any, any>;

  constructor(
    private _authService:AuthenticationService,
    private _dialog:DialogService,
    private _termsOfService:TermsOfServiceService,
    private _changeDetectorRef: ChangeDetectorRef, 
    private _media: MediaMatcher,
    private _elementRef: ElementRef,
    private _platform: Platform
  ) { 
    this.mobileQuery = this._media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => this._changeDetectorRef.detectChanges();
    this.mobileQuery.addEventListener<"change">("change", this._mobileQueryListener);    

    // Configa o suporte para swipe abrir o drawer (apenas mobile)
    if(this._platform.ANDROID || this._platform.IOS){
      var hammertime = new Hammer(this._elementRef.nativeElement, {});
      hammertime.on("panright", ()=>{      
        this.sidenav.open();
      });
      hammertime.on("panleft", ()=>{
        this.sidenav.close();
      });    
    }
  }

  /**
   * OBS: As validações feitas aqui podem melhorar. Tanto os termos de uso quando a liberação do acesso
   * devem ser feitas de maneira dinâmica, observando as mudanças
   */
  public async ngOnInit(): Promise<void> {   
    var isSecure = await this._authService.secureApplication();
    if(isSecure){
      await this._termsOfService.validate();          
    }

    this._authService.loggedUser.subscribe((user:User)=>{
      if(user == null) return;
      this.loggedUserLocalRef = user;

      // Busca a referência do diálogo de aguardando liberação
      for(let dialogRef of this._dialog.getOpenDialogs()){
        if(dialogRef.componentInstance.data){
          if(dialogRef.componentInstance.data.title === "Aguardando liberação"){
            this.dialogRef = dialogRef;
          }  
        }
      }      
      if(!this.loggedUserLocalRef.alowed){
        if(this.dialogRef === undefined){
          this._dialog.showWaitingForLiberationDialog().subscribe(()=>{
            this._authService.logout();
          });
        }
      }else{
        if(this.dialogRef !== undefined) this.dialogRef.close();
      }

    });    
  }

  /**
   * 
   */
  public ngOnDestroy(): void {
    this.mobileQuery.removeEventListener<"change">("change",this._mobileQueryListener);
  }  

  /**
   * 
   */
  public toggleSidenavWidthMode() : void {
    this.sidenavState = (this.sidenavState == 'contracted') ? 'expanded' : 'contracted';
  }

}
