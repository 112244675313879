import { OnlineDataControllerService } from '../services/online-data-controller/online-data-controller.service';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { Utils } from '../utils/utils.functions';
import { IError, Errors } from "../services/error-handling/error-definitions";
import { AuthenticationService } from '../services/authentication/authentication.service';

export class CustomValidators {

    /**
     * Retorna um async validator para o campo de CPF que confere:
     * 1) se o CPF é válido
     * @param _dc 
     */
    static ValidCPF() {
      return (cpfControl:AbstractControl) : Promise<ValidationErrors | null> => {
        if(!Utils.isValidCPF(cpfControl.value)){
          return Promise.resolve({invalidCPF: true});
        }else{
          return Promise.resolve(null);
        };        
      };
    }


    /**
     * Retorna um async validator para o campo de CPF que confere:
     * 1) se ele já existe no banco de dados
     * @param _dc 
     */
    static AvaliableCPF(_dc: OnlineDataControllerService) {
      return (cpfControl:AbstractControl) : Promise<ValidationErrors | null> => {
        return new Promise<ValidationErrors>((resolve,reject)=>{
          // Verifica se não existe no banco
          _dc.getUserByCPF(cpfControl.value)
          .then((user)=>{
            // Se o usuário já existe então não pode ser usado
            resolve({unavaliableCPF: true});
          })
          .catch((error:IError)=>{
            if(error == Errors.UserNotFound){
              // Então pode utilizar o CPF
              resolve(null);
            }else{
              // Falha inexperada
              reject(error);
            }
          })
        });        
      };
    }  
    
    
    /**
     * Retorna um async validator para o campo de CPF que confere:
     * 1) Se ele já existe no banco de dados, com exceção do usuário logado
     * @param _dc 
     * @param _auth 
     */
    static AvaliableCPFExceptFromLoggedUser(_dc: OnlineDataControllerService, _auth: AuthenticationService){
      return (cpfControl:AbstractControl) : Promise<ValidationErrors | null> => {        
        return new Promise<ValidationErrors>((resolve,reject)=>{
          // Verifica se não existe no banco, com exceção de ser o prprio usuário logado
          _dc.getUserByCPF(cpfControl.value)
          .then((user)=>{
            // Se o usuário já existe então não pode ser usado, exceto se for o 
            // próprio usuário logado
            if(_auth.getLoggedUser().uid === user.uid){
              resolve(null);
            } else {
              resolve({unavaliableCPF: true});
            }
          })
          .catch((error:IError)=>{
            if(error == Errors.UserNotFound){
              // Então pode utilizar o CPF
              resolve(null);
            }else{
              // Falha inexperada
              reject(error);
            }
          })
        });        
      };
    }


    /**
     * Retorna um async validator para o campo de CNPJ que confere:
     * 1) se o CNPJ é válido
     * @param _dc 
     */
    static ValidCNPJ() {
      return (cnpjControl:AbstractControl) : Promise<ValidationErrors | null> => {
        if(!Utils.isValidCNPJ(cnpjControl.value)){
          return Promise.resolve({invalidCNPJ: true});
        }else{
          return Promise.resolve(null);
        };        
      };
    }

  }