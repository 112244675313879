import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GetIpService {
  private static readonly _API:string = "https://api.ipify.org";

  constructor(
    private _http:HttpClient
  ) { }

  public async fetch() : Promise<string> {
    return this._http.get(GetIpService._API,{responseType: 'text'}).toPromise();
  }
}
