import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CreditCardFormatDirective, CreditCardValidators } from 'angular-cc-library';
import { ICreditCard } from 'src/app/models/credit-card.model';
import { GerenciaNetService } from 'src/app/services/gerencia-net/gerencia-net.service';

@Component({
  selector: 'app-credit-card',
  templateUrl: './credit-card.component.html',
  styleUrls: ['./credit-card.component.scss']
})
export class CreditCardComponent implements OnInit {
  @Output("onValidCard") onValidCard = new EventEmitter<ICreditCard>();  
  @ViewChild("ccNumber",{static:true}) ccNumber:CreditCardFormatDirective;
  public form: FormGroup;

  constructor(
    private _fb: FormBuilder,
    private _gn:GerenciaNetService
  ) { }

  public ngOnInit() : void {
    this.form = this._fb.group({
      creditCard: ['', [Validators.required, CreditCardValidators.validateCCNumber]],
      expDate:    ['', [Validators.required, CreditCardValidators.validateExpDate, Validators.minLength(9), Validators.maxLength(9)]],
      cvc:        ['', [Validators.required, Validators.minLength(3), Validators.maxLength(4)]],
    });

    this.ccNumber.resolvedScheme$.subscribe((value)=>{
      
    });

    this.form.statusChanges.subscribe((status)=>{
      if(status === 'VALID'){
        const creditCard:ICreditCard = {
          brand: this.ccNumber.resolvedScheme$.getValue(),
          number: this.form.value.creditCard.replace(/\s/g, ""),
          cvv: this.form.value.cvc,
          expiration_month: this.form.value.expDate.substr(0,2),
          expiration_year: this.form.value.expDate.substr(5,8)
        };
        this.onValidCard.emit(creditCard);
      }
    });
  }

  public goToNextField(controlName: string, nextField: HTMLInputElement) {
    if (this.form.get(controlName).valid) {
      nextField.focus();
    }
  }


  public teste() : void {
    // 'dinersclub' tem que converter para 'diners'
    // 'discover' tem que converter para 'hipercard'     
    this._gn.getPaymentToken({
      brand: 'visa', // bandeira do cartão
      number: '4012001038443335', // número do cartão
      cvv: '123', // código de segurança
      expiration_month: '05', // mês de vencimento
      expiration_year: '2021' // ano de vencimento
    })
    .then((token)=>{
      console.log(token);
    })
    .catch((error)=>{
      console.error(error);
    })
  }

  

}
