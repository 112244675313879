import {Component, OnInit} from '@angular/core';
import { version } from 'src/app/app.version';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './app-footer.component.html',
  styleUrls: ['./app-footer.component.scss']
})
export class AppFooterComponent {
  public environment = environment;
  public version = version;

  public constructor() { }
}
