
export interface IError {
  code: string;
  message: string;
  innerException?: Error;
}

export function ErrorBuilder(base: IError, i: Error): IError {
  return {
    ...base,
    ...{
      innerException: i
    }
  };
}

export const Errors = {
  // Falha inesperada
  UnexpectedFailure: {
    code: "000",
    message: "Ocorreu uma falha inesperada"
  },

  // Data Controller
  UserNotFound: {
    code: "DC001",
    message: "Usuário não encontrado"
  },
  UserWithoutUid: {
    code: "DC002",
    message: "Falha na estrutura do usuário"
  },
  FailedToSaveData: {
    code: "DC003",
    message: "Falha na escrita do banco de dados"
  },
  MultipleUsersForSameCPF: {
    code: "DC004",
    message: "Mais de um usuário encontrado para o CPF"
  },
  ObjectNotFound: {
    code: "DC005",
    message: "Objeto não encontrado no banco de dados"
  },

  // Aula ao vivo
  InvalidStreamUrl: {
    code: "ST000",
    message: "Url de stream inválida"
  },

  // Authentication
  LoginFailed: {
    code: "AT001",
    message: "Falha no login"
  },

  // Functions
  IncompleteClientData: {
    code: "FN001",
    message: "Dados do cliente incompletos"
  },
  IncompleteAddressData: {
    code: "FN002",
    message: "Dados do endereço incompletos"
  },
  IncompleteGatewayData: {
    code: "FN003",
    message: "Dados do gateway incompletos"
  }
};
