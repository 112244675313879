import { Injectable } from '@angular/core';
import { ILocalDataControllerService } from '../local-data-controller.interface';
import { ClassMap } from 'src/app/models/class-map.model';
import { resolve, reject } from 'q';

@Injectable({
  providedIn: 'root'
})
export class IndexedDBDcServiceService implements ILocalDataControllerService {
  static readonly DB_NAME = 'OFFLINE_CLASSES';
  static readonly STORE_NAME = 'classes';
  static readonly KEYPATH = 'id';
  private db:IDBDatabase;
  private initialized:Boolean;

  constructor() {
    this.initialized = false;
  }

  /***
   * Inicializa o Indexed DB, criando a tabela de aulas se necessário
   */
  private async init() : Promise<void> {
    return new Promise((resolve, reject) => {
      if(this.initialized){
        resolve();
      }
      var request = self.indexedDB.open(IndexedDBDcServiceService.DB_NAME, 1);
      request.onsuccess = (event) => {
          var req = event.target as IDBOpenDBRequest;              
          if(req.result === undefined){
            this.initialized = false;             
            reject(req.error);
          }else{
            this.db = req.result;
            this.initialized = true;
            resolve();
          }
      };
      request.onupgradeneeded = (event) => {
        var req = event.target as IDBOpenDBRequest;
        this.db = req.result;
        var store = this.db.createObjectStore(IndexedDBDcServiceService.STORE_NAME, {keyPath: IndexedDBDcServiceService.KEYPATH});        
      };
      request.onerror = (event) => {
        var req = event.target as IDBOpenDBRequest;
        reject(req.error);
      };     
    });
  }

  /**
   * Obtem a referência para o Object Store das aulas
   * @returns Referencia para IDBObjectStore
   */
  private async getClassesStore() : Promise<IDBObjectStore> {
    return new Promise((resolve, reject) => {
      var transaction = this.db.transaction(IndexedDBDcServiceService.STORE_NAME, 'readwrite');
      var classesStore = transaction.objectStore(IndexedDBDcServiceService.STORE_NAME);      
      resolve(classesStore);
    });
  }

  /**
   * Salva/Atualiza uma aula no Indexed DB
   * @param c Aula a ser armazenada ou atualizada (se houver um id correspondente)
   */
  public async saveClassMap(c:ClassMap): Promise<void> {
    return this.init().
      then(() => this.getClassesStore()).
      then((store) => {
        var dbOp = store.put(c);
        dbOp.onsuccess = resolve;
        dbOp.onerror = (event) => {
          var req = event.target as IDBOpenDBRequest;
          reject(req.error)};
      });
  }
  

  /**
   * Exclui uma aula do indexed db dado seu id
   * @param id Id da aula a ser excluida
   * @returns Aula carregada
   */
  public async deleteClassMap(id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.init().
      then(() => this.getClassesStore()).
      then((store) => {
        var dbOp = store.delete(id);
        dbOp.onsuccess = (event) => {
          resolve();
        };
        dbOp.onerror = (event) => {
          var req = event.target as IDBOpenDBRequest;
          reject(req.error)};
      }); 
    });     
  }


  /**
   * Carrega uma aula do indexed db dado seu id
   * @param id Id da aula a ser carregada
   * @returns Aula carregada
   */
  public async loadClassMap(id: string): Promise<ClassMap> {
    return new Promise((resolve, reject) => {
      this.init().
      then(() => this.getClassesStore()).
      then((store) => {
        var dbOp = store.get(id);
        dbOp.onsuccess = (event) => {
          var req = event.target as IDBOpenDBRequest;
          if(dbOp.result == undefined){
            reject(req.error);
          }
          var loadedClass = dbOp.result as ClassMap;  
          resolve(loadedClass);
        };
        dbOp.onerror = (event) => {
          var req = event.target as IDBOpenDBRequest;
          reject(req.error)};
      }); 
    });     
  }

  /**
   * Obtem uma lista de todas as aulas armazenadas no indexed db
   * @returns Array de aulas
   */
  public listAllClassMaps(): Promise<Array<ClassMap>> {
    return new Promise((resolve, reject) => {
      this.init().
      then(() => this.getClassesStore()).
      then((store) => {
        var dbOp = store.getAll();
        dbOp.onsuccess = (event) => {
          var req = event.target as IDBOpenDBRequest;
          if(dbOp.result == undefined){
            reject(req.error);
          }
          var loadedClasses = dbOp.result as Array<ClassMap>;  
          resolve(loadedClasses);
        };
        dbOp.onerror = (event) => {
          var req = event.target as IDBOpenDBRequest;
          reject(req.error)};
      }); 
    });   
  }

}
