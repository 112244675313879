import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { OnlineDataControllerService } from 'src/app/services/online-data-controller/online-data-controller.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { User } from 'src/app/models/user.model';
import { ErrorHandlingService } from 'src/app/services/error-handling/error-handling.service';
import { IError, Errors } from "src/app/services/error-handling/error-definitions";
import { SnackBarService } from 'src/app/services/snack-bar/snack-bar.service';
import { AddressSearchService } from 'src/app/services/address-search/address-search.service';
import { Utils } from 'src/app/utils/utils.functions';
import { CustomValidators } from 'src/app/validators/custom.validators';
import { phoneCustomPatterns } from 'src/app/utils/utils.consts';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit, AfterViewInit {

  private updatedUser:User;

  public personalInfoFormGroup: FormGroup;
  public contactInfoFormGroup: FormGroup;
 
  public readonly states = [{
    ID: "1",
    sigla: "AC",
    nome: "Acre"
  },
       {
    ID: "2",
    sigla: "AL",
    nome: "Alagoas"
  },
       {
    ID: "3",
    sigla: "AM",
    nome: "Amazonas"
  },
       {
    ID: "4",
    sigla: "AP",
    nome: "Amapá"
  },
       {
    ID: "5",
    sigla: "BA",
    nome: "Bahia"
  },
       {
    ID: "6",
    sigla: "CE",
    nome: "Ceará"
  },
       {
    ID: "7",
    sigla: "DF",
    nome: "Distrito Federal"
  },
       {
    ID: "8",
    sigla: "ES",
    nome: "Espírito Santo"
  },
       {
    ID: "9",
    sigla: "GO",
    nome: "Goiás"
  },
       {
    ID: "10",
    sigla: "MA",
    nome: "Maranhão"
  },
       {
    ID: "11",
    sigla: "MG",
    nome: "Minas Gerais"
  },
       {
    ID: "12",
    sigla: "MS",
    nome: "Mato Grosso do Sul"
  },
       {
    ID: "13",
    sigla: "MT",
    nome: "Mato Grosso"
  },
       {
    ID: "14",
    sigla: "PA",
    nome: "Pará"
  },
       {
    ID: "15",
    sigla: "PB",
    nome: "Paraíba"
  },
       {
    ID: "16",
    sigla: "PE",
    nome: "Pernambuco"
  },
       {
    ID: "17",
    sigla: "PI",
    nome: "Piauí"
  },
       {
    ID: "18",
    sigla: "PR",
    nome: "Paraná"
  },
       {
    ID: "19",
    sigla: "RJ",
    nome: "Rio de Janeiro"
  },
       {
    ID: "20",
    sigla: "RN",
    nome: "Rio Grande do Norte"
  },
       {
    ID: "21",
    sigla: "RO",
    nome: "Rondônia"
  },
       {
    ID: "22",
    sigla: "RR",
    nome: "Roraima"
  },
       {
    ID: "23",
    sigla: "RS",
    nome: "Rio Grande do Sul"
  },
       {
    ID: "24",
    sigla: "SC",
    nome: "Santa Catarina"
  },
       {
    ID: "25",
    sigla: "SE",
    nome: "Sergipe"
  },
       {
    ID: "26",
    sigla: "SP",
    nome: "São Paulo"
  },
       {
    ID: "27",
    sigla: "TO",
    nome: "Tocantins"
  }]; 

  public phoneCustomPatterns = phoneCustomPatterns;

  public isExecAsyncRequest:boolean;

  constructor(
    private _formBuilder: FormBuilder,
    private _authService: AuthenticationService,
    private _dc: OnlineDataControllerService,
    private _errorHnd:ErrorHandlingService,
    private _addressService:AddressSearchService,
    private _snackBar:SnackBarService,
  ) { 
    this.updatedUser = new User('');
  }

  /**
   * 
   */
  public ngOnInit() : void {        
    this.isExecAsyncRequest = false;

    this.personalInfoFormGroup = this._formBuilder.group({
      personalInfoNameControl:              ['', Validators.required],
      personalInfoCPFControl:               ['', Validators.required, [
                                              CustomValidators.ValidCPF(), 
                                              CustomValidators.AvaliableCPFExceptFromLoggedUser(this._dc, this._authService)
                                            ]],
      personalInfoBirthdayControl:          ['', Validators.required],      
    });

    this.contactInfoFormGroup = this._formBuilder.group({
      contactInfoEmailControl:              ['', [Validators.required, Validators.email]],
      contactInfoMobilePhoneControl:        [''],
      contactInfoResidentialPhoneControl:   [''],
      contactInfoAddressCEPControl:         [''],
      contactInfoAddressStreetControl:      [''],
      contactInfoNeighborhoodStreetControl: [''],
      contactInfoAddressNumberControl:      [''],
      contactInfoAddressComplementControl:  [''],
      contactInfoAddressCityControl:        [''],
      contactInfoAddressStateControl:       [''],
    });
  }

  /**
   * 
   */
  public ngAfterViewInit(): void {
    // Subscreve nas informações do usuário logado para os valores
    // dos formulários serem modificados automaticamente
    this._authService.loggedUser.subscribe((user:User)=>{
      if(user){
        // Atualiza a referencia local
        this.updatedUser = user;
      }else{
        // Esse caso acontece quando se faz logout em outra aba por exemplo.
        this.updatedUser = new User('');
      }
      
      // Atualiza os forms
      setTimeout(()=>{       
        // É necessário utilizar o setTimeout para evitar o 'ExpressionChangedAfterItHasBeenCheckedError'
        // que ocorre quando o valor do dom na tela é alterado duas vezes no mesmo frame update.
        // O settimeout sem parâmetro faz a atualização dos valores ocorrer no frame seguinte. 
        // Infelizmente não consegui outra correção        
        // Informações pessoais
        this.personalInfoFormGroup.get('personalInfoNameControl').setValue(this.updatedUser.personalInformation.name);
        this.personalInfoFormGroup.get('personalInfoCPFControl').setValue(this.updatedUser.personalInformation.cpf);
        this.personalInfoFormGroup.get('personalInfoBirthdayControl').setValue(this.updatedUser.personalInformation.birthday);       
        
        this.contactInfoFormGroup.get('contactInfoEmailControl').setValue(this.updatedUser.contactInformation.email);
        this.contactInfoFormGroup.get('contactInfoMobilePhoneControl').setValue(this.updatedUser.contactInformation.mobilePhone.number);
        this.contactInfoFormGroup.get('contactInfoResidentialPhoneControl').setValue(this.updatedUser.contactInformation.residentialPhone.number);
        this.contactInfoFormGroup.get('contactInfoAddressCEPControl').setValue(this.updatedUser.contactInformation.address.cep);
        this.contactInfoFormGroup.get('contactInfoAddressStreetControl').setValue(this.updatedUser.contactInformation.address.street);
        this.contactInfoFormGroup.get('contactInfoNeighborhoodStreetControl').setValue(this.updatedUser.contactInformation.address.neighborhood);
        this.contactInfoFormGroup.get('contactInfoAddressNumberControl').setValue(this.updatedUser.contactInformation.address.number);        
        this.contactInfoFormGroup.get('contactInfoAddressComplementControl').setValue(this.updatedUser.contactInformation.address.complement);
        this.contactInfoFormGroup.get('contactInfoAddressCityControl').setValue(this.updatedUser.contactInformation.address.city);
        this.contactInfoFormGroup.get('contactInfoAddressStateControl').setValue(this.updatedUser.contactInformation.address.state);
        this.contactInfoFormGroup.get('contactInfoEmailControl').disable();        
      });
    });
    
  }

  /**
   * 
   * @param cep 
   */
  public fillAddress(cep:string){
    if(cep.length != 9) return;

    this._addressService.get(cep).subscribe((address)=>{
      this.contactInfoFormGroup.get('contactInfoAddressStreetControl').setValue(address.logradouro);
      this.contactInfoFormGroup.get('contactInfoNeighborhoodStreetControl').setValue(address.bairro);
      this.contactInfoFormGroup.get('contactInfoAddressCityControl').setValue(address.localidade);
      this.contactInfoFormGroup.get('contactInfoAddressStateControl').setValue(address.uf);
    });
  }  

  /**
   * 
   */
  public update() : void {
    
    if(this.personalInfoFormGroup.valid && this.contactInfoFormGroup.valid){
      this.isExecAsyncRequest = true;

      // Informações pessoais
      this.updatedUser.personalInformation.name = this.personalInfoFormGroup.get('personalInfoNameControl').value;
      this.updatedUser.personalInformation.cpf = this.personalInfoFormGroup.get('personalInfoCPFControl').value;
      this.updatedUser.personalInformation.birthday = this.personalInfoFormGroup.get('personalInfoBirthdayControl').value;

      // Informações de contato
      this.updatedUser.contactInformation.email = this.contactInfoFormGroup.get('contactInfoEmailControl').value;
      this.updatedUser.contactInformation.mobilePhone.number = this.contactInfoFormGroup.get('contactInfoMobilePhoneControl').value;
      this.updatedUser.contactInformation.residentialPhone.number = this.contactInfoFormGroup.get('contactInfoResidentialPhoneControl').value;
      this.updatedUser.contactInformation.address.cep = this.contactInfoFormGroup.get('contactInfoAddressCEPControl').value;
      this.updatedUser.contactInformation.address.street = this.contactInfoFormGroup.get('contactInfoAddressStreetControl').value;
      this.updatedUser.contactInformation.address.neighborhood = this.contactInfoFormGroup.get('contactInfoNeighborhoodStreetControl').value;
      this.updatedUser.contactInformation.address.number = this.contactInfoFormGroup.get('contactInfoAddressNumberControl').value;
      this.updatedUser.contactInformation.address.complement = this.contactInfoFormGroup.get('contactInfoAddressComplementControl').value;
      this.updatedUser.contactInformation.address.city = this.contactInfoFormGroup.get('contactInfoAddressCityControl').value;
      this.updatedUser.contactInformation.address.state = this.contactInfoFormGroup.get('contactInfoAddressStateControl').value;
      
      // Atualiza
      this._dc.updateUser(this.updatedUser)
      .then(()=>{
        this._snackBar.show("Informações atualizadas!");
      })
      .catch((error:IError)=>{
        this._errorHnd.show(error);
      })
      .finally(()=>{
        this.isExecAsyncRequest = false;
      });
    }else{
      this._snackBar.show("Para atualizar as informações é necessário corrigir todos os erros")
    }
  }

}
