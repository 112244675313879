<div class="full-viewport-container" [loader]='isExecuting'>  
    <!--Componentes do formulário de cadastro-->
    <mat-vertical-stepper appMatVerticalStepperScroller linear="true" #stepper>
        <!--Step 0: Boas vindas-->
        <mat-step>
            <form>
                <ng-template matStepLabel>Bem vindo ao Cycle Connect</ng-template>
                <div class="flex-container-column">
                   <app-cycle-connect-presentation style="color: whitesmoke;"></app-cycle-connect-presentation>
                </div>    
            
                <!--Botoes-->
                <div class="flex-container-row padding-top">
                    <div>
                        <button 
                            mat-stroked-button 
                            color="accent" 
                            (click)="cancelRegistration()"
                            matStepperPrevious>Cancelar</button>
                    </div>                        
                    <div class="padding-left">
                        <button 
                            mat-flat-button 
                            color="primary" 
                            matStepperNext 
                            type="submit">Próximo</button>
                    </div>
                </div>
            </form>
        </mat-step>

        <!--Step 1: Papel-->
        <mat-step [stepControl]="roleFormGroup">
            <form [formGroup]="roleFormGroup" (ngSubmit)="OnSubmitRoleForm(roleFormGroup.value)">
                <ng-template matStepLabel>Tipo de Cadastro</ng-template>
                <div class="flex-container-column">
                    <!--Papel-->
                    <div>
                        <mat-form-field>    
                            <mat-label>Cadastro para...</mat-label>
                            <mat-select #roleSelect formControlName="roleControl" required>
                                <mat-option *ngFor="let roleInfo of rolesInfo" [value]="roleInfo" [disabled]=" roleInfo.name == 'Professor'">{{roleInfo.name}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="roleFormGroup.get('roleControl').hasError('required')">Escolha um papel</mat-error>                            
                            <mat-hint>{{roleSelect?.value?.hint}}</mat-hint>                         
                        </mat-form-field>
                    </div>
                    <!--Descrição do papel selecionado-->
                    <div class="limited-width">
                        <p>
                            {{roleSelect?.value?.description}}
                        </p>
                    </div>
                    <!--Botoes-->
                    <div class="flex-container-row padding-top">
                        <div>
                            <button 
                                mat-stroked-button 
                                color="accent" 
                                matStepperPrevious>Voltar</button>
                        </div>                        
                        <div class="padding-left">
                            <button 
                                mat-flat-button 
                                color="primary" 
                                matStepperNext 
                                type="submit">Próximo</button>
                        </div>
                    </div>
                </div>
            </form>
        </mat-step>

        <!--Step 2: Dados pessoais-->
        <mat-step [stepControl]="personalInfoFormGroup">
            <form [formGroup]="personalInfoFormGroup" (ngSubmit)="OnSubmitPersonalInfoForm(personalInfoFormGroup.value)">
                <ng-template matStepLabel>Informações pessoais</ng-template>
                <div class="flex-container-column">
                    <!--Nome-->
                    <div>                                                
                        <mat-form-field>
                            <input 
                                #nameInput
                                matInput 
                                required
                                placeholder="Nome completo" 
                                maxlength="100"                                
                                formControlName="personalInfoNameControl">
                            <mat-error *ngIf="personalInfoFormGroup.get('personalInfoNameControl').hasError('required')">Por favor nos diga seu nome</mat-error>
                            <mat-hint align="end">{{nameInput.value.length}} / 100</mat-hint>
                        </mat-form-field>
                    </div>
                    <!--CPF-->
                    <div>
                        <mat-form-field>
                            <input 
                                #cpfInput
                                matInput 
                                required
                                placeholder="000.000.000-00"
                                maxlength="14"
                                mask="000.000.000-00"                                 
                                formControlName="personalInfoCPFControl">
                            <mat-label>CPF</mat-label>
                            <mat-hint *ngIf="personalInfoFormGroup.get('personalInfoCPFControl').status === 'PENDING'">
                                Verificando CPF...
                            </mat-hint>
                            <mat-error *ngIf="personalInfoFormGroup.get('personalInfoCPFControl').hasError('required')">Por favor nos diga seu CPF</mat-error>
                            <mat-error *ngIf="personalInfoFormGroup.get('personalInfoCPFControl').errors?.invalidCPF">Por favor insra um CPF válido</mat-error>
                            <mat-error *ngIf="personalInfoFormGroup.get('personalInfoCPFControl').errors?.unavaliableCPF">O CPF inserido já é utilizado por outro usuário</mat-error>
                        </mat-form-field> 
                    </div>
                    <!--Aniversário-->
                    <div>
                        <mat-form-field>
                            <input 
                                matInput 
                                required
                                formControlName="personalInfoBirthdayControl"
                                [matDatepicker]="birthdayDatePicker"
                                placeholder="Escolha a data"
                                readonly
                                >
                            <mat-datepicker-toggle 
                                matSuffix [for]="birthdayDatePicker">
                            </mat-datepicker-toggle>
                            <mat-datepicker 
                                #birthdayDatePicker
                                touchUi
                                startView="multi-year"
                                [startAt]="startDate"
                                disabled="false">
                            </mat-datepicker>
                            <mat-label>Aniversário</mat-label>
                            <mat-error *ngIf="personalInfoFormGroup.get('personalInfoBirthdayControl').hasError('required')">Por favor nos diga seu aniversário</mat-error>                            
                        </mat-form-field>                       
                    </div>

                    <!--Botoes-->
                    <div class="flex-container-row padding-top">
                        <div>
                            <button 
                                mat-stroked-button 
                                color="accent" 
                                matStepperPrevious>Voltar</button>
                        </div>
                        <div class="padding-left">
                            <button 
                                mat-flat-button 
                                color="primary" 
                                type="submit"
                                matStepperNext>Próximo</button>
                        </div>
                    </div>
                </div>
            </form>
        </mat-step>

        <!--Step 3: Informações de contato-->
        <mat-step [stepControl]="contactInfoFormGroup">
            <form [formGroup]="contactInfoFormGroup" (ngSubmit)="OnSubmitContactInfoForm(contactInfoFormGroup.value)">
            <ng-template matStepLabel>Informações de contato</ng-template>
                <div class="flex-container-column">
                    <!--E-mail-->
                    <div>
                        <mat-form-field>
                            <input 
                                #emailInput
                                matInput 
                                required                                
                                type="email"
                                placeholder="E-mail" 
                                maxlength="100"
                                formControlName="contactInfoEmailControl">
                                <mat-error *ngIf="contactInfoFormGroup.get('contactInfoEmailControl').hasError('email') && !contactInfoFormGroup.get('contactInfoEmailControl').hasError('required')">É necessário um e-mail válido</mat-error>
                            <mat-error *ngIf="contactInfoFormGroup.get('contactInfoEmailControl').hasError('required')">Por favor nos diga seu E-mail</mat-error>
                            <mat-hint align="end">{{emailInput.value.length}} / 100</mat-hint>
                        </mat-form-field>  
                    </div>

                    <!--Instagram-->
                    <div>
                        <mat-form-field>
                            <input 
                                #instagramInput
                                matInput                               
                                placeholder="instagram" 
                                maxlength="100"
                                formControlName="contactInfoInstagramControl">                            
                            <mat-hint align="end">{{instagramInput.value.length}} / 50</mat-hint>
                            <span matPrefix style="color: whitesmoke;">@&nbsp;</span>
                        </mat-form-field>  
                    </div>                    

                    <!--Celular-->
                    <div>
                        <mat-form-field>
                            <input 
                                matInput                                 
                                type="text"
                                placeholder="(00) 0 0000-0000" 
                                mask="(90) 9 9000-0000"
                                [patterns]="phoneCustomPatterns"
                                formControlName="contactInfoMobilePhoneControl">
                            <mat-label>Telefone Celular</mat-label>
                            <mat-icon matSuffix color="primary">smartphone</mat-icon>
                        </mat-form-field> 
                    </div>

                    <!--Residencial-->
                    <div>
                        <mat-form-field>
                            <input 
                                matInput                                 
                                type="text"
                                placeholder="(00) 0 0000-0000" 
                                mask="(90) 9000-0000||(90) 9 9000-0000"
                                [patterns]="phoneCustomPatterns"
                                formControlName="contactInfoResidentialPhoneControl">
                            <mat-label>Telefone Residencial</mat-label>
                            <mat-icon matSuffix color="primary">phone</mat-icon>
                        </mat-form-field> 
                    </div>

                    <mat-divider></mat-divider>

                    <!--Endereço completo-->
                    <!--CEP-->
                    <div>
                        <mat-form-field>                            
                            <input 
                                #cepInput
                                matInput                                 
                                type="text"
                                placeholder="00000-000" 
                                mask="00000-000"
                                formControlName="contactInfoAddressCEPControl"
                                (input)="fillAddress(cepInput.value)">
                            <mat-label>CEP</mat-label>                            
                        </mat-form-field> 
                    </div>

                    <div *ngIf="hasValidCep">
                        <!--Rua-->                    
                        <div>
                            <mat-form-field>
                                <input 
                                    #streetInput
                                    matInput                                 
                                    type="text"
                                    placeholder="Rua" 
                                    maxlength="100"
                                    formControlName="contactInfoAddressStreetControl">
                                <mat-label>Logradouro</mat-label>
                                <mat-hint align="end">{{streetInput.value.length}} / 100</mat-hint>
                            </mat-form-field> 
                        </div>   

                        <!--Bairro-->
                        <div>
                            <mat-form-field>
                                <input 
                                    #neighborhoodInput
                                    matInput                                 
                                    type="text"
                                    placeholder="Bairro" 
                                    maxlength="50"
                                    formControlName="contactInfoNeighborhoodStreetControl">
                                <mat-label>Bairro</mat-label>
                                <mat-hint align="end">{{neighborhoodInput.value.length}} / 50</mat-hint>
                            </mat-form-field> 
                        </div> 
                        
                        <!--Numero-->
                        <div>
                            <mat-form-field>
                                <input 
                                    matInput                                 
                                    type="number"
                                    placeholder="Número" 
                                    maxlength="5"
                                    formControlName="contactInfoAddressNumberControl">
                                <mat-label>Número</mat-label>
                            </mat-form-field> 
                        </div>                     

                        <!--Complemento-->
                        <div>
                            <mat-form-field>
                                <input 
                                    #complementInput
                                    matInput                                 
                                    type="text"
                                    placeholder="Complemento" 
                                    maxlength="100"
                                    formControlName="contactInfoAddressComplementControl">
                                <mat-label>Complemento</mat-label>
                                <mat-hint align="end">{{complementInput.value.length}} / 100</mat-hint>
                            </mat-form-field> 
                        </div>  
                        
                        <!--Estado-->
                        <div>
                            <mat-form-field>                                
                                <mat-select formControlName="contactInfoAddressStateControl">
                                    <mat-option *ngFor="let state of states" [value]="state.sigla">{{state.nome}}</mat-option>
                                </mat-select>
                                <mat-label>UF</mat-label>
                            </mat-form-field>
                        </div>

                        <!--Cidade-->
                        <div>
                            <mat-form-field>
                                <input 
                                    #cityInput
                                    matInput                                 
                                    type="text"
                                    placeholder="Cidade" 
                                    maxlength="50"
                                    formControlName="contactInfoAddressCityControl">
                                <mat-label>Cidade</mat-label>
                                <mat-hint align="end">{{cityInput.value.length}} / 50</mat-hint>
                            </mat-form-field> 
                        </div>                     
                    </div>

                    <!--Botoes-->
                    <div class="flex-container-row padding-top">
                        <div>
                            <button 
                                mat-stroked-button 
                                color="accent" 
                                matStepperPrevious>Voltar</button>
                        </div>
                        <div class="padding-left">
                            <button 
                                mat-flat-button 
                                color="primary" 
                                type="submit"
                                matStepperNext>Próximo</button>
                        </div>
                    </div>                    
                </div>
            </form>                
        </mat-step>

        <!--Step 4: Termos de uso-->
        <mat-step [stepControl]="termsFormGroup">            
            <form [formGroup]="termsFormGroup">
                <ng-template matStepLabel>Termos de Uso v{{termsOfUseVersion}}.0</ng-template>
                <div class="flex-container-column">
                    <!--Termos-->
                    <app-terms-of-service style="color: whitesmoke;"></app-terms-of-service>
                    <!--Concordo--> 
                    <div>                        
                        <mat-checkbox formControlName="agreeControl" class="mat-input-element">Li e concordo com os termos</mat-checkbox>      
                        <mat-error *ngIf="termsFormGroup.get('agreeControl').invalid">É necessário concordar com os termos para prosseguir</mat-error>                  
                    </div>                                      
                </div>    
            
                <!--Botoes-->
                <div class="flex-container-row padding-top">
                    <div>
                        <button 
                            mat-stroked-button 
                            color="accent" 
                            matStepperPrevious>Voltar</button>
                    </div>
                    <div class="padding-left">
                        <button 
                            mat-flat-button 
                            color="primary" 
                            type="submit"
                            matStepperNext>Próximo</button>
                    </div>
                </div>
            </form>
        </mat-step>

        <!--Step 5: Política de Privacidade-->
        <mat-step>            
            <form>
                <ng-template matStepLabel>Política de Privacidade</ng-template>
                <div class="flex-container-column">
                    <!--Política de Privacidade-->
                    <app-privacy-policy style="color: whitesmoke;"></app-privacy-policy>
                </div>    
            
                <!--Botoes-->
                <div class="flex-container-row padding-top">
                    <div>
                        <button 
                            mat-stroked-button 
                            color="accent" 
                            matStepperPrevious>Voltar</button>
                    </div>
                    <div class="padding-left">
                        <button 
                            mat-flat-button 
                            color="primary" 
                            type="submit"
                            matStepperNext>Próximo</button>
                    </div>
                </div>
            </form>
        </mat-step>        

        <!--Step Final-->
        <mat-step>
            <ng-template matStepLabel>Pronto!</ng-template>
            <p>Obrigado(a) pelas suas informações cadastro. Seja bem vindo(a) ao futuro do ciclismo indoor!</p>
            <div class="flex-container-row padding-top">
                <!--Botoes-->
                <div>
                    <button mat-stroked-button color="accent" matStepperPrevious>Voltar</button>
                </div>
                <div class="padding-left">
                    <button mat-flat-button color="primary" (click)="register()">Salvar cadastro!</button>
                </div>
            </div>
        </mat-step>
    </mat-vertical-stepper>
    
</div>
