<div>
    <div mat-dialog-title>
      <mat-toolbar appSticky>
        <button 
            mat-icon-button
            matTooltip="Voltar"
            [mat-dialog-close]="false">
            <mat-icon>arrow_back_ios</mat-icon>
        </button>    
        <span>Pergunta</span>
        <span class="fill-remaining-space"></span>
      </mat-toolbar>
    </div>    
  
    <div mat-dialog-content>
                
        <div style="padding: 16px 0 16px 0;">
            Salvar esta aula no banco de dados? (será disponibilizada para os alunos) ?
        </div>

        <div style="padding: 16px 0 16px 0;"> 
            <mat-slide-toggle [(ngModel)]="data.stream.public">Tornar aula pública?</mat-slide-toggle>
        </div>

        <div style="padding: 16px 0 16px 0;" *ngIf="data.stream.source == 'instagram'">
            <!-- Link do stream -->
            <mat-form-field style="width: 100%;">
                <input          
                    #urlInput           
                    matInput
                    [(ngModel)]="data.stream.url">
                <mat-label>Link IGTV</mat-label>
                <mat-hint align='end'>Insira o link do compartilhamento direto do IGTV</mat-hint>
            </mat-form-field> 
        </div>    

    </div>
  
    <div mat-dialog-actions align="end">
        <button mat-button [mat-dialog-close]="false">Não</button>
        <button mat-stroked-button color="primary" [mat-dialog-close]="true">Sim</button>
    </div>
</div>