<div class="container-fluid">
    <h4>1. DEFINIÇÕES DE TERMOS</h4>
    <hr />
    <h5>SISTEMA</h5>
    <p>Conjunto de softwares desenvolvidos abrangendo o FRONTEND e o BACKEND e as infraestruturas necessárias para o funcionamento.</p>
    <h5>DESENVOLVEDORES</h5>
    <p>Profissionais responsáveis pelo desenvolvimento e manutenção do SISTEMA, aqui representados por Cycle Connect Brasil - CNPJ 36.901.307/0001-00.</p>
    <h5>PROFESSOR</h5>
    <p>Usuário do SISTEMA, cadastrado como PROFESSOR, ciente de que este ainda está em fase de desenvolvimento.</p>
    <h5>CLIENTE</h5>
    <p>Usuário do SISTEMA, cadastrado como ALUNO.</p>
    <h5>USUÁRIO</h5>
    <p>Usuário do sistema, cadastrado como PROFESSOR ou CLIENTE.</p>

    <h4>2. APRESENTAÇÃO</h4>
    <hr />
    <p>O presente documento estabelece um acordo entre os DESENVOLVEDORES e o USUÁRIO que rege o uso do SISTEMA.</p>

    <h4>3. ACEITAÇÃO</h4>
    <hr />
    <p>O USUÁRIO declara que leu, revisou e concordou com todos os termos deste documento.</p>

    <h4>4. ALTERAÇÕES E MODIFICAÇÕES</h4>
    <hr />
    <p>Os DESENVOLVEDORES se reservam no direito de modificar estes termos, seja incluindo, removendo ou alterando quaisquer de suas cláusulas a qualquer momento. As alterações têm efeito imediato no ato da publicação.</p>

    <h4>5. LICENÇA DE USO</h4>
    <hr />
    <h5>PROFESSORES</h5>
    <p>O USUÁRIO registrado como PROFESSOR, recebe uma licença de uso para o SISTEMA que o permite sua utilização para um único indivíduo. A licença é de caráter não transferível, não exclusiva e pode ser revogada a qualquer momento pelos DESENVOLVEDORES sem aviso prévio.</p>
    <p>A venda, distribuição, modificação ou engenharia reversa sobre o SISTEMA, bem como o compartilhamento de login de acesso, compartilhamento de material audiovisual são proibidas e acarretam na suspensão imediata da licença de uso bem como são  passíveis das punições penais cabíveis. </p>
    <hr />
    <h5>CLIENTES</h5>
    <p>O USUÁRIO registrado como ALUNO, recebe uma licença de uso para o SISTEMA que o permite sua utilização para um único indivíduo. A licença é de caráter não transferível, não exclusiva e pode ser revogada a qualquer momento pelos DESENVOLVEDORES sem aviso prévio.</p>
    <p>A licença de uso é válida mediante a adesão do CLIENTE a um dos planos de assinatura disponibilizados.</p>
    <p>O CLIENTE pode solicitar a qualquer momento o cancelamento de sua assinatura, acarretando na revogação da sua licença de uso.</p>
    <p>A venda, distribuição, modificação ou engenharia reversa sobre o SISTEMA, bem como o compartilhamento de login de acesso, compartilhamento de material audiovisual são proibidas e acarretam na suspensão imediata da licença de uso bem como são  passíveis das punições penais cabíveis.</p>

    <h4>6. ISENÇÃO DE GARANTIAS E LIMITAÇÃO DE RESPONSABILIDADES</h4>
    <hr />
    <p>O SISTEMA é distribuído atualmente na condição de “desenvolvimento contínuo” significando que o mesmo pode apresentar falhas inesperadas em sua execução. O USUÁRIO concorda em isentar os DESENVOLVEDORES de quaisquer perdas ou danos decorrentes de indisponibilidade, instabilidade, bugs ou mal funcionamento do sistema.</p>
    <p>Os DESENVOLVEDORES se reservam ao direito da suspensão da disponibilidade do SISTEMA para atualizações ou correções mediante aviso prévio de ao menos um dia útil.</p>

    <h4>7. COPYRIGHT E PROPRIEDADE INTELECTUAL</h4>
    <hr />
    <p>O SISTEMA é protegido por direitos autorais pertencentes aos DESENVOLVEDORES. Os direitos autorais incluem o código fonte, a documentação, a aparência, a estrutura e a organização, todos os nomes, todos os logotipos e imagens utilizadas.</p>
    <p>Todos os direitos sobre o APLICATIVO e sua documentação associada, em particular o exercício de todos os direitos de propriedade, são reservados exclusivamente aos DESENVOLVEDORES. O USUÁRIO receberá apenas os direitos da licença de uso regidos por este documento.</p>

    <h4>8. FORO DE ELEIÇÃO</h4>
    <hr />
    <p>As partes escolhem o foro de Belo Horizonte para dirimir quaisquer questões oriundas do presente termo de uso.</p>

  </div>