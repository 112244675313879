<div class="mat-elevation-z8" style="max-width: 285px;">
    <mat-card>
        <mat-card-header>
            <mat-card-title>                
                    <span>Cartão de crédito</span>
                    <!-- Cartões aceitos
                    <span fxFlex="1 1 auto"></span>
                    <div style="width: 24px; height: 16px;" class="visa-card"></div>
                    <div style="width: 24px; height: 16px; margin-left: 4px;" class="mastercard-card"></div>
                    <div style="width: 24px; height: 16px; margin-left: 4px;" class="amex-card"></div>
                    <div style="width: 24px; height: 16px; margin-left: 4px;" class="dinersclub-card"></div>                    
                    -->
            </mat-card-title>
        </mat-card-header>
        <mat-card-content>                      
            <form [formGroup]="form" novalidate>
                <!--Número do cartão-->
                <mat-form-field>
                    <input 
                        id="cc-number"
                        #ccNumber="ccNumber"
                        matInput 
                        required
                        placeholder="•••• •••• •••• ••••"
                        type="tel"
                        formControlName="creditCard"
                        autocomplete="cc-number"
                        ccNumber>
                    <mat-label>Número do Cartão</mat-label>
                    <div matSuffix style="width: 24px; height: 16px;" [class]="(ccNumber.resolvedScheme$ | async)+'-card'"></div>
                    <mat-error *ngIf="form.get('creditCard').hasError('required')">Não pode ser vazio</mat-error>
                    <mat-error *ngIf="!form.controls.creditCard.valid && form.controls.creditCard.dirty">Inválido ou não suportado</mat-error>
                </mat-form-field>
            
                <div fxLayout="row">
                    <!--Validade-->
                    <mat-form-field style="width: 75%;">
                        <input 
                            id="cc-exp"
                            matInput 
                            required
                            placeholder="•• / ••••"
                            type="tel"
                            formControlName="expDate"
                            autocomplete="cc-exp"
                            ccExp>
                        <mat-label>Validade</mat-label>
                        <mat-error *ngIf="form.get('expDate').hasError('required')">Não pode ser vazia</mat-error>
                        <mat-error *ngIf="!form.controls.expDate.valid && form.controls.expDate.dirty">Data inválida</mat-error>
                    </mat-form-field>
                
                    <!--CVC-->
                    <mat-form-field style="width: 20%;">
                    <input 
                        id="cc-cvc"
                        matInput 
                        required
                        placeholder="•••"
                        type="tel"
                        formControlName="cvc"
                        autocomplete="cc-cvc"
                        ccCVC>
                    <mat-label>CVC</mat-label>
                    <mat-error *ngIf="form.get('cvc').hasError('required')">Obrigatório</mat-error>
                    <mat-error *ngIf="!form.controls.cvc.valid && form.controls.cvc.dirty">Inválido</mat-error>
                    </mat-form-field>
                </div>   
                
                <div fxLayout="row">
                    <span fxFlex="1 1 auto" fxLayoutAlign="center center" class="mat-small">Bandeiras aceitas</span>
                    <div style="width: 24px; height: 16px; margin-left: 4px;" class="visa-card"></div>
                    <div style="width: 24px; height: 16px; margin-left: 4px;" class="mastercard-card"></div>
                    <div style="width: 24px; height: 16px; margin-left: 4px;" class="amex-card"></div>
                    <div style="width: 24px; height: 16px; margin-left: 4px;" class="dinersclub-card"></div>                                        
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</div> 
