import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

export interface ICreditCardGerenciaNet {
  brand: string;
  number: string;
  cvv: string;
  expiration_month: string;
  expiration_year: string;
}

@Injectable({
  providedIn: 'root'
})
export class GerenciaNetService {
  private static readonly _PAYEE_CODE = "dbd7abbce3ecd2ebfb9b7a162822b949";
  private static readonly _ENDPOINT = environment.production ? "https://api.gerencianet.com.br/v1/cdn" : "https://sandbox.gerencianet.com.br/v1/cdn";

  private api:any;
  
  constructor() { }

  public getPaymentToken(creditCard:ICreditCardGerenciaNet) : Promise<string> {
    if(this.api != undefined){
      return new Promise((resolve, reject)=>{
        this.api.getPaymentToken(creditCard, (error, response)=>{
          if(error) {
            reject({
              code: error.code,
              message: error.error_description
            });
          }else{
            resolve(response.data.payment_token);
          }
        });
      });
    }

    return new Promise((resolve, reject)=>{
      var s = document.createElement('script');
      s.type='text/javascript';
      
      var v = Math.random()*1000000;
      s.src=`${GerenciaNetService._ENDPOINT}/${GerenciaNetService._PAYEE_CODE}/${v}`;
      s.async=false;
      s.id = GerenciaNetService._PAYEE_CODE;

      if(!document.getElementById(GerenciaNetService._PAYEE_CODE)){
        document.getElementsByTagName('head')[0].appendChild(s);
      }       

      window['$gn'] = {
        validForm:true,
        processed:false,
        done:{},
        ready:function(fn){
          window['$gn'].done = fn;
        }
      };
  
      window['$gn'].ready((checkout)=>{
        this.api = checkout;
        this.api.getPaymentToken(creditCard, (error, response)=>{
          if(error) {
            reject({
              code: error.code,
              message: error.error_description
            });
          }else{
            resolve(response.data.payment_token);
          }
        });
      });      
    });
  }

}
