import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor() {
    if(NotificationService.isSupported()){
      Notification.requestPermission();
    }    
  }

  public static isSupported() : boolean {
    return 'Notification' in window && 'serviceWorker' in navigator && 'PushManager' in window
  }

  /**
   * 
   */
  public notifySwUpdate() : void {
    if(!NotificationService.isSupported()) return;
    // TODO: Colocar ações para atualizar/dispensar
    if(Notification.permission == 'granted'){
      navigator.serviceWorker.getRegistration().then(function(reg) {
        var options = {
          body: 'Recarregue para aplicar',
          icon: 'assets/icons/icon-256x256.png',
          vibrate: [100, 50, 100]
        };
        reg.showNotification('Atualização disponível!', options);
      });        
    }
  }
}
