import { Injectable } from '@angular/core';
import { FirebaseService } from '../firebase/firebase.service';

@Injectable({
  providedIn: 'root'
})
export class RemoteConfigService {

  constructor(
    private _firebaseService:FirebaseService
  ) {

  }

  public fetchAndActivate() : Promise<boolean> {
    return this._firebaseService.getRemoteConfig().fetchAndActivate();
  }

  public getTermsOfUseVersion(): number {    
    return this._firebaseService.getRemoteConfig().getNumber('terms_of_service_version');
  }
}
