import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ClassMap } from 'src/app/models/class-map.model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-class-map-card',
  templateUrl: './class-map-card.component.html',
  styleUrls: ['./class-map-card.component.scss']
})
export class ClassMapCardComponent {

  @Input() class: ClassMap;
  @Output() deleteRequest = new EventEmitter<String>();

  constructor(private _route: ActivatedRoute) { }

  /**
   * Emite o evento 'deleteRequest' propagando a solicitação para o componente pai
   */
  public delete() : void {
    this.deleteRequest.emit(this.getClassKeyForSafeDisplay(this.class,'id'));    
  }

  /**
   * Obtem um valor do objeto Class dado sua key de modo que seja null ou
   * undefined, retorna uma string legível para exibição
   */
  public getClassKeyForSafeDisplay(c: ClassMap, key: string): any {
    if (c[key] === undefined || c[key] == null || c[key]=='') {
      return '--';
      // return null;
    } else {
      return c[key];
    }
  }

  

}
