import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Client } from 'src/app/models/client.model';
import { ICreditCard } from 'src/app/models/credit-card.model';
import { AddressSearchService } from 'src/app/services/address-search/address-search.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { phoneCustomPatterns, states } from 'src/app/utils/utils.consts';
import { CustomValidators } from 'src/app/validators/custom.validators';

@Component({
  selector: 'app-subscription-dialog',
  templateUrl: './subscription-dialog.component.html',
  styleUrls: ['./subscription-dialog.component.scss']
})
export class SubscriptionDialogComponent implements OnInit {
  public payer:Client = new Client('');
  public creditCard:ICreditCard;
  
  public phoneCustomPatterns = phoneCustomPatterns;

  // Propriedades do Step 2
  public personalInfoFormGroup: FormGroup;
  public startDate = new Date(1980,0,1);

  // Propriedades do Step 3
  public contactInfoFormGroup: FormGroup;
  public states = states;
  public hasValidCep:Boolean = false;  

  constructor(
    @Inject(MAT_DIALOG_DATA) public data:any,
    private _formBuilder: FormBuilder,
    private _addressService:AddressSearchService,
    private _authService:AuthenticationService
  ) { }

  /**
   * 
   */
  public ngOnInit() : void {
    // Possível erro aqui. A referência fica duplicada. É necessário talvez obter uma cópia.
    this.payer = this._authService.getLoggedUser();

    // Referente ao Step 2
    this.personalInfoFormGroup = this._formBuilder.group({
      personalInfoNameControl:              ['', Validators.required],
      personalInfoCPFControl:               ['', Validators.required, [
                                                CustomValidators.ValidCPF()
                                            ]],
      personalInfoBirthdayControl:          ['', Validators.required],
    });    
    this.personalInfoFormGroup.get('personalInfoNameControl').setValue(this.payer.personalInformation.name);
    this.personalInfoFormGroup.get('personalInfoCPFControl').setValue(this.payer.personalInformation.cpf);
    this.personalInfoFormGroup.get('personalInfoBirthdayControl').setValue(this.payer.personalInformation.birthday);


    // Referente ao Step 3
    this.contactInfoFormGroup = this._formBuilder.group({
      contactInfoEmailControl:              ['', [Validators.required, Validators.email]],
      contactInfoMobilePhoneControl:        ['', Validators.required],      
      contactInfoAddressCEPControl:         ['', Validators.required],
      contactInfoAddressStreetControl:      ['', Validators.required],
      contactInfoNeighborhoodStreetControl: ['', Validators.required],
      contactInfoAddressNumberControl:      ['', Validators.required],
      contactInfoAddressComplementControl:  [''],
      contactInfoAddressCityControl:        ['', Validators.required],
      contactInfoAddressStateControl:       ['', Validators.required],
    });
    this.contactInfoFormGroup.get('contactInfoEmailControl').setValue(this.payer.contactInformation.email);
    this.contactInfoFormGroup.get('contactInfoMobilePhoneControl').setValue(this.payer.contactInformation.mobilePhone.number);    
    this.contactInfoFormGroup.get('contactInfoAddressCEPControl').setValue(this.payer.contactInformation.address.cep);
    this.contactInfoFormGroup.get('contactInfoAddressStreetControl').setValue(this.payer.contactInformation.address.street);
    this.contactInfoFormGroup.get('contactInfoNeighborhoodStreetControl').setValue(this.payer.contactInformation.address.neighborhood);
    this.contactInfoFormGroup.get('contactInfoAddressNumberControl').setValue(this.payer.contactInformation.address.number);        
    this.contactInfoFormGroup.get('contactInfoAddressComplementControl').setValue(this.payer.contactInformation.address.complement);
    this.contactInfoFormGroup.get('contactInfoAddressCityControl').setValue(this.payer.contactInformation.address.city);
    this.contactInfoFormGroup.get('contactInfoAddressStateControl').setValue(this.payer.contactInformation.address.state);
    
  }  

  /**
   * 
   * @param cep 
   */
   public fillAddress(cep:string){
    if(cep.length != 9) return;

    this._addressService.get(cep).subscribe((address)=>{
      this.hasValidCep = true;
      this.contactInfoFormGroup.get('contactInfoAddressStreetControl').setValue(address.logradouro);
      this.contactInfoFormGroup.get('contactInfoNeighborhoodStreetControl').setValue(address.bairro);
      this.contactInfoFormGroup.get('contactInfoAddressCityControl').setValue(address.localidade);
      this.contactInfoFormGroup.get('contactInfoAddressStateControl').setValue(address.uf);
    });
  }


  /**
   * 
   * @param personalInfoFormGroupValue 
   */
   public onSubmitPersonalInfoForm(personalInfoFormGroupValue){    
    this.payer.personalInformation.name = personalInfoFormGroupValue.personalInfoNameControl;
    this.payer.personalInformation.cpf = personalInfoFormGroupValue.personalInfoCPFControl;
    this.payer.personalInformation.birthday = personalInfoFormGroupValue.personalInfoBirthdayControl;
  }  

  /**
   * 
   * @param contactInfoFormGroupValue 
   */
   public onSubmitContactInfoForm(contactInfoFormGroupValue){    
    this.payer.contactInformation.email = contactInfoFormGroupValue.contactInfoEmailControl;
    this.payer.contactInformation.mobilePhone.number = contactInfoFormGroupValue.contactInfoMobilePhoneControl;
    this.payer.contactInformation.address.cep = contactInfoFormGroupValue.contactInfoAddressCEPControl;
    this.payer.contactInformation.address.street = contactInfoFormGroupValue.contactInfoAddressStreetControl;
    this.payer.contactInformation.address.neighborhood = contactInfoFormGroupValue.contactInfoNeighborhoodStreetControl;
    this.payer.contactInformation.address.number = contactInfoFormGroupValue.contactInfoAddressNumberControl;
    this.payer.contactInformation.address.complement = contactInfoFormGroupValue.contactInfoAddressComplementControl;
    this.payer.contactInformation.address.city = contactInfoFormGroupValue.contactInfoAddressCityControl;
    this.payer.contactInformation.address.state = contactInfoFormGroupValue.contactInfoAddressStateControl;
  }  

  /**
   * 
   */
  public onSubmitCreditCardForm(creditCardValue){
    this.creditCard = creditCardValue;
  }

  /**
   * 
   */
  public onConfirmSubscription() : void {
    
  }
}
