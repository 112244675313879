import { AfterViewInit, ElementRef, HostListener, ViewChild } from '@angular/core';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { PlyrComponent } from 'ngx-plyr';
import * as Plyr from 'plyr';

@Component({
  selector: 'app-youtube-player',
  templateUrl: './youtube-player.component.html',
  styleUrls: ['./youtube-player.component.scss']
})
export class YoutubePlayerComponent implements OnInit {
  @ViewChild("player",{static:true}) divPlayerRef:ElementRef;
  @ViewChild(PlyrComponent, { static: true })  plyr: PlyrComponent;
  private player;  

  @Input() id:string;
  @Input() showProgress:boolean = false;
  public url:SafeResourceUrl;
  public unsanitazedUrl:string;

  private readonly youtubeAPIOptions = {
    autoplay: 1,
    modestbranding: 1,
    ytControls: 0,
    disablekb: 1,
    rel: 0,
    showinfo: 0,
    fs: 0,
    playsinline: 0
  };
  

  public videoJsOptions = { 
    autoplay: 'any',
    controls: true,
    fluid: true,
    techOrder: ['youtube'],
    sources: [],
    youtube: this.youtubeAPIOptions
  };

  public plyrSources = [
    {
      src: '',
      provider: 'youtube',
    },
  ];

  public plyrOptions = {
    settings: ['quality'],
    controls: ['play', 'current-time', 'mute', 'volume', 'settings', 'pip', 'airplay', 'fullscreen'],
    fullscreen: { enabled: true, fallback: true, iosNative: true, container: null },
    autoplay: true,
    volume: 1,
    disableContextMenu: true,
    invertTime: false,
    youtube: this.youtubeAPIOptions
  };

  private castSession;
  private cast;

  constructor(
    private sanitizer: DomSanitizer
  ) { }

  public ngOnInit() : void {
    // TODO: verificar aqui se o ID é diferente de nulo ?
    this.unsanitazedUrl = `https://www.youtube.com/embed/${this.id}`;
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.unsanitazedUrl);

    this.plyrSources[0].src = this.unsanitazedUrl;
    if(this.showProgress){
      this.plyrOptions.controls.push('progress');
    }

    // this.loadChromecastAPI();

    /*
    this.videoJsOptions.sources.push({ 
      src: this.unsanitazedUrl,
      type: 'video/youtube'
    });
    */
    
    // Inicialização da API nativa do youtube
    //this.init();
  }
  
  //#region Plyr API
  public onPlyrReady() : void {
    // Remove interação do iframe
    var iframe = document.getElementsByTagName('iframe')[0];
    iframe.style.pointerEvents = "none";
  }
  //#endregion

  //#region Cromecast
  private loadChromecastAPI() : Promise<void> {
    window['__onGCastApiAvailable'] = function(isAvailable) {      
      console.log('__onGCastApiAvailable');
      console.log(isAvailable);
      if (isAvailable) { 
        window['cast'].framework.CastContext.getInstance().setOptions({
          receiverApplicationId: window['chrome'].cast.media.DEFAULT_MEDIA_RECEIVER_APP_ID,
          autoJoinPolicy: window['chrome'].cast.AutoJoinPolicy.ORIGIN_SCOPED
        });
        console.log(window['cast'].framework.CastContext.getInstance());
      }
    };

    return new Promise((resolve,reject)=>{
      if (window['cast']) {
        resolve();
      }    
      var tag = document.createElement('script');
      tag.src = 'https://www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1';
      tag.onload = ()=>{
        resolve();
      }
      tag.onerror = ()=>{
        reject();
      }
      var firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    });
  }

  private initializeCastApi() : void {
    console.log('initializeCastApi');
    window['chrome'].cast.framework.CastContext.getInstance().setOptions({
      receiverApplicationId: window['chrome'].cast.media.DEFAULT_MEDIA_RECEIVER_APP_ID,
      autoJoinPolicy: window['chrome'].cast.AutoJoinPolicy.ORIGIN_SCOPED
    });

    this.castSession = window['chrome'].cast.framework.CastContext.getInstance().getCurrentSession();
  }

  public castMedia() : void {
    let castContext = window['cast'].framework.CastContext.getInstance();
    castContext.addEventListener(window['cast'].framework.CastContextEventType.CAST_STATE_CHANGED,    (event)=>{console.log(`CAST_STATE_CHANGED:`); console.log(event)});
    castContext.addEventListener(window['cast'].framework.CastContextEventType.SESSION_STATE_CHANGED, (event)=>{console.log(`SESSION_STATE_CHANGED:`); console.log(event)});
    castContext.requestSession()
    .then(()=>{
      console.log("[requestSession] - Sucesso");
      let castSession = castContext.getCurrentSession();
      var mediaInfo = new window['chrome'].cast.media.MediaInfo("https://manifest.googlevideo.com/api/manifest/hls_playlist/expire/1619143065/ei/OdWBYPX2EMLTwgSUkrjIAw/ip/2804:14c:5b74:833c:a966:379c:7add:a65c/id/5qap5aO4i9A.0/itag/96/source/yt_live_broadcast/requiressl/yes/ratebypass/yes/live/1/sgoap/gir%3Dyes%3Bitag%3D140/sgovp/gir%3Dyes%3Bitag%3D137/hls_chunk_host/r2---sn-oxunxg8pjvn-jo4s.googlevideo.com/playlist_duration/30/manifest_duration/30/vprv/1/playlist_type/DVR/initcwndbps/7790/mh/30/mm/44/mn/sn-oxunxg8pjvn-jo4s/ms/lva/mv/m/mvi/2/pl/48/dover/11/keepalive/yes/fexp/24001373,24007246/mt/1619121144/sparams/expire,ei,ip,id,itag,source,requiressl,ratebypass,live,sgoap,sgovp,playlist_duration,manifest_duration,vprv,playlist_type/sig/AOq0QJ8wRQIhAJvXABaR3zPaTjqj7X80xIWoRWnK6qNNMOirtyqT7wjYAiAtmcIWzV2eVY0Tdw9jkFGlHiRflmS4su1ygBLDTXN23w%3D%3D/lsparams/hls_chunk_host,initcwndbps,mh,mm,mn,ms,mv,mvi,pl/lsig/AG3C_xAwRAIgG1Rjy-EaOXlkmPG5jiahGrPcAe0BFm8Hv-ZRixKCq88CIB2_oOArkZd1SOWLytflYIkczbJoenJzOC5Wrcipqihx/playlist/index.m3u8", 'video/mp4');
      mediaInfo.streamType = window['chrome'].cast.media.StreamType.LIVE;      
      var request = new window['chrome'].cast.media.LoadRequest(mediaInfo);
      castSession.loadMedia(request).then(()=>{
        console.log("[loadMedia] - Sucesso");
      })
      .catch((errorCode)=>{
        console.log(`[loadMedia] - Falha ${errorCode}`);
      });      
    })
    .catch((errorCode)=>{
      console.log(`[requestSession] - Falha ${errorCode}`);
    });
  }
  //#endregion


  //#region Youtube API
  init() {
    if (window['YT']) {
      this.startVideo();
      return;
    }    
    var tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    /* 3. startVideo() will create an <iframe> (and YouTube player) after the API code downloads. */
    window['onYouTubeIframeAPIReady'] = () => this.startVideo();
  }

  startVideo() {
    // Remove interações do iframe
    //var iframeRef = document.getElementsByTagName("iframe");
    //iframeRef[0].style.pointerEvents = "none";

    this.player = new window['YT'].Player('player', {
      videoId: this.id,
      playerVars: this.youtubeAPIOptions,
      events: {
        'onStateChange': this.onPlayerStateChange.bind(this),
        'onError': this.onPlayerError.bind(this),
        'onReady': this.onPlayerReady.bind(this),
      }
    });
  }

  /* 4. It will be called when the Video Player is ready */
  onPlayerReady(event) {
    event.target.playVideo();
    // Coloca o tamanho para ocupar toda a àrea do componente
    this.player.setSize(this.divPlayerRef.nativeElement.clientWidth, this.divPlayerRef.nativeElement.clientHeight);    
    
    // Inicializa em tela cheia
    var iframe = this.player.getIframe();
    var requestFullScreen = iframe.requestFullScreen || iframe.mozRequestFullScreen || iframe.webkitRequestFullScreen;
    if (requestFullScreen) {
      requestFullScreen.bind(iframe)();
    }
    // Remove interações de clique
    iframe.style.pointerEvents = "none";
  }

  onPlayerStateChange(event) {
    switch (event.data) {
      case window['YT'].PlayerState.PLAYING:
        break;
      case window['YT'].PlayerState.PAUSED:
        break;
      case window['YT'].PlayerState.ENDED:
        break;
    }
  }
      
  cleanTime() {
    return Math.round(this.player.getCurrentTime())
  }

  onPlayerError(event) {
    switch (event.data) {
      case 2:
        break;
      case 100:
        break;
      case 101 || 150:
        break;
    }
  }
  //#endregion

}
