import { Utils } from "../utils/utils.functions";
import { IDataControllable, IPrimitiveObject } from "./common.model";

export type SubscriptionStatus = "Degustação" | "Inativo" | "Ativo" | "Cancelado";

export type PaymentStatus = "Aguardando" | "Pago" | "Em Atraso" | "Contestado" | "Cancelado" | "Estornado";

export type Periodicity = undefined | "Mensal" | "Trimestral";

export interface IPlan {
    name: string;
    periodicity: Periodicity;
    value: number;
    plan_id: number;
}

export class SubscriptionPlan implements IDataControllable, IPrimitiveObject {
    public uid: string;
    public started_at: Date;
    public ended_at?: Date;
    public updated_at?: Date;
    public status: SubscriptionStatus;
    public payment_status: PaymentStatus;
    public plan?:IPlan;
    public currentValue: number;
    public gateway?: any;
        
    constructor(uid:string) {
        this.uid = uid;
        this.started_at = new Date();
        this.updated_at = new Date();
        this.status = "Inativo";
        this.payment_status = "Aguardando";
        this.currentValue = 69.90; // Apenas para nunca inicializar zerado
        this.gateway = {};
    }

    /**
     * 
     */
    public isVirgem() : boolean {
        return this.uid === '';
    }

    //#region Barramento de conversão para o Data Controller
    /**
     * Barramento de conversão entre o modelo de dados e o tipo nativo do DataController
     * OBS: nesse caso é do Firebase. Mas essa função deveria ser genérica
     * OBS: As datas devem ser convertidas do tipo Timestamp para Date
     * @param firebaseData 
     */    
    public static fromDataControllerBus(firebaseDocSnap:any) : SubscriptionPlan {
        var sub = new SubscriptionPlan(firebaseDocSnap.id);
        var firebaseData = (firebaseDocSnap.data) ? firebaseDocSnap.data() :  firebaseDocSnap;
                
        if(firebaseData['started_at']) sub.started_at = (firebaseData['started_at'] as any).toDate();
        if(firebaseData['ended_at']) sub.ended_at = (firebaseData['ended_at'] as any).toDate();
        if(firebaseData['updated_at']) sub.updated_at = (firebaseData['updated_at'] as any).toDate();
        if(firebaseData['status']) sub.status = firebaseData['status'];
        if(firebaseData['payment_status']) sub.payment_status = firebaseData['payment_status'];
        if(firebaseData['plan']) sub.plan = firebaseData['plan'];
        if(firebaseData['currentValue']) sub.currentValue = firebaseData['currentValue'];
        if(firebaseData['gateway']) sub.gateway = firebaseData['gateway'];

        return sub;
    }

    /**
     * Barramento de conversão entre o modelo de dados e o tipo nativo do DataController
     */
    public toDataControllerBus() : object {
        return Utils.removeUndefinedKeys({
            uid: this.uid,
            started_at: this.started_at,
            ended_at: this.ended_at,
            updated_at: this.updated_at,
            status: this.status,
            payment_status: this.payment_status,
            plan: this.plan,
            currentValue: this.currentValue,
            gateway: this.gateway
        });
    } 
    //#endregion     

    //#region Interface IPrimitiveObject
    /**
     * Converte o modelo para o tipo primitivo do javascript
     * As datas são serializadas como ISO String, e por conta
     * disso, convertidas para UTC.
     */
    public toPrimitiveObject(): object {
        return Utils.toJSON(this);
    } 

    /**
     * Converte um objeto do tipo primitivo do javascript serializado
     * para o modelo. 
     * É necessário converter todas as datas manualmente pois elas estão
     * serializadas como ISO Strings
     * @param primitive 
     */
    public static fromPrimitiveObject(p:object) : SubscriptionPlan {
        var primitive = p as any;
        var sub = new SubscriptionPlan(primitive["uid"]);    
        
        if(primitive["started_at"]) sub.started_at = new Date(primitive["started_at"]);
        if(primitive["ended_at"]) sub.ended_at = new Date(primitive["ended_at"]);
        if(primitive["updated_at"]) sub.updated_at = new Date(primitive["updated_at"]);
        if(primitive["status"]) sub.status = primitive["status"];
        if(primitive["payment_status"]) sub.payment_status = primitive["payment_status"];
        if(primitive['plan']) sub.plan = primitive['plan'];
        if(primitive['currentValue']) sub.currentValue = primitive['currentValue'];
        if(primitive['gateway']) sub.gateway = primitive['gateway'];                

        return sub;
    }
    //#endregion     


    /**
     * Retorna uma cópia do objeto porém com outra referÊncia
     * @param p 
     */
    public getCopy() : SubscriptionPlan {  
        return SubscriptionPlan.fromPrimitiveObject(this.toPrimitiveObject());
    }    
}