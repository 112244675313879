import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-instagram-player',
  templateUrl: './instagram-player.component.html',
  styleUrls: ['./instagram-player.component.scss']
})
export class InstagramPlayerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
