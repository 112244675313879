import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ClassMapEditorComponent } from './components/class-map-editor/class-map-editor.component';
import { MyClassMapsListComponent } from './components/my-class-maps-list/my-class-maps-list.component';
import { AppComponent } from './app.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LoaderScreenComponent } from './components/loader-screen/loader-screen.component';
import { AppContentComponent } from './components/app-content/app-content.component';
import { RegistrationFormComponent } from './components/registration-form/registration-form.component';
import { MyAccountComponent } from './components/my-account/my-account.component';
import { LiveClassComponent } from './components/live-class/live-class.component';
import { LiveClassesListingComponent } from './components/live-classes-listing/live-classes-listing.component';
import { LiveClassroomComponent } from './components/live-classroom/live-classroom.component';
import { RecordedClassesListingComponent } from './components/recorded-classes-listing/recorded-classes-listing.component';
import { RecordedClassroomComponent } from './components/recorded-classroom/recorded-classroom.component';
import { SubscriptionManagementComponent } from './components/subscription-management/subscription-management.component';
import { FeedbackFormComponent } from './components/feedback-form/feedback-form.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { TermsOfServiceComponent } from './components/terms-of-service/terms-of-service.component';

const routes: Routes = [
  {   // Rota inicial
    path: '', redirectTo: '/accessDenied', pathMatch: 'full'
  },{ // Rota para a Política de Privacidade
    path: 'privacyPolicy', pathMatch: 'full',
    component: PrivacyPolicyComponent,
  },{ // Rota para os Termos de serviço
    path: 'termsOfService', pathMatch: 'full',
    component: TermsOfServiceComponent,
  },{ // Rota para acesso negado
    path: 'accessDenied',
    component: LoaderScreenComponent,
  },{ // Rota para solicitação de cadastro
    path: 'registration',
    component: RegistrationFormComponent,
  },{ // Rotas de acesso concedido
    path: 'accessAllowed',
    component: AppContentComponent,
    children: [
      {path:'',                             component: DashboardComponent},
      {path:'dashboard',                    component: DashboardComponent},
      {path:'class-map',                    component: ClassMapEditorComponent},
      {path:'my-class-maps',                component: MyClassMapsListComponent},
      {path:'my-account',                   component: MyAccountComponent},
      {path:'live-class',                   component: LiveClassComponent},
      {path:'live-classroom',               component: LiveClassroomComponent},
      {path:'live-classes-list',            component: LiveClassesListingComponent},
      {path:'recorded-classes-list',        component: RecordedClassesListingComponent, data:{public:false}},
      {path:'public-recorded-classes-list', component: RecordedClassesListingComponent, data:{public:true}},
      {path:'recorded-classroom',           component: RecordedClassroomComponent},
      {path:'subscription-management',      component: SubscriptionManagementComponent},
      {path:'feedback',                     component: FeedbackFormComponent},
    ]    
  },{ // Rota de url inválida 
    path: '**', redirectTo: '/accessDenied' 
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
