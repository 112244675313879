import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IStreamDialogResult } from 'src/app/models/live-stream.model';

@Component({
  selector: 'app-close-stream-dialog',
  templateUrl: './close-stream-dialog.component.html',
  styleUrls: ['./close-stream-dialog.component.scss']
})
export class CloseStreamDialogComponent implements OnInit {
 
  constructor(
    @Inject(MAT_DIALOG_DATA) public data:any 
  ) { }

  ngOnInit(): void {
  }

}
