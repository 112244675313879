/**
 * Faz a inicialização do Facebook SDK antes do bootstrap do app, atravez do injection token 'APP_INITIALIZER'
 * OBS: O ideal seria injetar FacebookService aqui e fazer a inicialização a partir dele mas não consegui.
 * @returns 
 */
export function facebookSDKInitializer() {
    return () => new Promise<void>(resolve => {
        window['fbAsyncInit'] = function () {
            FB.init({
              appId      : '534430810917098',
              xfbml      : true,
              version    : 'v10.0'
            });
            FB.AppEvents.logPageView();
            resolve();            
        };
      
        (function(d, s, id){
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {return;}
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    });
}

/**
 * Faz a inicialização do Facebook SDK antes do bootstrap do app, atravez do injection token 'APP_INITIALIZER'
 * OBS: O ideal seria injetar FacebookService aqui e fazer a inicialização a partir dele mas não consegui.
 * @returns 
 */
 declare let fbq:Function;
 export function facebookPixelInitializer() {
    return () => new Promise<void>(resolve => {
        (function (f: any, b, e, v, n, t, s) {
            if (f.fbq) return; n = f.fbq = function () {
                n.callMethod ?
                    n.callMethod.apply(n, arguments) : n.queue.push(arguments)
            }; if (!f._fbq) f._fbq = n;
            n.push = n; n.loaded = !0; n.version = '2.0'; n.queue = [];
            t = b.createElement(e);
            t.async = !0;
            t.src = v;
            t.onload = function(){};
            s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s)
          })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '463902341424619');
          resolve();        
    });
}