import { Platform } from '@angular/cdk/platform';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LiveStream } from 'src/app/models/live-stream.model';
import { Professor } from 'src/app/models/professor.model';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { ErrorHandlingService } from 'src/app/services/error-handling/error-handling.service';
import { OnlineDataControllerService } from 'src/app/services/online-data-controller/online-data-controller.service';

@Component({
  selector: 'app-recorded-classes-listing',
  templateUrl: './recorded-classes-listing.component.html',
  styleUrls: ['./recorded-classes-listing.component.scss']
})
export class RecordedClassesListingComponent implements OnInit, OnDestroy {
  public isExecAsyncRequest:boolean;

  // Professores
  public professors:Array<Professor> = new Array<Professor>();
  // Professor selecionado
  public professor_uid:string;
  // Aulas gravadas
  public recordedStreams:Array<LiveStream> = new Array<LiveStream>();

  private routeSubs: Subscription;

  private public:Boolean = false;

  constructor(
    private _dc:OnlineDataControllerService,
    private _errorHnd:ErrorHandlingService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _platform: Platform,
    private _auth:AuthenticationService,
    private _analytics:AnalyticsService
  ) { }

  /**
   * 
   */
  public ngOnInit(): void {

    this.routeSubs = this._route.data.subscribe((parameter) => {
      this.public = parameter.public;
    });

    this.isExecAsyncRequest = true;
    // Obtem os professors
    this._dc.getAllProfessors()
    .then((professors)=>{
      // Somente os professores com acesso liberado
      this.professors = professors.filter((p)=>p.alowed);
    })
    .catch((err)=>{
      this._errorHnd.show(err);
    })
    .finally(() => this.isExecAsyncRequest = false);
  
  }

  /**
   * 
   */
  public ngOnDestroy(): void {
    if(this.routeSubs) this.routeSubs.unsubscribe();
  }

  /**
   * 
   */
  public loadProfessorClasses(professor_uid:string): void {
    this.professor_uid = professor_uid;
    this.isExecAsyncRequest = true;
    this._dc.getProfessorRecordedStreams(professor_uid, null, null)
    .then((recordedStreams)=>{
      if(this.public){
        // Exibir apenas as aulas públicas
        this.recordedStreams = recordedStreams.filter((r)=>r.public);
      }else{
        this.recordedStreams = recordedStreams.filter((r)=>!r.public);
      }
      this.recordedStreams = this.recordedStreams.sort((a,b) => b.started_at.valueOf() - a.started_at.valueOf());
    })
    .catch((err)=>{
      this._errorHnd.show(err);
    })
    .finally(() => this.isExecAsyncRequest = false);    
  }

  /**
   * 
   * @param recordedStream 
   */
  public openRecordedStream(recordedStream:LiveStream) : void {
    this._analytics.logEvent('Watch offline class',{uid:this._auth.getLoggedUser().uid, class_uid:recordedStream.uid});
    switch(recordedStream.source){
      // O link original será algo no formato: https://www.instagram.com/tv/COOlY8vp62f1ov6h-9SWJQZitP-tOQ9HQrWR1I0/

      case 'instagram':{
        if(this._platform.ANDROID){
          // window.location.href = "intent://www.instagram.com/tv/COOlY8vp62f1ov6h-9SWJQZitP-tOQ9HQrWR1I0/#Intent;package=com.instagram.android;scheme=https;end" <<< Não funciona
          window.location.href = recordedStream.url;
        }else if(this._platform.IOS){
          // window.location.href = "instagram://media?id=COOlY8vp62f1ov6h-9SWJQZitP-tOQ9HQrWR1I0"; <<< Não funciona
          window.location.href = recordedStream.url;          
        }else{
          window.location.href = recordedStream.url;
        }
        break;
      }
      case 'youtube':{
        this._router.navigate(['/accessAllowed/recorded-classroom', {video_id: recordedStream.youtubeId}]);
        break;
      }
      case 'private':{
        break;
      }
    }
  }
}
