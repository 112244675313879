import { Injectable } from '@angular/core';
import { DialogService } from '../dialog/dialog.service';
import { RemoteConfigService } from '../remote-config/remote-config.service';
import { AuthenticationService } from '../authentication/authentication.service';
import { NotificationService } from '../notification/notification.service';
import { User } from '../../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class TermsOfServiceService {

  constructor(
    private _dialog:DialogService,
    private _rc:RemoteConfigService,
    private _auth:AuthenticationService,
    private _notification:NotificationService
  ) { 

  }

  public validate() : Promise<boolean> {
    return new Promise((resolve,reject)=>{
      var user:User = this._auth.getLoggedUser();
      this._rc.fetchAndActivate().then(()=>{
        if((user.accepted_terms_of_use == undefined) || 
           (user.accepted_terms_of_use.version < this._rc.getTermsOfUseVersion())){
          // É necessário aceitar os novos termos
          this._dialog.createTermsOfService().subscribe((result)=>resolve(result));
        }
        resolve(true);
      });
    });


  }
}
