<div class="full-content-container" [loader]='isExecAsyncRequest'>
    <div class="content-padding" fxLayout="column">

        <!--Form para alteração das informações pessoais-->
        <div fxFlex="1 1 auto">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>Envie seu Feedback</mat-card-title>
                </mat-card-header>
                <mat-card-content>                    
                    <form [formGroup]="feedbackFormGroup">
                        <div fxLayout="row warp">
                            <!--Assunto-->                
                            <mat-form-field>
                                <mat-label>Cadastro para...</mat-label>
                                <mat-select 
                                    #subjectSelect 
                                    required
                                    cdkFocusInitial
                                    (selectionChange)="onSubjectSelect()"
                                    formControlName="subjectControl">
                                    <mat-option value="Reportar um problema">Preciso de ajuda</mat-option>
                                    <mat-option value="Reportar um problema">Reportar um problema</mat-option>
                                    <mat-option value="Fazer um elogio">Fazer um elogio</mat-option>
                                    <mat-option value="Dar uma sugestão">Dar uma sugestão</mat-option>
                                    <mat-option value="Fazer uma crítica">Fazer uma crítica</mat-option>
                                    <mat-option value="Outro">Outro</mat-option>
                                </mat-select>
                                <mat-error *ngIf="feedbackFormGroup.get('subjectControl').hasError('required')">É necessário escolher um assunto</mat-error>
                            </mat-form-field>

                            <!--Assunto (Outro)-->                
                            <mat-form-field *ngIf="subjectSelect.value == 'Outro'">
                                <input 
                                    #subjectInput
                                    matInput 
                                    required
                                    placeholder="Assunto" 
                                    maxlength="1000"
                                    formControlName="otherSubjectControl">
                                <mat-error *ngIf="feedbackFormGroup.get('otherSubjectControl').hasError('required')">O assunto não pode ser vazio</mat-error>
                                <mat-hint align="end">{{subjectInput.value.length}} / 100</mat-hint>
                            </mat-form-field>                            

                            <!--Comentário-->
                            <mat-form-field>
                                <textarea 
                                    #feedbackInput
                                    matInput 
                                    textare
                                    rows="10"
                                    required
                                    placeholder="Comentário" 
                                    maxlength="1000"                                
                                    formControlName="commentControl"></textarea>
                                <mat-error *ngIf="feedbackFormGroup.get('commentControl').hasError('required')">Seu feedback não pode ser vazio</mat-error>
                                <mat-hint align="end">{{feedbackInput.value.length}} / 1000</mat-hint>
                            </mat-form-field>   
                        </div>
                    </form>                    
                </mat-card-content>
            </mat-card>
        </div> 

    </div>
</div>


<button 
    mat-fab 
    matTooltip="Atualizar informações"
    (click)="send()"
    color="primary"
    class="fab-button">
    <mat-icon>send</mat-icon>        
</button>