import { Component, Input, OnInit } from '@angular/core';
import { Professor } from 'src/app/models/professor.model';

@Component({
  selector: 'app-professor-card',
  templateUrl: './professor-card.component.html',
  styleUrls: ['./professor-card.component.scss']
})
export class ProfessorCardComponent implements OnInit {
  @Input() professor:Professor;

  constructor() { }

  ngOnInit() {
  }

}
