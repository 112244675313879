import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription, Unsubscribable } from 'rxjs';
import { Client } from 'src/app/models/client.model';
import { IPlan, SubscriptionPlan } from 'src/app/models/subscription.model';
import { _PLANO_MENSAL, _PLANO_TRIMESTRAL } from 'src/app/models/subscription.plans';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { CloudFunctionsService } from 'src/app/services/cloud-functions/cloud-functions.service';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { ErrorHandlingService } from 'src/app/services/error-handling/error-handling.service';
import { GerenciaNetService } from 'src/app/services/gerencia-net/gerencia-net.service';

@Component({
  selector: 'app-subscription-management',
  templateUrl: './subscription-management.component.html',
  styleUrls: ['./subscription-management.component.scss']
})
export class SubscriptionManagementComponent implements OnInit, OnDestroy, Unsubscribable {
  
  public client:Client;
  public activeSubsPlan:SubscriptionPlan = new SubscriptionPlan('');
  private activePlanSubscription: Subscription;

  public isExecAsyncRequest:boolean;
  public _PLANO_MENSAL = _PLANO_MENSAL;
  public _PLANO_TRIMESTRAL = _PLANO_TRIMESTRAL;

  constructor(
    private _auth:AuthenticationService,
    private _functions:CloudFunctionsService,
    private _dialog:DialogService,
    private _paymentGateway:GerenciaNetService,
    private _errorHnd:ErrorHandlingService,
    private _analytics:AnalyticsService
  ) { }

  /**
   * 
   */
  public async ngOnInit() : Promise<void> {
    this.isExecAsyncRequest = true;

    await this._auth.secureApplication();
    this.client = this._auth.getLoggedUser() as Client;

    // Observa o plano de assinatura ativo do cliente
    this.activePlanSubscription = this._auth.loggedUserSubsPlan.subscribe((activeSubsPlanUpdate:SubscriptionPlan)=>{
      this.activeSubsPlan = activeSubsPlanUpdate;
      this.isExecAsyncRequest = false;
    });

  }

  /**
   * 
   */
  public ngOnDestroy() : void {
    this.unsubscribe();
  }

  /**
   * 
   */
  public unsubscribe() : void {
    if(this.activePlanSubscription) this.activePlanSubscription.unsubscribe();
  }


  /**
   * 
   * @param plan 
   */
  public onSubscribe(plan:IPlan) : void {    
    var obsvrSubs = this._dialog.showSubscriptionPayment(plan).subscribe((info)=>{
      if(!info) {
        return;
      }
      this.isExecAsyncRequest = true;
      this._paymentGateway.getPaymentToken(info.cc)
      .then((token)=>{
        this._analytics.logEvent('Enter payment information', {uid:this.client.uid});
        return this._functions.subscribeToPlan({
          plan:plan,
          payer: info.payer,
          gateway_data: {payment_token:token}
        })
      })
      .then(()=>{
        this._analytics.logEvent('Subscribe', {uid:this.client.uid}, plan);
        // Assinatura criada com sucesso
        // TODO: Esse diálogo ficou muito pequeno
        this._dialog.createInformation("Parabéns! Sua assinatura Cycle Connect foi criada. Assim que o pagamento for confirmado pelo sistema seu acesso será atualizado automaticamente.");
      })
      .catch((error)=>{
        this._errorHnd.show(error);
      })
      .finally(()=>{
        obsvrSubs.unsubscribe();
        this.isExecAsyncRequest = false;
      })      
    });
    
  }

  /**
   * 
   */
  public onCancel() : void {
    this._dialog.createQuestion("Deseja realmente cancelar a assinatura?").subscribe((yes)=>{
      if(yes){
        this.isExecAsyncRequest = true;
        this._functions.cancelSubscription({
          client: this.client.toPrimitiveObject(),
          subs: this.activeSubsPlan.toPrimitiveObject()
        })
        .then(()=>{
          this._analytics.logEvent('Cancel Subsciption', {uid:this.client.uid});
          this._dialog.createInformation("Assinatura cancelada com sucesso.");
        })
        .catch((error)=>{
          this._errorHnd.show(error);
        })
        .finally(()=>{
          this.isExecAsyncRequest = false;
        });
      }
    });
  }
}
