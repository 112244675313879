<div class="full-content-container" [loader]='isExecAsyncRequest'>
    <div class="content-padding" fxLayout="column">

        <ng-container *ngIf="(activeSubsPlan?.status === 'Inativo') || (activeSubsPlan?.status === 'Cancelado'); else activePlan">
            <h2>Planos disponíveis</h2>            
    
                <div fxFlex="1 1 auto">
                    <mat-card>
                        <mat-card-header>
                            <mat-card-title>{{_PLANO_MENSAL.name}}</mat-card-title>
                        </mat-card-header>
                        <mat-card-content>                    
                            {{_PLANO_MENSAL.value | currency:'BRL'}}
                        </mat-card-content>
                        <mat-card-actions align="end">
                            <button mat-button (click)="onSubscribe(_PLANO_MENSAL)">ASSINAR</button>
                        </mat-card-actions>
                    </mat-card>    
                </div>
                
                <div style="padding-top: 16px; padding-bottom: 16px;">
                    <mat-divider></mat-divider>
                </div>

                <div fxFlex="1 1 auto">
                    <mat-card>
                        <mat-card-header>
                            <mat-card-title>{{_PLANO_TRIMESTRAL.name}}</mat-card-title>
                        </mat-card-header>
                        <mat-card-content>                    
                            {{_PLANO_TRIMESTRAL.value | currency:'BRL'}}
                        </mat-card-content>
                        <mat-card-actions align="end">
                            <button mat-button (click)="onSubscribe(_PLANO_TRIMESTRAL)">ASSINAR</button>
                        </mat-card-actions>                
                    </mat-card>   
                </div>            

        </ng-container>

        <ng-template #activePlan>
            <h2>Plano ativo</h2> 

            <div fxFlex="1 1 auto">
                <mat-card>
                    <mat-card-header>
                        <mat-card-title>{{activeSubsPlan.plan.name}}</mat-card-title>
                        <mat-card-subtitle>
                            <p> Início: {{activeSubsPlan.started_at | date:'shortDate'}} <span style="float: right;">Situação: {{activeSubsPlan.status}}</span> </p>
                        </mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-content>                    
                        <p> Valor atual: {{activeSubsPlan.currentValue | currency:'BRL'}} </p>                        
                        <p> Situação do pagamento: {{activeSubsPlan.payment_status}} </p>                        
                        <p> Próximo pagamento em: {{activeSubsPlan.gateway?.subscription?.next_execution | date:'shortDate'}} </p>                        
                    </mat-card-content>
                    <mat-card-actions align="end">
                        <button mat-button (click)="onCancel()">CANCELAR ASSINATURA</button>
                    </mat-card-actions>                
                </mat-card>   
            </div>

            <div style="padding-top: 16px; padding-bottom: 16px;">
                <mat-divider></mat-divider>
            </div>

            <!-- TODO: Histórico de pagamentos -->
            <!-- <h2>Histórico de pagamentos</h2> -->

        </ng-template>        

    </div>
</div>