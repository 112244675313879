<mat-sidenav-container class="sidenav-container">

  <mat-sidenav-content>
      <div #konvaStageContainer id="konvaStageContainer"></div>
  </mat-sidenav-content>

  <mat-sidenav opened mode="side" position="end" class="sidenav-content">

    <div class="sidenav-items">
      <div class="section">
        <span class="section-title">Propriedades da aula</span>
        <div class="section-internal">
          <mat-form-field>
            <input matInput placeholder="Nome" name="name" type="text"
              [(ngModel)]="classInfo && classInfo.name">
          </mat-form-field>
          <mat-form-field>
        <textarea matInput placeholder="Descrição" name="description" type="text"
          [(ngModel)]="classInfo && classInfo.description">
        </textarea>
          </mat-form-field>
          <mat-form-field>
            <input matInput name="totalTimeMinuts" placeholder="Tempo total" value="60" type="number"
              [(ngModel)]="classInfo && classInfo.totalTimeMinuts">
          </mat-form-field>  

          <!-- Para depuracao do ponto flutuante--->
          <!--
          <mat-form-field>              
              <input matInput name="0" type="text" 
                [value]="divContainerRef && divContainerRef.nativeElement.clientWidth"
                placeholder="div.clientwidth">
          </mat-form-field> 
          <mat-form-field>              
              <input matInput name="1" type="text" 
                [value]="divContainerRef && divContainerRef.nativeElement.clientHeight"
                placeholder="div.clientHeight">
          </mat-form-field> 

          <mat-form-field>              
              <input matInput name="2" type="text" 
                [value]="stage && stage.width()"
                placeholder="stage.width">
          </mat-form-field> 
          <mat-form-field>              
              <input matInput name="3" type="text" 
                [value]="stage && stage.height()"
                placeholder="stage.height">
          </mat-form-field>            
        -->

        </div>
      </div>
      <div class="section">
        <span class="section-title">Propriedades do ponto<span class="point" *ngIf="selectedAnchorPointRef;">#{{selectedAnchorPointRef._id}}</span></span>
        <div class="section-internal">
           <span *ngIf="selectedAnchorPointRef; else selectedAnchorNull">
                <mat-form-field>
                    <input matInput name="rpm" placeholder="RPM" type="number"
                      [(ngModel)]="classInfo && classInfo.rpmMap[selectedAnchorPointRef._id]">
                </mat-form-field>
               <mat-form-field>
                    <!-- REVIEW: Como verificar se está menor que o tempo da aula?--->
                    <input matInput name="x" type="text" mask="00:00:00" #xCoordSelectedAnchorPointProp
                      matTooltip=""
                      (input)="validateAndUpdateXCoordOfSelectedAnchorPoint($event)"
                      [placeholder]="xCoordToTimeStrForDisplay(selectedAnchorPointRef.x())"
                      (blur)="$event.target.value = ''; isEdittingPoint=false;"
                      (focus)="isEdittingPoint=true;">
                </mat-form-field>
                <mat-form-field>
                    <!-- REVIEW: Aqui deveria ter um onChanged() para verificar se o usuário digitou um valor maior que 100? (e converter automaticamente para 100? Tipo um Clamp?)--->
                    <input matInput name="y" type="number" maxlength="3" min="0" max="100"
                    (input)="validateAndUpdateYCoordOfSelectedAnchorPoint($event)"
                    [placeholder]="yCoordToPercentageStrForDisplay(selectedAnchorPointRef.y())"
                    (blur)="$event.target.value = ''; isEdittingPoint=false;"
                    (focus)="isEdittingPoint=true;">
                </mat-form-field>
            </span>
          <ng-template #selectedAnchorNull>Nenhum ponto selecionado</ng-template>
        </div>
      </div>
      <div class="footer">
        <button (click)="saveClass()" mat-flat-button color="primary" class="btnSalvar">Salvar Aula</button>
      </div>
    </div>

  </mat-sidenav>

</mat-sidenav-container>




