import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export interface IAddressByCEP {
  cep:string,
  logradouro:string,
  complemento:string,
  bairro:string,
  localidade:string,
  uf:string,
  unidade:string,
  ibge:string,
  gia:string,
}

@Injectable({
  providedIn: 'root'
})
export class AddressSearchService {

  constructor(private _http: HttpClient) { }

  public get(cep:string) : Observable<IAddressByCEP> {
    return this._http.get<IAddressByCEP>('https://viacep.com.br/ws/'+cep+'/json/');
  }

}
