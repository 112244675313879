import { Component, Output, EventEmitter, Input } from '@angular/core';
import {optionsFabAnimations} from "./options-fab.animations";

export interface IOptionFabButton {
  icon:string;
  text:string;
  action:string; 
}

@Component({
  selector: 'app-options-fab',
  templateUrl: './options-fab.component.html',
  styleUrls: ['./options-fab.component.scss'],
  animations: optionsFabAnimations
})
export class OptionsFabComponent {
  @Output() onMiniFabClick = new EventEmitter<string>();
  @Input() fabButtons:Array<IOptionFabButton> = [];
  @Input() icon:string = 'add';

  buttons = [];
  fabTogglerState = 'inactive';

  constructor() { }

  showItems() {
    this.fabTogglerState = 'active';
    this.buttons = this.fabButtons;
  }

  hideItems() {
    this.fabTogglerState = 'inactive';
    this.buttons = [];
  }

  onToggleFab() {
    this.buttons.length ? this.hideItems() : this.showItems();
  }

  public onClickMiniFab(action:string){    
    this.onMiniFabClick.emit(action);
    this.hideItems();
  }

}
