import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import firebase from "firebase/app";
import 'firebase/auth';
import 'firebase/functions';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/analytics';
import "firebase/remote-config";

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  private app:firebase.app.App;
  private auth:firebase.auth.Auth;
  private functions: firebase.functions.Functions;
  private firestore: firebase.firestore.Firestore;
  private storage: firebase.storage.Storage;
  private analytics:firebase.analytics.Analytics;
  private remoteConfig:firebase.remoteConfig.RemoteConfig;

  private firebaseConfig = {
    development:{
      apiKey: "AIzaSyC6bEKAd9YV8HdqShPzv00HNfT1fPcND0g",
      authDomain: "cycleconnect-br-dev.firebaseapp.com",
      databaseURL: "https://cycleconnect-br-dev.firebaseio.com",
      projectId: "cycleconnect-br-dev",
      storageBucket: "cycleconnect-br-dev.appspot.com",
      messagingSenderId: "785923878046",
      appId: "1:785923878046:web:10afecae68401046ca9f8f",
      measurementId: "G-S55BN6ZHMC"
    },
    production:{
      apiKey: "AIzaSyB7pcwBpYgRNyuhWsXlEJzOBUFyNPso8Q0",
      authDomain: "cycleconnect-br.firebaseapp.com",
      databaseURL: "https://cycleconnect-br.firebaseio.com",
      projectId: "cycleconnect-br",
      storageBucket: "cycleconnect-br.appspot.com",
      messagingSenderId: "830854115484",
      appId: "1:830854115484:web:4ecf2074b55f9edf0cbd59",
      measurementId: "G-KRXPT2GP9Z"
    }
  };

  /**
   * Realiza a inicialização dos serviços do firebase no construtor. Defini que esse serviço deve ser injetado
   * em todos os outros que utilizarem o firebase. Isso garante que a inicialização do firebase já foi feita
   */
  constructor() { 
    this.app = firebase.initializeApp(
      environment.production? this.firebaseConfig.production : this.firebaseConfig.development
    );
    // Inicializa a auth para esse app
    this.auth = firebase.auth(this.app);
    // Inicializa as functions para esse app
    this.functions = firebase.functions(this.app);
    // Inicializa o firestore para esse app
    this.firestore = firebase.firestore(this.app);
    // Inizializa o staorage para esse app
    this.storage = firebase.storage(this.app);
    // Inicializa o analytics
    this.analytics = firebase.analytics(this.app);
    // Inicializa o remote config
    this.remoteConfig = firebase.remoteConfig(this.app);
    this.remoteConfig.settings = {
      minimumFetchIntervalMillis: 60000,
      fetchTimeoutMillis: 3600000
    };
    this.remoteConfig.defaultConfig = {
      'terms_of_service_version': 0
    };    
  }

  public getApp() : firebase.app.App {
    return this.app;
  }

  public getAuth() : firebase.auth.Auth {
    return this.auth;
  }  

  public getFunctions() : firebase.functions.Functions {
    return this.functions;
  }
  
  public getFirestore() : firebase.firestore.Firestore {
    return this.firestore;
  }  

  public getStorage() : firebase.storage.Storage {
    return this.storage;
  }  
  
  public getAnalytics() : firebase.analytics.Analytics {
    return this.analytics;
  }

  public getRemoteConfig() : firebase.remoteConfig.RemoteConfig {
    return this.remoteConfig;
  }   
}
