<div class="container-fluid">
        
    <h4>O Cycle Connect é um grupo exclusivo de ciclismo indoor online</h4>
    <hr />
    <p>O projeto foi idealizado pelo professor Fred Reis para que todos possam desfrutar do ciclismo indoor no conforto e segurança da sua casa, sem abrir mão da qualidade e da metodologia.</p>
    
    <p>
    As aulas ao vivo (lives) são transmitidas para assinantes às segundas, quartas e sextas-feiras de 19:30hs às 20:15hs, através do perfil privado <b>@cycleconnect_exclusivo</b> ou pelo app Cycle Connect. 
    Aos sábados ou domingos teremos uma live aberta às 10:00hs no perfil <b>@fredreis</b> e <b>@cycleconnectbrasil</b>.
    </p>

    <p>
    Ao realizar a assinatura, você será convidado para o perfil exclusivo e terá acesso a todas as aulas já gravadas através do nosso app.
    </p>

  </div>