import { Component, OnInit } from '@angular/core';
import { ClassMap } from 'src/app/models/class-map.model';
import { LocalDataControllerService } from 'src/app/services/local-data-controller/local-data-controller.service';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SnackBarService } from 'src/app/services/snack-bar/snack-bar.service';

@Component({
  selector: 'app-my-class-maps-list',
  templateUrl: './my-class-maps-list.component.html',
  styleUrls: ['./my-class-maps-list.component.scss']
})
export class MyClassMapsListComponent implements OnInit {

  public classes: Array<ClassMap> = [];
  public isExecuting: Boolean = true;

  public constructor(
    private _dc: LocalDataControllerService,
    private _snackBar: SnackBarService,
    private _dialogService: DialogService,
    private _router: Router,
    private _route: ActivatedRoute
  ) {
    
  }

  /**
   * Carrega todas as aulas e preenche a lista ao iniciar os componentes
   */
  public ngOnInit(): void {
    // Carrega as aulas
    this.loadClasses();
  }

  /**
   * Carrega todas as aulas e preenche a lista
   */
  private loadClasses(): void {
    this.isExecuting = true;
    this._dc.listAllClassMaps()
      .then((loadedClasses) => {
        this.classes = loadedClasses;
      })
      .catch((error) => {
        this.showSnackBar('Falha no carregamento das aulas: Erro ' + error);
      })
      .finally(() => { this.isExecuting = false; });
  }

  /**
   * Exclui a aula com id dado com confirmação do usuário
   * @param id Id da aula e ser excluída
   */
  public deleteClass(id: string): void {
    this.isExecuting = true;
    // Solicita confiormação do usuário através de um diálogo
    this._dialogService.createQuestion('Deseja excluir a aula selecionada?').subscribe((result) => {
      if (result == true) {
        this._dc.deleteClassMap(id)
        .then(() => {
          this.showSnackBar('Aula excluída com sucesso');
          // Refresh nas aulas da lista
          this.loadClasses();
        })
        .catch((error) => {
          this.showSnackBar('Falha ao excluir a aula: Erro ' + error);
        })
        .finally(() => { this.isExecuting = false; });
      }
    });
  }

  /**
   * Exibe uma snackbar com a string recebida
   * @param msg String a ser exibida
   */
  private showSnackBar(msg: string) {
    this._snackBar.show(msg);
  }

  /**
   * 
   */
  public addClass(): void {
    this._router.navigate(['class-map'],{relativeTo: this._route.parent}); 
  }

  /**
   * Exporta as aulas carregadas gerando um arquivo JSON de saída.
   */
  public exportClasses() : void {
    this.isExecuting = true;
    var link = document.createElement('a');
    link.download = "backup_aulas.json";
    link.href = "data:text/json;charset=utf-8," + JSON.stringify(this.classes);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    this.isExecuting = false;
  }

  /**
   * Importa um arquivo JSON de aulas exportado e recarrega as aulas.
   */
  public importClasses() : void {
    
    var fileInput = document.createElement('input');
    fileInput.type = "file";
    fileInput.accept = ".json";
    fileInput.addEventListener("change", (event:Event)=>{
      this.isExecuting = true;   
      var file = (event.target as HTMLInputElement).files[0];
      var fileReader = new FileReader();
      fileReader.onload = (e) => {      
        var classesJson = (e.target as FileReader).result as string;        
        try{
          var classes = JSON.parse(classesJson);
        }catch(error){
          this.showSnackBar("Arquivo JSON inválido");
          return;
        }
        // TODO: Validar se o JSON carregado realmente contém as aulas
        
        // Salva todas as aulas no DB
        classes.forEach(async (c:ClassMap) => {
          await this._dc.saveClassMap(c).catch((error)=>{this.showSnackBar("Falha ao carregar a aula " + c.id + ":" + error)});
        });
        this.isExecuting = false;
        // Recarrega a lista
        this.loadClasses();
      };
      fileReader.onerror = (e) => {
        this.isExecuting = false;
        this.showSnackBar("Falha no carregamento do arquivo: " + (e.target as FileReader).error);
      };
      fileReader.readAsText(file);
    });
    
    document.body.appendChild(fileInput);    
    fileInput.click();    
    document.body.appendChild(fileInput);
  }


  /**
   * 
   * @param action 
   */
  public onActionSelected(action:string) : void {
    switch(action){
      case 'add':{
        this.addClass();
        break;
      }
      case 'export':{
        this.exportClasses();
        break;
      }    
      case 'import':{
        this.importClasses();
        break;
      }        
    }
  }

}

