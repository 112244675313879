import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';

export const sidenavAnimations = [
  trigger('sidenavWidthTogglerIcon', [
    state('expanded', style({
      transform: 'rotate(0deg)'
    })),
    state('contracted', style({
      transform: 'rotate(180deg)'
    })),
    transition('* <=> *', animate('250ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
  ]),
  trigger('sidenavWidth', [
    state('expanded', style({
      width: '256px'
    })),
    state('contracted', style({
      width: '68px'
    })),
    transition('* <=> *', animate('250ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
  ]) 
];
