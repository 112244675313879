import { Utils } from "../utils/utils.functions";
import { IDataControllable, IPrimitiveObject } from "./common.model";
import { User } from "./user.model";

export class Feedback implements IDataControllable, IPrimitiveObject {
    public uid: string;
    public timestamp: Date;
    public subject:string;
    public comment:string;
    public from:User;
        
    constructor(uid:string) {
        this.uid = uid;
        this.timestamp = new Date();
        this.subject = '';
        this.comment = '';
        this.from = new User('');
    }

    /**
     * 
     */
    public isVirgem() : boolean {
        return this.uid === '';
    }

    //#region Barramento de conversão para o Data Controller
    /**
     * Barramento de conversão entre o modelo de dados e o tipo nativo do DataController
     * OBS: nesse caso é do Firebase. Mas essa função deveria ser genérica
     * OBS: As datas devem ser convertidas do tipo Timestamp para Date
     * @param firebaseData 
     */    
    public static fromDataControllerBus(firebaseDocSnap:any) : Feedback {
        var feedback = new Feedback(firebaseDocSnap.id);
        var firebaseData = (firebaseDocSnap.data) ? firebaseDocSnap.data() :  firebaseDocSnap;
                
        if(firebaseData['timestamp']) feedback.timestamp = (firebaseData['timestamp'] as any).toDate();
        if(firebaseData['subject']) feedback.subject = firebaseData['subject'];
        if(firebaseData['comment']) feedback.comment = firebaseData['comment'];
        if(firebaseData['from']) feedback.from = User.fromDataControllerBus(firebaseData['from']);

        return feedback;
    }

    /**
     * Barramento de conversão entre o modelo de dados e o tipo nativo do DataController
     */
    public toDataControllerBus() : object {
        return Utils.removeUndefinedKeys({
            uid: this.uid,
            timestamp: this.timestamp,
            subject: this.subject,
            comment: this.comment,
            from: this.from.toDataControllerBus()
        });
    } 
    //#endregion     

    //#region Interface IPrimitiveObject
    /**
     * Converte o modelo para o tipo primitivo do javascript
     * As datas são serializadas como ISO String, e por conta
     * disso, convertidas para UTC.
     */
    public toPrimitiveObject(): object {
        return Utils.toJSON(this);
    } 

    /**
     * Converte um objeto do tipo primitivo do javascript serializado
     * para o modelo. 
     * É necessário converter todas as datas manualmente pois elas estão
     * serializadas como ISO Strings
     * @param primitive 
     */
    public static fromPrimitiveObject(p:object) : Feedback {
        var primitive = p as any;
        var feedback = new Feedback(primitive["uid"]);    
        
        if(primitive["started_at"]) feedback.timestamp = new Date(primitive["timestamp"]);
        if(primitive["subject"]) feedback.subject = primitive["subject"];
        if(primitive["comment"]) feedback.comment = primitive["comment"];
        if(primitive["from"]) feedback.from = User.fromPrimitiveObject(primitive["from"]);

        return feedback;
    }
    //#endregion     


    /**
     * Retorna uma cópia do objeto porém com outra referÊncia
     * @param p 
     */
    public getCopy() : Feedback {  
        return Feedback.fromPrimitiveObject(this.toPrimitiveObject());
    }    
}