<div class="full-content-container" [loader]="isExecAsyncRequest">   
    <div class="content-padding" fxLayout="column">
        <!-- INFO HEADER -->
        <div fxLayout="row wrap">

            <div class="card-padding">
                <mat-card class="info-card start-at-card">
                    <mat-card-header>
                        <mat-card-subtitle>Início</mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-content>
                        <span class="mat-display-1">{{(liveStream?.started_at | date:'shortTime') || '--'}}</span>
                    </mat-card-content>
                </mat-card>
            </div>

            <div class="card-padding">
                <mat-card class="info-card elapsed-card">
                    <mat-card-header>
                        <mat-card-subtitle>Decorrido</mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-content>
                        <span class="mat-display-1">{{liveStreamElapsedTime || '--'}}</span>
                    </mat-card-content>
                </mat-card>
            </div> 
            
            <div class="card-padding" fxFlex="1 1 auto">
                <mat-card class="info-card">
                    <mat-card-header>
                        <mat-card-subtitle>Link do stream</mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-content>
                        <span class="mat-display-1">{{liveStream?.url || '--'}}</span>
                    </mat-card-content>
                </mat-card>
            </div>        
        
        </div>

        <div style="padding-top: 16px; padding-bottom: 16px;">
            <mat-divider></mat-divider>
        </div>        

        <!-- PARTICIPANTS CONNECTED -->        

        <div fxLayout="row wrap">
                        
            <div class="card-padding" *ngFor="let participant of participants">
                <mat-card class="info-card">                      
                    <mat-card-header>
                        <div mat-card-avatar style="background-size: cover;" [ngStyle]="{ 'background-image': 'url(' + participant.displayInformation.avatarUrl + ')'}"></div>
                        <mat-card-title>{{participant.personalInformation.name}}</mat-card-title>
                    </mat-card-header>
                </mat-card>
            </div>

        </div>
    </div>
</div>

<app-options-fab
    (onMiniFabClick)="onActionSelected($event)"
    [icon]="'live_tv'"
    [fabButtons]="actionButtons">
</app-options-fab>
