<!--<
<iframe
    #iframe
    width="100%"
    height="100%"
    [src]="url"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
    allowfullscreen>
</iframe>
-->
<!--<div #player id="player" class="full-content-container"></div>-->
<!--<app-videojs-player [options]="videoJsOptions"></app-videojs-player>-->
<div plyr 
    class="full-content-container"
    plyrTitle="Aula ao vivo"
    [plyrSources]="plyrSources"
    [plyrOptions]="plyrOptions"
    (plyrReady)="onPlyrReady()">
</div>