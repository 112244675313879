<div mat-dialog-title>
  <mat-toolbar appSticky>
    <span>Termos de uso - v{{version}}.0</span>
  </mat-toolbar>
</div>

<mat-dialog-content [loader]='isSaving'>  
  <app-terms-of-service></app-terms-of-service>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button 
    mat-button
    [disabled]='isSaving'
    (click)="onDecline()">Não aceito</button>
  <button 
    mat-stroked-button color="primary"
    [disabled]='isSaving'
    (click)="onAccept()"
    cdkFocusInitial>Aceito</button>
</mat-dialog-actions>
