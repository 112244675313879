import { Component, OnInit, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { User, ERole, IUser } from 'src/app/models/user.model';
import { FormGroup, FormBuilder, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { AddressSearchService } from 'src/app/services/address-search/address-search.service';
import { AuthenticationService, EAuthState } from 'src/app/services/authentication/authentication.service';
import { Router } from '@angular/router';
import { OnlineDataControllerService } from 'src/app/services/online-data-controller/online-data-controller.service';
import { ErrorHandlingService } from 'src/app/services/error-handling/error-handling.service';
import { IError } from "src/app/services/error-handling/error-definitions";
import { CustomValidators } from 'src/app/validators/custom.validators';
import { RemoteConfigService } from 'src/app/services/remote-config/remote-config.service';
import { phoneCustomPatterns, states } from 'src/app/utils/utils.consts';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';


interface IRoleInfo {
  name:"Aluno" | "Professor";
  value: ERole;
  hint: string;
  description:string;
}

@Component({
  selector: 'app-registration-form',
  templateUrl: './registration-form.component.html',
  styleUrls: ['./registration-form.component.scss']
})
export class RegistrationFormComponent implements OnInit, AfterViewInit {
  
  // Modelo que seja preenchido pelo form
  private newUser:User;
 
  // Versão atual dos termos de uso
  public termsOfUseVersion:number = 0;

  public phoneCustomPatterns = phoneCustomPatterns;

  // Propriedades do Step 1
  public roleFormGroup: FormGroup;
  public readonly rolesInfo:IRoleInfo[] = [    
    {
      name:"Aluno",
      value:ERole.Client,
      hint:"Bora!",
      description:"Acesso exclusivo as aulas online."
    }/*,{
      name:"Professor",
      value:ERole.Professor,
      hint:"Pedalando pro futuro!",
      description:"Crie seus mapas de aula e faça lives privadas"
    },{
      name:"Gestor de academia",
      value:Role.GymManager,
      hint:"Empreendendo e pedalando!",
      description:"Vincule-se a uma academia para poder criar agendamentos de aulas, escalonar professores e autorizar alunos."
    },{
      name:"Administrador de academia",
      value:Role.GymAdministrator,
      hint:"Empreendendo e pedalando!",
      description:"Crie o cadastro de sua academia e gerencie seus gestores"
    }*/
  ];

  // Propriedades do Step 2
  public personalInfoFormGroup: FormGroup;
  public startDate = new Date(1980,0,1);

  // Propriedades do Step 3
  public contactInfoFormGroup: FormGroup;
  public states = states;
  public hasValidCep:Boolean = false;

  // Propriedades do Step 4
  public termsFormGroup: FormGroup;

  // Controle do loader
  public isExecuting:boolean = false;
  

  /**
   * 
   * @param _formBuilder 
   */
  constructor(
    private _formBuilder: FormBuilder,
    private _authService:AuthenticationService,
    private _dc:OnlineDataControllerService,
    private _rc:RemoteConfigService,
    private _addressService:AddressSearchService,
    private _errorHnd:ErrorHandlingService,
    private _router: Router,
    private _analytics:AnalyticsService
  ) {

  }

  /**
   * 
   */
  public ngOnInit(): void {    
    // Antes de mais nada, garante um estado seguro para a aplicação
    this._authService.secureApplication();

    // Inicializa o usuario vazio para que as referecias dos componentes funcionem
    this.newUser = new User(this._authService.firebaseUser.uid);
    
    // Refente ao Step 1
    this.roleFormGroup = this._formBuilder.group({
      roleControl:                          ['', Validators.required]
    });    

    // Referente ao Step 2
    this.personalInfoFormGroup = this._formBuilder.group({
      personalInfoNameControl:              ['', Validators.required],
      personalInfoCPFControl:               ['', Validators.required, [
                                                CustomValidators.ValidCPF(),
                                                CustomValidators.AvaliableCPF(this._dc)
                                            ]],
      personalInfoBirthdayControl:          ['', Validators.required],      
    });    

    // Referente ao Step 3
    this.contactInfoFormGroup = this._formBuilder.group({
      contactInfoEmailControl:              ['', [Validators.required, Validators.email]],
      contactInfoMobilePhoneControl:        [''],
      contactInfoInstagramControl:          [''],
      contactInfoResidentialPhoneControl:   [''],
      contactInfoAddressCEPControl:         [''],
      contactInfoAddressStreetControl:      [''],
      contactInfoNeighborhoodStreetControl: [''],
      contactInfoAddressNumberControl:      [''],
      contactInfoAddressComplementControl:  [''],
      contactInfoAddressCityControl:        [''],
      contactInfoAddressStateControl:       [''],
    });    
    
    // Referente ao Step 4
    this.termsFormGroup = this._formBuilder.group({
      agreeControl:                         [false, Validators.requiredTrue]//new FormControl(false,Validators.requiredTrue)
    }); 

    // Obtem a versao dos termos de uso
    this._rc.fetchAndActivate().then(()=>{
      this.termsOfUseVersion = this._rc.getTermsOfUseVersion();
    });  
  }


  /**
   * 
   */
  public ngAfterViewInit(): void {
    // Subscreve para verificar o estado da autenticacao.
    // Se mudar para 'RegistrationRequired' então atualiza os campos do objeto
    this._authService.authState.subscribe((state:EAuthState)=>{
      if(state == EAuthState.RegistrationRequired){
        this.updateNewUserInfoFromAuthProvider();
        this._analytics.logEvent('Registration start', {uid:this._authService.firebaseUser.uid});
      }
    });    
  }

  /**
   * 
   * @param roleFormGroupValue 
   */
  public OnSubmitRoleForm(roleFormGroupValue){
    this.newUser.role = roleFormGroupValue.roleControl.value;
    // Os clientes são inicializados como liberados agora
    switch(this.newUser.role){
      case ERole.Client:{
        this.newUser.alowed = true;
      }
    }
  }

  /**
   * 
   * @param personalInfoFormGroupValue 
   */
  public OnSubmitPersonalInfoForm(personalInfoFormGroupValue){
    this.newUser.personalInformation.name = personalInfoFormGroupValue.personalInfoNameControl;
    this.newUser.personalInformation.cpf = personalInfoFormGroupValue.personalInfoCPFControl;
    this.newUser.personalInformation.birthday = personalInfoFormGroupValue.personalInfoBirthdayControl;
    this._analytics.logEvent('Registration personal info', {uid:this._authService.firebaseUser.uid});
  }

  /**
   * 
   * @param contactInfoFormGroupValue 
   */
  public OnSubmitContactInfoForm(contactInfoFormGroupValue){
    this.newUser.contactInformation.instagram = contactInfoFormGroupValue.contactInfoInstagramControl;
    this.newUser.contactInformation.mobilePhone.number = contactInfoFormGroupValue.contactInfoMobilePhoneControl;
    this.newUser.contactInformation.residentialPhone.number = contactInfoFormGroupValue.contactInfoResidentialPhoneControl;
    this.newUser.contactInformation.address.cep = contactInfoFormGroupValue.contactInfoAddressCEPControl;
    this.newUser.contactInformation.address.street = contactInfoFormGroupValue.contactInfoAddressStreetControl;
    this.newUser.contactInformation.address.neighborhood = contactInfoFormGroupValue.contactInfoNeighborhoodStreetControl;
    this.newUser.contactInformation.address.number = contactInfoFormGroupValue.contactInfoAddressNumberControl;
    this.newUser.contactInformation.address.complement = contactInfoFormGroupValue.contactInfoAddressComplementControl;
    this.newUser.contactInformation.address.city = contactInfoFormGroupValue.contactInfoAddressCityControl;
    this.newUser.contactInformation.address.state = contactInfoFormGroupValue.contactInfoAddressStateControl;
    this._analytics.logEvent('Registration contact info', {uid:this._authService.firebaseUser.uid});
  }

  /**
   * 
   * @param cep 
   */
  public fillAddress(cep:string){
    if(cep.length != 9) return;

    this._addressService.get(cep).subscribe((address)=>{
      this.hasValidCep = true;
      this.contactInfoFormGroup.get('contactInfoAddressStreetControl').setValue(address.logradouro);
      this.contactInfoFormGroup.get('contactInfoNeighborhoodStreetControl').setValue(address.bairro);
      this.contactInfoFormGroup.get('contactInfoAddressCityControl').setValue(address.localidade);
      this.contactInfoFormGroup.get('contactInfoAddressStateControl').setValue(address.uf);
    });
  }
  

  /**
   * 
   * @param newUserBasicInfo 
   */
  public updateNewUserInfoFromAuthProvider(): void {
    this.newUser = new User(this._authService.firebaseUser.uid);
    this.newUser.displayInformation.avatarUrl = this._authService.firebaseUser.photoURL;
    this.newUser.contactInformation.email = this._authService.firebaseUser.email;
    // É necessário utilizar o setTimeout para evitar o 'ExpressionChangedAfterItHasBeenCheckedError'
    // que ocorre quando o valor do dom na tela é alterado duas vezes no mesmo frame update.
    // O settimeout sem parâmetro faz a atualização dos valores ocorrer no frame seguinte. 
    // Infelizmente não consegui outra correção
    setTimeout(()=>{
      this.personalInfoFormGroup.get('personalInfoNameControl').setValue(this._authService.firebaseUser.displayName);
      this.contactInfoFormGroup.get('contactInfoEmailControl').setValue(this._authService.firebaseUser.email);   
      this.contactInfoFormGroup.get('contactInfoEmailControl').disable();  
    });        
  }


  /**
   * 
   */
  public register() : void {
    this.isExecuting = true;
    this.newUser.accepted_terms_of_use.version = this.termsOfUseVersion;
    this.newUser.accepted_terms_of_use.at = new Date();

    // Verifica se o engraçadinho está tentando cadastrar algo que não seja um client
    if(this.newUser.role != ERole.Client){
      this.cancelRegistration();
      return;
    }

    // Após salvar com sucesso:
    this._dc.updateUser(this.newUser)
    .then(() => {
      this._analytics.logEvent('Registration complete', {uid:this._authService.firebaseUser.uid});
      this._authService.startAuthAndRegisteredProcedures(this.newUser);
      // Navega para a àrea restrita
      this._router.navigate(['accessAllowed']);
    })
    .catch((error:IError) => {
      this._errorHnd.show(error);
      this._router.navigate(['accessDenied']);
    })
    .finally(()=>{
      this.isExecuting = false;
    });
  }


  /**
   * 
   */
  public cancelRegistration() : void {
    this._authService.logout();
  }

}
