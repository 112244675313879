import { Injectable } from '@angular/core';
import { IPlan } from 'src/app/models/subscription.model';
import { IUser } from 'src/app/models/user.model';
import { FirebaseService } from '../firebase/firebase.service';

export interface ISubscribeToPlanContract {
  plan:IPlan,
  client: IUser,
  gateway_data: any
};

@Injectable({
  providedIn: 'root'
})
export class CloudFunctionsService {
  // Funções
  public subscribeToPlan;
  public cancelSubscription;

  constructor(
    private _firebaseService:FirebaseService
  ) {
    this.subscribeToPlan = this._firebaseService.getFunctions().httpsCallable("onSubscribeToPlan");
    this.cancelSubscription = this._firebaseService.getFunctions().httpsCallable("onCancelSubscription");
  }
}
