<mat-toolbar class="header">
  <button mat-icon-button (click)="onMenuToggle.emit()">
    <mat-icon>menu</mat-icon>
  </button> 

  <span class="fill-remaining-space"></span>

  <button 
    mat-stroked-button    
    *ngIf="_pwa.installationSupported()"
    (click)="_pwa.promptInstallation()">
    <span>Instalar</span>
  </button>  

  <div class="authenticated-commands">
    <img #avatarImgElement 
      [src]="(_authService.loggedUser | async)?.displayInformation.avatarUrl  ||  'assets/gifs/loading.gif' "
      [matMenuTriggerFor]="menu"
      class="avatar">  
    <mat-menu #menu="matMenu">
      <!--Minha conta-->
      <button mat-menu-item
        [routerLink]="['my-account']">
        <mat-icon>account_box</mat-icon>
        <span>Minha conta</span>
      </button>

      <!--Meu plano-->
      <!--OBS: Acessível apenas para Clientes-->
      <button mat-menu-item
        *ngIf="(_authService.loggedUser | async)?.role === ERole.Client"
        [routerLink]="['subscription-management']">
        <mat-icon>loyalty</mat-icon>
        <span>Gerenciar Assinatura</span>
      </button>      

      <mat-divider></mat-divider>

      <!--Termos de uso-->
      <button mat-menu-item
        (click)="onTermsOfServiceClick()">
        <mat-icon>assignment_turned_in</mat-icon>
        <span>Termos de Uso</span>
      </button>  
      <!--Política de privacidade-->
      <button mat-menu-item
        (click)="onPrivacyPolicyClick()">
        <mat-icon>security</mat-icon>
        <span>Políica de Privacidade</span>
      </button>     

      <mat-divider></mat-divider>

      <!--Contato-->
      <button mat-menu-item
        [routerLink]="['feedback']">
        <mat-icon>feedback</mat-icon>
        <span>Deixe seu Feedback</span>
      </button>       

      <mat-divider></mat-divider>      

      <!--Sair-->
      <button mat-menu-item
        (click)="onLogoutButtonClick()">
        <mat-icon>exit_to_app</mat-icon>
        <span>Sair</span>
      </button>      
    </mat-menu>
  </div>
  
</mat-toolbar>
