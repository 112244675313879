import {BrowserModule, HammerGestureConfig, HammerModule, HAMMER_GESTURE_CONFIG} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';
import localePt from '@angular/common/locales/pt';
import localePtExtra from '@angular/common/locales/extra/pt';
import { CurrencyPipe, registerLocaleData } from '@angular/common';

import {AppComponent} from './app.component';
import {ServiceWorkerModule} from '@angular/service-worker';
import {FlexLayoutModule} from '@angular/flex-layout';
import {environment} from '../environments/environment';
import {AppRoutingModule} from './app-routing.module';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { ClassMapEditorComponent } from './components/class-map-editor/class-map-editor.component';
import { AppHeaderComponent } from './components/app-content/app-header/app-header.component';
import { AppBodyComponent } from './components/app-content/app-body/app-body.component';
import { MyClassMapsListComponent } from './components/my-class-maps-list/my-class-maps-list.component';
import { DefaultDialogComponent } from './services/dialog/components/default-dialog/default-dialog.component';
import { ClassMapCardComponent } from './components/my-class-maps-list/class-map-card/class-map-card.component';
import { NgxMaskModule } from 'ngx-mask';
import { NgxLoaderIndicatorModule } from 'ngx-loader-indicator';
import { AppFooterComponent } from './components/app-content/app-footer/app-footer.component';
import { LoaderScreenComponent } from './components/loader-screen/loader-screen.component';
import { OptionsFabComponent } from './components/utils/options-fab/options-fab.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { RegistrationFormComponent } from './components/registration-form/registration-form.component';
import { HttpClientModule } from '@angular/common/http';

import { MatVerticalStepperScrollerDirective } from './directives/mat-vertical-stepper-scroller/mat-vertical-stepper-scroller.directive';
import { AppContentComponent } from './components/app-content/app-content.component';
import { MyAccountComponent } from './components/my-account/my-account.component';
import { TermsOfServiceComponent } from './components/terms-of-service/terms-of-service.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { TermsOfServiceDialogComponent } from './services/dialog/components/terms-of-service-dialog/terms-of-service-dialog.component';
import { PrivacyPolicyDialogComponent } from './services/dialog/components/privacy-policy-dialog/privacy-policy-dialog.component';
import { VideojsPlayerComponent } from './components/stream-player/videojs-player/videojs-player.component';
import { YoutubePlayerComponent } from './components/stream-player/youtube-player/youtube-player.component';
import { LiveClassComponent } from './components/live-class/live-class.component';
import { OpenStreamDialogComponent } from './components/live-class/open-stream-dialog/open-stream-dialog.component';
import { LiveClassesListingComponent } from './components/live-classes-listing/live-classes-listing.component';
import { LiveClassCardComponent } from './components/live-classes-listing/live-class-card/live-class-card.component';
import { LiveClassroomComponent } from './components/live-classroom/live-classroom.component';
import { RecordedClassesListingComponent } from './components/recorded-classes-listing/recorded-classes-listing.component';
import { ProfessorCardComponent } from './components/recorded-classes-listing/professor-card/professor-card.component';
import { RecordedClassroomComponent } from './components/recorded-classroom/recorded-classroom.component';
import { MomentModule } from 'ngx-moment';
import { InstagramPlayerComponent } from './components/stream-player/instagram-player/instagram-player.component';
import { SubscriptionManagementComponent } from './components/subscription-management/subscription-management.component';
import { CreditCardComponent } from './components/utils/credit-card/credit-card.component';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { SubscriptionDialogComponent } from './components/subscription-management/subscription-dialog/subscription-dialog.component';
import { PlyrModule } from 'ngx-plyr';
import { CloseStreamDialogComponent } from './components/live-class/close-stream-dialog/close-stream-dialog.component';
import { CycleConnectPresentationComponent } from './components/cycle-connect-presentation/cycle-connect-presentation.component';
import { FeedbackFormComponent } from './components/feedback-form/feedback-form.component';
import { facebookPixelInitializer, facebookSDKInitializer } from './helpers/app.initializer';
import { FacebookService } from './services/facebook/facebook.service';

registerLocaleData(localePt,'pt',localePtExtra);

export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any> {
    swipe: { direction: Hammer.DIRECTION_ALL },
  };
}

@NgModule({
  declarations: [
    AppComponent,
    ClassMapEditorComponent,
    AppHeaderComponent,
    AppBodyComponent,
    AppFooterComponent,
    MyClassMapsListComponent,
    DefaultDialogComponent,
    TermsOfServiceDialogComponent,
    ClassMapCardComponent,    
    LoaderScreenComponent,
    OptionsFabComponent,
    DashboardComponent,
    RegistrationFormComponent,
    MatVerticalStepperScrollerDirective,
    AppContentComponent,
    MyAccountComponent,
    TermsOfServiceComponent,
    PrivacyPolicyComponent,
    PrivacyPolicyDialogComponent,
    VideojsPlayerComponent,
    YoutubePlayerComponent,
    LiveClassComponent,
    OpenStreamDialogComponent,
    LiveClassesListingComponent,
    LiveClassCardComponent,
    LiveClassroomComponent,
    RecordedClassesListingComponent,
    ProfessorCardComponent,
    RecordedClassroomComponent,
    InstagramPlayerComponent,
    SubscriptionManagementComponent,
    CreditCardComponent,
    SubscriptionDialogComponent,
    CloseStreamDialogComponent,
    CycleConnectPresentationComponent,
    FeedbackFormComponent 
  ],
  entryComponents: [
    DefaultDialogComponent,
    TermsOfServiceDialogComponent,
    PrivacyPolicyDialogComponent,
    OpenStreamDialogComponent,
    SubscriptionDialogComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatSidenavModule,
    MatGridListModule,
    MatListModule,
    MatTooltipModule,
    AppRoutingModule,
    MatSnackBarModule,
    MatInputModule,
    MatDialogModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    FlexLayoutModule,
    MatStepperModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatTabsModule,
    MatListModule,
    MatSlideToggleModule,
    CreditCardDirectivesModule,
    PlyrModule,
    HammerModule,
    NgxMaskModule.forRoot(),
    NgxLoaderIndicatorModule.forRoot({
      img: 'assets/images/loader-spinner.png',
      loaderStyles: {
        background: 'rgba(0, 0, 0, 0.5)',
      },
      imgStyles: {
        width: '100px'
      },
      rotate: {
        duration: 500
      }
    }),
    ServiceWorkerModule.register('/ngsw-worker.js', {enabled: environment.production}),
    ReactiveFormsModule,
    MomentModule
  ],
  providers: [
    CurrencyPipe,
    MatDatepickerModule,
    {provide: LOCALE_ID,        useValue: 'pt-BR' },
    {provide: MAT_DATE_LOCALE,  useValue: 'pt-BR' },  
    {provide: HAMMER_GESTURE_CONFIG,  useClass: HammerGestureConfig},
    {provide: APP_INITIALIZER, useFactory: facebookSDKInitializer,   multi: true},
    {provide: APP_INITIALIZER, useFactory: facebookPixelInitializer, multi: true},    
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
