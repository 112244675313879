import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription, Unsubscribable } from 'rxjs';
import { IStreamDialogResult, LiveStream } from 'src/app/models/live-stream.model';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { ErrorHandlingService } from 'src/app/services/error-handling/error-handling.service';
import { _LIVE_STREAM_DOC } from 'src/app/services/online-data-controller/firebase-dc-service/firebase-consts';
import { OnlineDataControllerService } from 'src/app/services/online-data-controller/online-data-controller.service';
import { SnackBarService } from 'src/app/services/snack-bar/snack-bar.service';
import { IOptionFabButton } from '../utils/options-fab/options-fab.component';
import 'moment/locale/pt-br';
import { Client } from 'src/app/models/client.model';
import { ERole } from 'src/app/models/user.model';
import { Professor } from 'src/app/models/professor.model';

@Component({
  selector: 'app-live-class',
  templateUrl: './live-class.component.html',
  styleUrls: ['./live-class.component.scss']
})
export class LiveClassComponent implements OnInit, OnDestroy, Unsubscribable {
  public liveStream:LiveStream;
  private liveStreamSubscription:Subscription;

  private readonly openStreamButton:IOptionFabButton = {
    icon:   'play_arrow',
    text:   'Abrir stream',
    action: 'open-stream'
  };
  private readonly closeStreamButton:IOptionFabButton = {
    icon:   'stop',
    text:   'Fechar stream',
    action: 'close-stream'
  };  
  public actionButtons = [];

  public isExecAsyncRequest:boolean = false;

  // Professor logado
  private loggedUser:Professor = this._auth.getLoggedUser() as Professor;

  // Tempo decorrido desde que a aula foi aberta
  public get liveStreamElapsedTime() : string {
    if(this.liveStream === undefined) return '--';
    if(!this.liveStream.streaming) return '';
    return this.liveStream.duration.humanize();
  }

  // Clientes conectados à aula
  public participants:Array<Client> = new Array<Client>();
  private participantsSubscription:Subscription;

  constructor(
    private _dc:OnlineDataControllerService,
    private _auth:AuthenticationService,
    private _errorHnd:ErrorHandlingService,
    private _dialog:DialogService,
    private _snackBar:SnackBarService
  ) { 

  }

  /**
   * 
   */
  public ngOnInit() : void {
    this.isExecAsyncRequest = true;
    this._auth.secureApplication().then((isSecure)=>{
      if(this.loggedUser.role !== ERole.Professor){
        this._dialog.createError("Acesso negado").subscribe(()=>{
          this._auth.logout();
        });
      }
      if(isSecure){
        this.liveStreamSubscription = this._dc.observeLiveStream(this.loggedUser.uid).subscribe((liveStreamUpdate)=>{
          this.isExecAsyncRequest = false;
          this.liveStream = liveStreamUpdate;
          if(!this.liveStream || !this.liveStream.streaming){
            this.actionButtons = [this.openStreamButton];
          }else{
            this.actionButtons = [this.closeStreamButton];
            this.observeParticipants();
          }
        });
      }
    });    
  }

  /**
   * 
   */
  public ngOnDestroy(): void {
    this.unsubscribe();
  }

  /**
   * 
   */
  public unsubscribe(): void {
    if(this.liveStreamSubscription) this.liveStreamSubscription.unsubscribe();
    if(this.participantsSubscription) this.participantsSubscription.unsubscribe();
  }

  /**
   * 
   * @param action 
   */
  public onActionSelected(action:string) : void {
    switch(action){
      case 'open-stream':{
        this.openStream();
        break;
      }
      case 'close-stream':{
        this.closeStream();
        break;
      }      
    }
  }

  /**
   * 
   */
  private openStream() : void {
    this._dialog.openStreamDialog().subscribe((result:IStreamDialogResult)=>{
      if(result !== undefined){

        switch(result.source){
          case 'youtube':{
            if((result.url === '') || (result.url.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/)==null)){
              this._snackBar.show("URL inválida");
              return;
            }    
            break;
          }
          case 'instagram':{
            break;
          }
          case 'private':{
            break;
          }
        }

        // TODO: Conferir se é uma url válida no futuro?
        this.isExecAsyncRequest = true;
        this.liveStream.open(result.url, result.source, result.title);
        this._dc.updateLiveStream(this.loggedUser.uid, this.liveStream)
        .then(()=>{
          this._snackBar.show("Aula iniciada com sucesso");
        })
        .catch((error)=>{
          this._errorHnd.show(error);
        });    
      }
    });
  } 

  /**
   * 
   */
  private closeStream() : void {

    this._dialog.createQuestion("Confirma o encerramento do stream?").subscribe((confirm)=>{
      if(confirm){
        this.liveStream.close();
        var classCopy = this.liveStream.getCopy();
        // Copia os participantes que se conectaram à aula
        classCopy.participants = this.participants;
    
        // Para de observar os alunos ?
        // Por enquanto apenas zera a lista local
        // this.participantsSubscription.unsubscribe();
        
        // Atualiza o objeto fixo da aula ao vivo, zerando a transmissão e limpa os participantes
        this.isExecAsyncRequest = true;
        this.liveStream = new LiveStream(_LIVE_STREAM_DOC);
        this._dc.updateLiveStream(this.loggedUser.uid, this.liveStream)
        .then(()=>{
          return this._dc.clearLiveStreamParticipants(this.loggedUser.uid);
        })
        .then(()=>{
          this._dialog.closeStreamDialog(classCopy).subscribe((confirm)=>{
            if(confirm){
              // Cria um novo documento duplicando a aula que foi transmitida
              return this._dc.createLiveStream(this.loggedUser.uid, classCopy);              
            }
          });
        })
        .catch((error)=>{
          this._errorHnd.show(error);
        });
      }
    });
  }  

  /**
   * 
   */
  private observeParticipants() : void {
    this.participantsSubscription = this._dc.observeLiveStreamParticipants(this.loggedUser.uid).subscribe((participant)=>{
      // Exibe os novos alunos que entraram na sala
      participant.forEach((newParticipant)=>{
        if(this.participants.find(element => element.uid == newParticipant.uid) === undefined){
          this._snackBar.show(`${newParticipant.personalInformation.name} entrou na sala!`);
        }
      });
      // Atualiza a referência
      this.participants = participant;
    });
  }
}
