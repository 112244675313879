<div mat-dialog-title>
    <mat-toolbar appSticky>
        <button
            mat-icon-button
            matTooltip="Voltar"
            [mat-dialog-close]="false">
            <mat-icon>arrow_back_ios</mat-icon>
        </button> 

      <span>Revisão e pagamento</span>
    </mat-toolbar>
</div>

<mat-dialog-content>

    <!--Componentes do formulário de pagamento-->
    <mat-vertical-stepper appMatVerticalStepperScroller linear="true" #stepper>

        <!--Step 1: Confirmação do plano-->
        <mat-step>            
            <ng-template matStepLabel>Confirme o plano escolhido</ng-template>
            <div fxLayout="column">
                <!--Plano-->
                <div>                    
                    <span>{{data.plan.name}} - {{data.plan.value | currency:'BRL'}}</span>
                </div>
                <!--Botoes-->
                <div fxLayout="row" style="padding-top: 16px;">
                    <span fxFlex="1 1 auto"></span>
                    <button 
                        mat-flat-button 
                        color="primary" 
                        matStepperNext>
                        Confirmar Plano
                    </button>                    
                </div>
            </div>   
        </mat-step>

        <!--Step 2: Dados do pagador-->
        <mat-step [stepControl]="personalInfoFormGroup">
            <form [formGroup]="personalInfoFormGroup" (ngSubmit)="onSubmitPersonalInfoForm(personalInfoFormGroup.value)">
                <ng-template matStepLabel>Dados do pagador</ng-template>
                <div fxLayout="column">
                    <!--Form-->
                    <div fxLayout="row wrap">
                        <!--Nome-->                                              
                        <mat-form-field>
                            <input 
                                #nameInput
                                matInput 
                                required
                                placeholder="Nome completo" 
                                maxlength="100"                                
                                formControlName="personalInfoNameControl">
                            <mat-error *ngIf="personalInfoFormGroup.get('personalInfoNameControl').hasError('required')">O nome é obrigatório para processamento do pagamento</mat-error>
                            <mat-hint align="end">{{nameInput.value.length}} / 100</mat-hint>
                        </mat-form-field>
    
                        <!--CPF-->
                        <mat-form-field>
                            <input 
                                #cpfInput
                                matInput 
                                required
                                placeholder="000.000.000-00"
                                maxlength="14"
                                mask="000.000.000-00"                                 
                                formControlName="personalInfoCPFControl">
                            <mat-label>CPF</mat-label>
                            <mat-hint *ngIf="personalInfoFormGroup.get('personalInfoCPFControl').status === 'PENDING'">
                                Verificando CPF...
                            </mat-hint>
                            <mat-error *ngIf="personalInfoFormGroup.get('personalInfoCPFControl').hasError('required')">O cpf é obrigatório para processamento do pagamento</mat-error>
                            <mat-error *ngIf="personalInfoFormGroup.get('personalInfoCPFControl').errors?.invalidCPF">Por favor insira um CPF válido</mat-error>                            
                        </mat-form-field>
    
                        <!--Aniversário-->
                        <mat-form-field>
                            <input 
                                matInput 
                                required
                                formControlName="personalInfoBirthdayControl"
                                [matDatepicker]="birthdayDatePicker"
                                placeholder="Escolha a data"
                                readonly
                                >
                            <mat-datepicker-toggle 
                                matSuffix [for]="birthdayDatePicker">
                            </mat-datepicker-toggle>
                            <mat-datepicker 
                                #birthdayDatePicker
                                touchUi
                                startView="multi-year"
                                [startAt]="startDate"
                                disabled="false">
                            </mat-datepicker>
                            <mat-label>Aniversário</mat-label>
                            <mat-error *ngIf="personalInfoFormGroup.get('personalInfoBirthdayControl').hasError('required')">O aniversário é obrigatório para processamento do pagamento</mat-error>                            
                        </mat-form-field>
                    </div>
                    <!--Botoes-->
                    <div fxLayout="row" style="padding-top: 16px;">
                        <span fxFlex="1 1 auto"></span>
                        <button 
                            mat-stroked-button 
                            color="accent" 
                            matStepperPrevious>Voltar</button>
                        <button 
                            mat-flat-button 
                            color="primary" 
                            type="submit"
                            style="margin-left: 16px;"
                            matStepperNext>Próximo</button>
                    </div>
                </div>
            </form>
        </mat-step>

        <!--Step 3: Dados de cobrança-->
        <mat-step [stepControl]="contactInfoFormGroup">
            <form [formGroup]="contactInfoFormGroup" (ngSubmit)="onSubmitContactInfoForm(contactInfoFormGroup.value)">
            <ng-template matStepLabel>Dados de cobrança</ng-template>
            <div fxLayout="column">
                <!--Form-->
                <div fxLayout="row wrap">
                    <!--E-mail-->                    
                    <mat-form-field>
                        <input 
                            #emailInput
                            matInput 
                            required                                
                            type="email"
                            placeholder="E-mail" 
                            maxlength="100"
                            formControlName="contactInfoEmailControl">
                        <mat-error *ngIf="contactInfoFormGroup.get('contactInfoEmailControl').hasError('email') && !contactInfoFormGroup.get('contactInfoEmailControl').hasError('required')">É necessário um e-mail válido</mat-error>
                        <mat-error *ngIf="contactInfoFormGroup.get('contactInfoEmailControl').hasError('required')">O e-mail é obrigatório para o processamento do pagamento</mat-error>
                        <mat-hint align="end">{{emailInput.value.length}} / 100</mat-hint>
                    </mat-form-field>  
                    
                    <!--Celular-->
                    <mat-form-field>
                        <input 
                            matInput
                            required
                            type="text"
                            placeholder="(00) 0 0000-0000"
                            mask="(90) 9 9000-0000"
                            [patterns]="phoneCustomPatterns"
                            formControlName="contactInfoMobilePhoneControl">
                        <mat-label>Telefone Celular</mat-label>
                        <mat-icon matSuffix color="primary">smartphone</mat-icon>
                        <mat-error *ngIf="contactInfoFormGroup.get('contactInfoMobilePhoneControl').hasError('required')">O celular é obrigatório para o processamento do pagamento</mat-error>
                    </mat-form-field> 

                    <mat-divider></mat-divider>

                    <!--Endereço completo-->
                    <!--CEP-->
                    <mat-form-field>
                        <input 
                            #cepInput
                            matInput
                            required
                            type="text"
                            placeholder="00000-000" 
                            mask="00000-000"
                            formControlName="contactInfoAddressCEPControl"
                            (input)="fillAddress(cepInput.value)">
                        <mat-label>CEP</mat-label>
                        <mat-error *ngIf="contactInfoFormGroup.get('contactInfoAddressCEPControl').hasError('required')">O CEP é obrigatório para o processamento do pagamento</mat-error>
                    </mat-form-field> 
                    
                    <!--Logradouro-->
                    <mat-form-field>
                        <input 
                            #streetInput
                            matInput
                            required
                            type="text"
                            placeholder="Rua" 
                            maxlength="100"
                            formControlName="contactInfoAddressStreetControl">
                        <mat-label>Logradouro</mat-label>
                        <mat-error *ngIf="contactInfoFormGroup.get('contactInfoAddressStreetControl').hasError('required')">O logradouro é obrigatório para o processamento do pagamento</mat-error>
                        <mat-hint align="end">{{streetInput.value.length}} / 100</mat-hint>
                    </mat-form-field>

                    <!--Bairro-->
                    <mat-form-field>
                        <input 
                            #neighborhoodInput
                            matInput     
                            required                            
                            type="text"
                            placeholder="Bairro" 
                            maxlength="50"
                            formControlName="contactInfoNeighborhoodStreetControl">
                        <mat-label>Bairro</mat-label>
                        <mat-error *ngIf="contactInfoFormGroup.get('contactInfoNeighborhoodStreetControl').hasError('required')">O bairro é obrigatório para o processamento do pagamento</mat-error>
                        <mat-hint align="end">{{neighborhoodInput.value.length}} / 50</mat-hint>
                    </mat-form-field>
                    
                    <!--Numero-->
                    <mat-form-field>
                        <input 
                            matInput      
                            required                           
                            type="number"
                            placeholder="Número" 
                            maxlength="5"
                            formControlName="contactInfoAddressNumberControl">
                        <mat-label>Número</mat-label>
                        <mat-error *ngIf="contactInfoFormGroup.get('contactInfoAddressNumberControl').hasError('required')">O número é obrigatório para o processamento do pagamento</mat-error>
                    </mat-form-field>                 

                    <!--Complemento-->
                    <mat-form-field>
                        <input 
                            #complementInput
                            matInput                                 
                            type="text"
                            placeholder="Complemento" 
                            maxlength="100"
                            formControlName="contactInfoAddressComplementControl">
                        <mat-label>Complemento</mat-label>
                        <mat-hint align="end">{{complementInput.value.length}} / 100</mat-hint>
                    </mat-form-field> 
                    
                    <!--Estado-->
                    <mat-form-field>                                
                        <mat-select formControlName="contactInfoAddressStateControl">
                            <mat-option *ngFor="let state of states" [value]="state.sigla">{{state.nome}}</mat-option>
                        </mat-select>
                        <mat-label>UF</mat-label>
                        <mat-error *ngIf="contactInfoFormGroup.get('contactInfoAddressStateControl').hasError('required')">O estado é obrigatório para o processamento do pagamento</mat-error>
                    </mat-form-field>

                    <!--Cidade-->
                    <mat-form-field>
                        <input 
                            #cityInput
                            matInput                                 
                            type="text"
                            placeholder="Cidade" 
                            maxlength="50"
                            formControlName="contactInfoAddressCityControl">
                        <mat-label>Cidade</mat-label>
                        <mat-error *ngIf="contactInfoFormGroup.get('contactInfoAddressCityControl').hasError('required')">A cidade é obrigatória para o processamento do pagamento</mat-error>
                        <mat-hint align="end">{{cityInput.value.length}} / 50</mat-hint>
                    </mat-form-field>                   
                                        
                </div>
                <!--Botoes-->
                <div fxLayout="row" style="padding-top: 16px;">
                    <span fxFlex="1 1 auto"></span>
                    <button 
                        mat-stroked-button 
                        color="accent" 
                        matStepperPrevious>Voltar</button>
                    <button 
                        mat-flat-button 
                        color="primary" 
                        type="submit"
                        style="margin-left: 16px;"
                        matStepperNext>Próximo</button>
                </div>
            </div>
            </form>                
        </mat-step>

        <!--Step 4: Dados de pagamento-->
        <mat-step>
            <ng-template matStepLabel>Dados de pagamento</ng-template>
            <div fxLayout="column">
                <!--Cartão de crédito-->
                <app-credit-card #cc style="margin-left: -20px;" (onValidCard)="onSubmitCreditCardForm($event)"></app-credit-card>
            </div>
        </mat-step>

    </mat-vertical-stepper>

</mat-dialog-content>

<mat-dialog-actions align="end">
    <button 
      mat-button
      [mat-dialog-close]="false">Cancelar</button>

    <button 
      mat-stroked-button color="primary"
      [disabled]="!personalInfoFormGroup.valid || !contactInfoFormGroup.valid || !cc.form.valid"
      [mat-dialog-close]="{
          cc:creditCard,
          payer:payer.toPrimitiveObject()
        }"
    >Confirmar assinatura</button>
  </mat-dialog-actions>