// Definições dos nomes das collections e documents no Firebase Firestore
  // Coleções / Entidades
  export const _USERS_COLLECTION = "users";
  export const _PROFESSORS_COLLECTION = "professors";
  export const _CLIENTS_COLLECTION = "clients";
  export const _FEEDBACKS_COLLECTION = "feedbacks";
  export const _STREAMS_COLLECTION = "streams";
  export const _PARTICIPANTS_COLLECTION = "participants";
  export const _SUBSCRIPTIONS_COLLECTION = "subscriptions";

  // Documentos fixos
  export const _LIVE_STREAM_DOC = "live";
  export const _ACTIVE_SUBSCRIPTION_DOC = "active";