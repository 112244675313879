// Estados brasileiros
export const states = [{
    ID: "1",
    sigla: "AC",
    nome: "Acre"
  },
       {
    ID: "2",
    sigla: "AL",
    nome: "Alagoas"
  },
       {
    ID: "3",
    sigla: "AM",
    nome: "Amazonas"
  },
       {
    ID: "4",
    sigla: "AP",
    nome: "Amapá"
  },
       {
    ID: "5",
    sigla: "BA",
    nome: "Bahia"
  },
       {
    ID: "6",
    sigla: "CE",
    nome: "Ceará"
  },
       {
    ID: "7",
    sigla: "DF",
    nome: "Distrito Federal"
  },
       {
    ID: "8",
    sigla: "ES",
    nome: "Espírito Santo"
  },
       {
    ID: "9",
    sigla: "GO",
    nome: "Goiás"
  },
       {
    ID: "10",
    sigla: "MA",
    nome: "Maranhão"
  },
       {
    ID: "11",
    sigla: "MG",
    nome: "Minas Gerais"
  },
       {
    ID: "12",
    sigla: "MS",
    nome: "Mato Grosso do Sul"
  },
       {
    ID: "13",
    sigla: "MT",
    nome: "Mato Grosso"
  },
       {
    ID: "14",
    sigla: "PA",
    nome: "Pará"
  },
       {
    ID: "15",
    sigla: "PB",
    nome: "Paraíba"
  },
       {
    ID: "16",
    sigla: "PE",
    nome: "Pernambuco"
  },
       {
    ID: "17",
    sigla: "PI",
    nome: "Piauí"
  },
       {
    ID: "18",
    sigla: "PR",
    nome: "Paraná"
  },
       {
    ID: "19",
    sigla: "RJ",
    nome: "Rio de Janeiro"
  },
       {
    ID: "20",
    sigla: "RN",
    nome: "Rio Grande do Norte"
  },
       {
    ID: "21",
    sigla: "RO",
    nome: "Rondônia"
  },
       {
    ID: "22",
    sigla: "RR",
    nome: "Roraima"
  },
       {
    ID: "23",
    sigla: "RS",
    nome: "Rio Grande do Sul"
  },
       {
    ID: "24",
    sigla: "SC",
    nome: "Santa Catarina"
  },
       {
    ID: "25",
    sigla: "SE",
    nome: "Sergipe"
  },
       {
    ID: "26",
    sigla: "SP",
    nome: "São Paulo"
  },
       {
    ID: "27",
    sigla: "TO",
    nome: "Tocantins"
}]; 

export const phoneCustomPatterns = { 
  '9': { pattern: new RegExp('\[1-9\]')},
  '0':  {pattern: new RegExp('\[0-9\]')}
};