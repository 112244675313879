import moment from 'moment';
import { Duration } from 'moment';
import { Errors } from '../services/error-handling/error-definitions';
import { Utils } from '../utils/utils.functions';
import { Client } from './client.model';
import { IDataControllable, IPrimitiveObject } from './common.model';

export type TLiveStreamSource = 'youtube' | 'instagram' | 'private';
export interface IStreamDialogResult {
    source:TLiveStreamSource;
    url:string;
    title:string;
}

export class LiveStream implements IDataControllable, IPrimitiveObject {

    public uid: string;
    public source:TLiveStreamSource;
    public url:string;

    public started_at?:Date;
    public closed_at?:Date;
        
    public streaming: boolean;

    public title?:string;

    // Se a aula é publica 
    public public?:boolean;

    // Participantes de uma aula que foi feita. 
    // Não contém os particpantes em tempo real!
    // Sua obtentenção deve ser observada
    public participants?: Array<Client>;

    constructor(uid:string) {
        this.uid = uid;
        this.url = '';
        this.streaming = false;
        this.source = 'youtube';
        this.title = '--';
        this.public = false;
    }        

    /**
     * 
     */
    public get duration(): Duration | undefined {
        if(this.started_at === undefined) return undefined;
        var start = moment(this.started_at);
        var end = (this.closed_at === undefined) ? moment() : moment(this.closed_at);
        return moment.duration(start.diff(end));
    }

    /**
     * 
     */
    public get youtubeId(): string {
        if(this.source !== 'youtube') return '';
        var evaluated = this.url.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/);
        if(evaluated == null) throw Errors.InvalidStreamUrl;
        return evaluated[1];
    }

    /**
     * 
     */
    public get thumbnail_url(): string {
        switch(this.source){
            case 'youtube':{
              var yti = this.url.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/);
              return "https://i3.ytimg.com/vi/" + yti[1] + "/hqdefault.jpg";        
            }
            case 'instagram':{
              return 'assets/images/logo.png';
            }
            case 'private':{
              return '';
            }  
        }        
    }

    /**
     * 
     */
    public open(url:string, source:TLiveStreamSource, title:string) : void {
        this.started_at = new Date();
        this.streaming = true;
        this.url = url;
        this.source = source;
        this.title = title;
    }

    /**
     * 
     */
    public close() : void {
        this.closed_at = new Date();
        this.streaming = false;
    }


    //#region Barramento de conversão para o Data Controller
    /**
     * Barramento de conversão entre o modelo de dados e o tipo nativo do DataController
     * OBS: nesse caso é do Firebase. Mas essa função deveria ser genérica
     * OBS: As datas devem ser convertidas do tipo Timestamp para Date
     * @param firebaseData 
     */    
    public static fromDataControllerBus(firebaseDocSnap:any) : LiveStream {
        var liveClass = new LiveStream(firebaseDocSnap.id);
        var firebaseData = (firebaseDocSnap.data) ? firebaseDocSnap.data() :  firebaseDocSnap;
        
        if(firebaseData['streaming']) liveClass.streaming = firebaseData['streaming'];
        if(firebaseData['url']) liveClass.url = firebaseData['url'];
        if(firebaseData['source']) liveClass.source = firebaseData['source'];
        if(firebaseData['started_at']) liveClass.started_at = (firebaseData['started_at'] as any).toDate();
        if(firebaseData['closed_at']) liveClass.closed_at = (firebaseData['closed_at'] as any).toDate();

        if(firebaseData['title']) liveClass.title = firebaseData['title'];
        if(firebaseData['public']) liveClass.public = firebaseData['public'];

        if(firebaseData['participants']){
            liveClass.participants = new Array<Client>();
            for(let participant of firebaseData['participants']){
                liveClass.participants.push(Client.fromDataControllerBus(participant));
            }
        }

        return liveClass;
    }

    /**
     * Barramento de conversão entre o modelo de dados e o tipo nativo do DataController
     */
    public toDataControllerBus() : object {
        var participantsObj = [];
        if(this.participants){
            for(let participant of this.participants){
                participantsObj.push(participant.toDataControllerBus());
            }            
        }
        return Utils.removeUndefinedKeys({
            uid: this.uid,
            url: this.url,
            title: this.title,
            public: this.public,
            source: this.source,
            started_at: this.started_at,
            closed_at: this.closed_at,
            streaming: this.streaming,
            participants: participantsObj
        });
    } 
    //#endregion 
    
    

    //#region Interface IPrimitiveObject
    /**
     * Converte o modelo para o tipo primitivo do javascript
     * As datas são serializadas como ISO String, e por conta
     * disso, convertidas para UTC.
     */
    public toPrimitiveObject(): object {
        return Utils.toJSON(this);
    } 

    /**
     * Converte um objeto do tipo primitivo do javascript serializado
     * para o modelo. 
     * É necessário converter todas as datas manualmente pois elas estão
     * serializadas como ISO Strings
     * @param primitive 
     */
    public static fromPrimitiveObject(p:object) : LiveStream {
        var primitive = p as any;
        var liveClass = new LiveStream(primitive["uid"]);    
        
        liveClass.url = primitive["url"];
        liveClass.title = primitive["title"];
        liveClass.public = primitive["public"];
        liveClass.source = primitive["source"];
        liveClass.streaming = primitive["streaming"];
        if(primitive["started_at"]) liveClass.started_at = new Date(primitive["started_at"]);
        if(primitive["closed_at"]) liveClass.closed_at = new Date(primitive["closed_at"]);

        /// TODO: Necessário implementar a interface de IPrimitiveObjects no usuario
        /// para conseguir realizar a conversão corretamente
        /*
        if(primitive["participants"]){
            liveClass.participants = new Array<Client>();
            for(let participant of primitive["participants"]){
                liveClass.participants.push(Client.fromPrimitiveObject(participant));
            }
        } 
        */       

        return liveClass;
    }
    //#endregion     


    /**
     * Retorna uma cópia do objeto porém com outra referÊncia
     * @param p 
     */
    public getCopy() : LiveStream {  
        return LiveStream.fromPrimitiveObject(this.toPrimitiveObject());
    }    
}