import { Injectable } from '@angular/core';
import { IOnlineDataControllerService } from './online-data-controller.interface';
import { FirebaseDcServiceService } from './firebase-dc-service/firebase-dc-service.service';
// Models
import { ERole, User } from '../../models/user.model';
import { Professor } from 'src/app/models/professor.model';
import { Observable } from 'rxjs';
import { Client } from 'src/app/models/client.model';
import { LiveStream } from 'src/app/models/live-stream.model';
import { SubscriptionPlan } from 'src/app/models/subscription.model';
import { Feedback } from 'src/app/models/feedback.model';

@Injectable({
  providedIn: 'root'
})
export class OnlineDataControllerService implements IOnlineDataControllerService {
  
  public constructor(
    private _dc: FirebaseDcServiceService
  ) { 

  }

  //#region USUÁRIOS
  /**
   * Busca um usuário dado seu uid
   * @param uid Id do usuário a ser buscado
   */
  public async getUser(uid: string): Promise<User> {
    return this._dc.getUser(uid);
  }

  /**
   * Observa alterações em tempo real em um usuário Cliente ou Professor
   * @param uid Id do usuário a ser buscado 
   * @param role Papel do usuário
   */
  public observeUser(uid: string, role:ERole): Observable<User> {
    return this._dc.observeUser(uid, role);
  }  

  /**
   * Busca um usuário dado seu cpf
   * @param uid Id do usuário a ser buscado
   */
  public async getUserByCPF(cpf: string): Promise<User> {
    return this._dc.getUserByCPF(cpf);
  }
  /**
   * Salva um usuário no banco de dados (criando ou atualizando)
   * @param user Usuário a ser salvo
   */
  public async updateUser(user: User): Promise<void> {
    return this._dc.updateUser(user);
  }
  //#endregion

  //#region PROFESSORES
  /**
   * 
   * @param uid 
   */
  public async getProfessor(uid: string): Promise<Professor> {
    return this._dc.getProfessor(uid);
  }

  /**
   * 
   */
  public async getAllProfessors() : Promise<Array<Professor>> {
    return this._dc.getAllProfessors();
  }

  /**
   * 
   * @param uid 
   */
  public async getProfessorByCPF(cpf: string): Promise<Client> {
    return this._dc.getProfessorByCPF(cpf);
  }    

  /**
   * 
   * @param uid 
   */
  public observeProfessor(uid: string): Observable<Professor> {
    return this._dc.observeProfessor(uid);
  }
  
  /**
   * 
   * @param uid 
   */
  public async updateProfessor(p: Professor): Promise<void> {
    return this._dc.updateProfessor(p);
  }  
  
  /**
   * 
   * @param uid 
   */
  public async createProfessor(p: Professor): Promise<Professor> {
    return this._dc.createProfessor(p);
  }

  /**
   * 
   * @param p 
   * @param from 
   * @param to 
   */
  public async getProfessorRecordedStreams(professor_uid: string, from:Date, to:Date) : Promise<Array<LiveStream>> {
    return this._dc.getProfessorRecordedStreams(professor_uid,from,to);
  }
  //#endregion   

  //#region CLIENTES
  /**
   * 
   * @param uid 
   */
  public async getClient(uid: string): Promise<Client> {
    return this._dc.getClient(uid);
  }

  /**
   * 
   * @param uid 
   */
  public async getClientByCPF(cpf: string): Promise<Client> {
    return this._dc.getClientByCPF(cpf);
  }  

  /**
   * 
   * @param uid 
   */
  public observeClient(uid: string): Observable<Client> {
    return this._dc.observeClient(uid);
  }
  
  /**
   * 
   * @param uid 
   */
  public async updateClient(c: Client): Promise<void> {
    return this._dc.updateClient(c);
  }  
  
  /**
   * 
   * @param uid 
   */
  public async createClient(c: Client): Promise<Client> {
    return this._dc.createClient(c);
  }

  /**
   * 
   * @param c 
   */
  public observeClientActiveSubscriptionPlan(c: Client): Observable<SubscriptionPlan> {
    return this._dc.observeClientActiveSubscriptionPlan(c);
  }
  //#endregion   

  //#region AULA AO VIVO
  /**
   * 
   * @param uid 
   */
  public observeLiveStream(professor_uid: string): Observable<LiveStream> {
    return this._dc.observeLiveStream(professor_uid);
  }  

  /**
   * 
   * @param professor_uid 
   */
  public async getLiveStream(professor_uid: string): Promise<LiveStream> {
    return this._dc.getLiveStream(professor_uid);
  }

  /**
   * 
   * @param professor_uid 
   * @param liveStream 
   */
  public async updateLiveStream(professor_uid: string, liveStream:LiveStream) : Promise<void> {
    return this._dc.updateLiveStream(professor_uid, liveStream);
  }

  /**
   * 
   * @param professor_uid 
   * @param liveClass 
   */
  public async createLiveStream(professor_uid: string, liveStream:LiveStream) : Promise<LiveStream> {
    return this._dc.createLiveStream(professor_uid, liveStream);
  }

  /**
   * 
   * @param professor_uid 
   */
  public observeLiveStreamParticipants(professor_uid: string) : Observable<Array<Client>> {
    return this._dc.observeLiveStreamParticipants(professor_uid);
  }

  /**
   * 
   * @param professor_uid 
   */
  public clearLiveStreamParticipants(professor_uid: string) : Promise<void> {
    return this._dc.clearLiveStreamParticipants(professor_uid);
  }

  /**
   * 
   * @param professor_uid 
   * @param liveClass 
   */
  public async joinLiveStream(professor_uid: string, client:Client) : Promise<void> {
    return this._dc.joinLiveStream(professor_uid, client);
  }  
  //#endregion


  //#region FEEDBACKS
  /**
   * 
   * @param uid 
   */
   public async createFeedback(f: Feedback): Promise<Feedback> {
    return this._dc.createFeedback(f);
  }
  //#endregion
}
