import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  constructor(private _snackBar:MatSnackBar) { }

  public show(msg: string) {
    this._snackBar.open(msg, null, {
      duration: 5000
    });
  }
}
