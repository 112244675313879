import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ILiveClasse } from '../live-classes-listing.component';

@Component({
  selector: 'app-live-class-card',
  templateUrl: './live-class-card.component.html',
  styleUrls: ['./live-class-card.component.scss']
})
export class LiveClassCardComponent {
  @Input() liveClass: ILiveClasse;

  constructor() { }

  public get thumbnail_url() : string {
    return this.liveClass.streamRef.thumbnail_url;
  }

}
