<div fxLayout="column" class="full-content-container content-padding" [loader]="isExecAsyncRequest">
    <span class="mat-subheading-1">
        Escolha o professor
    </span>

    <!-- Cards dos professores -->
    <div class="professors-cards" fxLayout="row warp">
        <app-professor-card *ngFor="let professor of professors" (click)="loadProfessorClasses(professor.uid)" [professor]="professor"></app-professor-card>
    </div>

    <div class="divider">
        <mat-divider></mat-divider>
    </div>

    <!-- Lista de aulas -->
    <div class="class-listing">
        <mat-action-list>
            <mat-list-item *ngFor="let recordedStream of recordedStreams" (click)="openRecordedStream(recordedStream)">
                
                <div class="thumbnail">
                    <img [src]="recordedStream.thumbnail_url">
                </div>

                <div fxLayout="column">
                    <div>
                        <ng-container *ngIf="(recordedStream.title != undefined) && (recordedStream.title != ''); else noTitle">
                            <span>{{recordedStream.title}}</span>
                        </ng-container>
                        <ng-template #noTitle>
                            <span>--</span>
                        </ng-template>                        
                    </div>

                    <div>
                        <span class="mat-small">{{recordedStream.started_at! | date:'HH:mm , dd/MM/yyyy'}}</span>
                    </div>
                </div>                                     
            
            </mat-list-item>
        </mat-action-list>
    </div>
</div>