import { Component, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { SnackBarService } from 'src/app/services/snack-bar/snack-bar.service';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { ProgressiveWebAppService } from 'src/app/services/progressive-web-app/progressive-web-app.service';
import { ERole } from 'src/app/models/user.model';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent {
  @ViewChild('avatarImgElement') avatar:ElementRef;
  @Output() onMenuToggle = new EventEmitter<void>();

  public ERole = ERole;

  public constructor(
    public _authService:AuthenticationService,
    private _snackBar:SnackBarService,
    private _dialogService:DialogService,
    public  _pwa: ProgressiveWebAppService
  ){ 
    
  }

  /**
   * 
   */
  public onLogoutButtonClick(): void {
    this._authService.logout()
    .then(()=>{
      this._snackBar.show("Logout com sucesso");      
    })
    .catch((error)=>{
      this._snackBar.show("Falha no logout: " + error.message);
    });
  }  

  /**
   * 
   */
  public onTermsOfServiceClick(): void {
    this._dialogService.createTermsOfService();
  }  

  /**
   * 
   */
  public onPrivacyPolicyClick(): void {
    this._dialogService.createPrivacyPolicy();
  }    

  /**
   * 
   */
  public onContactClick(): void {
    window.open("mailto:cycleconnectbrasil@gmail.com", "_blank");
  }
}
