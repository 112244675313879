import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, Unsubscribable } from 'rxjs';
import { SnackBarService } from 'src/app/services/snack-bar/snack-bar.service';

@Component({
  selector: 'app-recorded-classroom',
  templateUrl: './recorded-classroom.component.html',
  styleUrls: ['./recorded-classroom.component.scss']
})
export class RecordedClassroomComponent implements OnInit, OnDestroy, Unsubscribable {
  private routeSubscription: Subscription;
  public video_id:string;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _snackBar: SnackBarService,    
  ) { }

  /**
   * 
   */
  public ngOnInit() : void {
    this.routeSubscription = this._route.paramMap.subscribe((params)=>{
      this.video_id = params.get('video_id');
      if(!this.video_id){
        this._snackBar.show("Parâmetros de aula inválidos");
        this._router.navigate(['/accessAllowed/recorded-classes-list']);
        return;
      }            
    });    
  }

  /**
   * 
   */
  public ngOnDestroy(): void {
    this.unsubscribe();
  }
  
  /**
   * 
   */
  public unsubscribe(): void {
    if(this.routeSubscription) this.routeSubscription.unsubscribe();
  }  

}
