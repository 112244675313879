import { environment } from "src/environments/environment"
import { IPlan } from "./subscription.model"

// Planos comercializados
export const _PLANO_MENSAL:IPlan = {
    name: "Cycle Connect - Mensal",
    periodicity: "Mensal",
    value: 69.90,
    plan_id: environment.production ? 60238 : 7227
}
export const _PLANO_TRIMESTRAL:IPlan = {
    name: "Cycle Connect - Trimestral",
    periodicity: "Trimestral",
    value: 179.70,
    plan_id: environment.production ? 60237 : 7228
}