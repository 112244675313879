<div class="full-content-container" [loader]='isExecuting'>
  <!--Quando termina de carregar:-->
  <!--TODO (p/ Gabriel): Tratar aqui falha no carregamento, colocando um botão para tentar novamente talvez?-->
  
      <!--Exibe a lista de aulas caso haja alguma-->
      <div fxLayout="row wrap" class="card-deck-container" *ngIf="classes.length > 0 else noClassesRecorded">
        <div *ngFor="let class of classes">
          <app-class-map-card [class]="class" (deleteRequest)="deleteClass($event)"></app-class-map-card>
        </div>
      </div>
      <ng-template #noClassesRecorded>
        <div class="full-content-container empty-classes" fxLayout="column" fxLayoutAlign="center center">
          <mat-icon color="primary">cancel</mat-icon>
          <span>Nenhuma aula ainda</span>
        </div>
      </ng-template>

    <app-options-fab
      (onMiniFabClick)="onActionSelected($event)"
      [icon]="'add'"
      [fabButtons]="[
        {
          icon:   'add',
          text:   'Nova Aula',
          action: 'add'
        },
        {
          icon:   'arrow_downward',
          text:   'Importar Aulas',
          action: 'import'
        },{
          icon:   'save',
          text:   'Exportar Aulas',
          action: 'export'
        }
      ]"
    ></app-options-fab>
  
</div>


