<div>
    <div mat-dialog-title>
      <mat-toolbar appSticky>
        <button 
            mat-icon-button
            matTooltip="Voltar"
            [mat-dialog-close]="undefined">
            <mat-icon>arrow_back_ios</mat-icon>
        </button>    
        <span>Abrir stream</span>
        <span class="fill-remaining-space"></span>
      </mat-toolbar>
    </div>    
  
    <div mat-dialog-content>
                
        <div style="padding: 16px 0 16px 0;">
            <!-- Título da aula -->
            <mat-form-field style="width: 100%;">
                <input          
                    #titleInput
                    cdkFocusInitial
                    matInput
                    maxlength="250"
                    placeholder="Insira o título da aula"
                    [(ngModel)]="result.title">
                <mat-label>Título</mat-label>                
                <mat-hint align="end">{{titleInput.value.length}} / 250</mat-hint>
            </mat-form-field> 

            <!-- Fonte do stream -->
            <mat-form-field style="width: 100%;">                                
                <mat-select [(ngModel)]="result.source" (selectionChange)="updateUrl()">
                    <mat-option value="youtube">Youtube</mat-option>
                    <mat-option value="instagram">Instagram</mat-option>
                    <mat-option value="private" disabled>Servidor privado</mat-option>
                </mat-select>
                <mat-label>Fonte</mat-label>
            </mat-form-field>            

            <!-- Link do stream -->
            <mat-form-field style="width: 100%;">
                <input          
                    #urlInput           
                    matInput
                    [(ngModel)]="result.url">
                <mat-label>Link</mat-label>
                <mat-hint align='end'>Insira o link do compartilhamento do stream</mat-hint>
            </mat-form-field> 

        </div>    

    </div>
  
    <div mat-dialog-actions align="end">
        <button mat-button [mat-dialog-close]="undefined">Cancelar</button>
        <button mat-stroked-button color="primary" [mat-dialog-close]="result">Abrir stream</button>
    </div>
</div>