import { Component, OnInit } from '@angular/core';
import { OnlineDataControllerService } from '../../../online-data-controller/online-data-controller.service';
import { AuthenticationService } from '../../../authentication/authentication.service';
import { User } from 'src/app/models/user.model';
import { RemoteConfigService } from '../../../remote-config/remote-config.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-terms-of-service-dialog',
  templateUrl: './terms-of-service-dialog.component.html',
  styleUrls: ['./terms-of-service-dialog.component.scss']
})
export class TermsOfServiceDialogComponent implements OnInit {
  public version:number;
  public isSaving:boolean = false;

  constructor(
    public _dialogRef: MatDialogRef<TermsOfServiceDialogComponent>,
    private _auth:AuthenticationService,
    private _dc:OnlineDataControllerService,
    private _rc:RemoteConfigService
  ) { 
      
  }

  public ngOnInit() : void {
    this._rc.fetchAndActivate().then(()=>{
      this.version = this._rc.getTermsOfUseVersion();
    });    
  }

  public onAccept() {
    var user:User = this._auth.getLoggedUser();
    this.isSaving = true;
    user.accepted_terms_of_use.version = this.version;
    user.accepted_terms_of_use.at = new Date();
    this._dc.updateUser(user)
    .then(()=>{
      this._dialogRef.close(true);
    })
    .catch((error)=>{
      // TODO: Toast
    })
    .finally(()=>{
      this.isSaving = false;
    });
  }

  public onDecline() {
    var user = this._auth.getLoggedUser();
    user.accepted_terms_of_use.version = 0;
    this._dc.updateUser(user)
    .then(()=>{
      return this._auth.logout();
    })
    .then(()=>{
      this._dialogRef.close(false);
    });
  }

}
