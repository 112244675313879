<div mat-dialog-title>
  <mat-toolbar appSticky>
    <span>Política de Privacidade</span>
  </mat-toolbar>
</div>

<mat-dialog-content>
  <app-privacy-policy></app-privacy-policy>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button 
    mat-button
    [mat-dialog-close]="true"
    cdkFocusInitial>Fechar</button>
</mat-dialog-actions>