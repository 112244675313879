export enum EDialogButtons {
    OK,
    SIM_NAO,
  }
  
  export enum EDialogIcon {
    INFORMATION,
    QUESTION,
    ERROR,
    WARNING
  }

  export interface DialogData {
    title: string,
    message: string,
    buttons: EDialogButtons,
    header_back_button: boolean,
    icon: EDialogIcon      
  }