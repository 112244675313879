
<div class="full-content-container content-border content-background" 
     fxLayout="column">

  <!--Logo-->
  <div class="logo w3-animate-zoom">
    <img src="assets/images/logo.png" style="max-width:100%; height:auto;">    
  </div>

  <!--Barra de progresso-->
  <div style="width: 256px; margin-top: 32px;" *ngIf="progressBarEnabled">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
  
  <!--Botoes / Status-->
  <div *ngIf="checkingPersistentLogin else noPersistentLogin" style="margin-top: 32px;">
    <span class="text">Verificando login...</span>
  </div>
  <ng-template #noPersistentLogin>
    <div class="w3-animate-zoom" fxLayout="row wrap" fxLayoutAlign="space-around" style="width: 256px; margin-top: 32px;">
      <button mat-button
        class="login-button"
        (click)="onLoginButtonClick()">
        <svg xmlns="https://www.w3.org/2000/svg" xmlns:xlink="https://www.w3.org/1999/xlink" viewBox="0 0 48 48">
          <defs>
            <path id="a" d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"/>
          </defs>
          <clipPath id="b">
            <use xlink:href="#a" overflow="visible"/>
          </clipPath>
          <path clip-path="url(#b)" fill="#FBBC05" d="M0 37V11l17 13z"/>
          <path clip-path="url(#b)" fill="#EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z"/>
          <path clip-path="url(#b)" fill="#34A853" d="M0 37l30-23 7.9 1L48 0v48H0z"/>
          <path clip-path="url(#b)" fill="#4285F4" d="M48 48L17 24l-4-3 35-10z"/>
        </svg>
        <span>Entrar</span>
      </button>

      <button mat-stroked-button color="primary"
        class="pwa-install-button"
        *ngIf="_pwa.installationSupported()"
        (click)="_pwa.promptInstallation()">
        <span>Instalar</span>
      </button>
    </div>
  </ng-template>


  <!--O que é a Cycle Connect ?-->
  <div #about class="about" style="margin-top: 32px; display: none;">    
    <h1 style="text-align: center;">
      Grupo exclusivo de ciclismo indoor online
    </h1>
    <h2>
      O projeto foi idealizado pelo professor <span style="color:#a62d5d; font-family: 'Patua One'">Fred Reis</span> para que todos possam desfrutar do ciclismo indoor no conforto e segurança da sua casa, sem abrir mão da <span style="color:#a62d5d; font-family: 'Patua One'">qualidade e da metodologia.</span><br><br>
      As aulas ao vivo (lives) são transmitidas para assinantes às segundas, quartas e sextas-feiras de 19:30hs às 20:15hs, através do perfil privado <span style="color:#a62d5d; font-family: 'Patua One'">@cycleconnect_exclusivo</span> ou pelo app Cycle Connect.
    </h2>
  </div>  
  

  <!--Depoimentos-->
  <div #testimonial class="blockquote" style="margin-top: 32px; display: none;">    
    <h1>
      O app Cycle Connect otimizou o que já era ótimo: <span style="color:#a62d5d; font-family: 'Patua One'">aulas de altíssima qualidade técnica, com imagem e som impecáveis</span> a um clique do dedo. Oferece uma cartela de aulas com níveis e propostas diversificadas, <span style="color:#a62d5d; font-family: 'Patua One'">que podem ser assistidas a qualquer momento</span>, adequando-se perfeitamente à rotina de todos. O app em si é de <span style="color:#a62d5d; font-family: 'Patua One'">fácil manuseio</span> e nos dá total liberdade para desfrutar, da forma que melhor convir para cada um, do <span style="color:#a62d5d; font-family: 'Patua One'">melhor do ciclismo indoor</span> de Minas Gerais.
    </h1>
    <img src="https://lh3.googleusercontent.com/a-/AOh14GigSewT-YxszDNekb10k5ffwk62ccH6zDT_95o9fIg=s96-c" class="avatar">
    <h4>&mdash;&nbsp;Tatiana Gibram<br><em>Cliente Cycle Connect</em></h4>
  </div>
  

  <div #testimonial class="blockquote" style="margin-top: 32px; display: none;">
    <h1>
      <span style="color:#a62d5d; font-family: 'Patua One'">Melhor professor</span> de ciclismo indoor com <span style="color:#a62d5d; font-family: 'Patua One'">aulas extremamente técnicas e motivantes!</span> Super recomendo!
    </h1>
    <img src="https://lh3.googleusercontent.com/a-/AOh14GhIOy6rnGkfbmeP552FVRScbRrPVx5GgaCKp7H7=s96-c" class="avatar">
    <h4>&mdash;&nbsp;Rose Schuttenberg<br><em>Cliente Cycle Connect</em></h4>      
  </div>


  <!--Icones sociais-->
  <div class="footer"
    fxLayout="row" fxLayoutAlign="center center">
    <span fxFlex="1 1 auto"></span>

    <div class="w3-animate-left">
      <a href="https://www.instagram.com/cycleconnectbrasil" target="_blank" rel="noopener noreferrer" class="fa fa-instagram fa-lg" matTooltip="Instagram" matTooltipPosition="above"></a>
    </div>
    
    <span style="width: 8px;"></span>

    <div class="w3-animate-right">
      <a href="https://www.facebook.com/cycleconnectbrasil" target="_blank" rel="noopener noreferrer" class="fa fa-facebook fa-lg" matTooltip="Facebook" matTooltipPosition="above"></a>
    </div>    
    
    <span fxFlex="1 1 auto"></span>
  </div>  

  <!--Versão-->
  <div class="version">
    {{version.number}}
  </div>
</div>